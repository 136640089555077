import { Dialog as HeadlessDialog } from "@headlessui/react"
import styles from "./Dialog.module.css"

const Dialog = ({ open, onClose, title, description, children }) => {
  return (
    <HeadlessDialog
      open={open}
      onClose={
        // The no-op disables the default behavior of closing the dialog when the backdrop or the ESC key is clicked
        onClose ? onClose : () => {}
      }
    >
      <div className={styles.backdrop} onClick={e => {}} />
      <HeadlessDialog.Panel className={styles.panel}>
        <HeadlessDialog.Title>{title}</HeadlessDialog.Title>
        <HeadlessDialog.Description>{description}</HeadlessDialog.Description>
        {children}
      </HeadlessDialog.Panel>
    </HeadlessDialog>
  )
}

export default Dialog
