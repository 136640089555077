import React, { useState, useEffect } from "react";
import produce from 'immer';
import auth from "../auth";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from "../components/Selfcare/Common/Icon";
import { Grid, FormControlLabel, Checkbox } from '@mui/material';
import { LargeButton } from '../controls/components/Buttons';

export default function DailyData(props) {
    const { t } = useTranslation();
    const { vm, state, setState } = useConnect("DailyData", { ConfirmDialogOpen: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });
    const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, termsAccepted: false, msisdn: '', amount: '' });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    const handleChangeAccept = (event) => {
        setState({ accept: event.target.checked })
    }

    return (
        <>
            <ServerState serverState={state} setState={setState} handleClose={props.handleClose} handleRefresh={props.handleRefresh} />
            {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
            {state.Step === 1 &&
                <>
                    <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={10}>
                                <Icon name="pause-button" size={40} color="#FFFFFF" />
                                <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                    Pausa ditt Stugbredband
                                </div>
                                <div>
                                    Här kan du pausa ditt Stugbredband i upp till 12 månader
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container direction="column" justifyContent="space-around" alignItems="center" style={{ paddingTop: '17px' }}>
                        <Grid item xs={12} sm={10} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                            <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                <div style={{ width: '100%' }}>
                                    Du är på väg att pausa ditt Stugbredband. Eftersom du betalar för användning under hela dagen, så kommer abonnemanget att vara öppet fram kl 24:00 i kväll.
                                </div>
                                <div style={{ width: '100%', marginTop: '20px' }}>
                                    Bekräfta att du vill pausa Stugbredbandet genom att klicka nedan.
                                </div>
                                <FormControlLabel
                                    style={{ width: '100%' }}
                                    id="accept"
                                    control={
                                        <Checkbox
                                            disableRipple
                                            disabled={state.Disable}
                                            checked={state.accept}
                                            onChange={handleChangeAccept}
                                            color="primary"
                                            name="accept"
                                        />
                                    }
                                    label=
                                    {
                                        <div style={{ fontSize: '14px' }} className="noselect">
                                            Pausa Stugbredband
                                        </div>
                                    }
                                />

                                <div style={{ paddingTop: '20px' }}>
                                    <LargeButton onClick={() => { setState({ buzy: true }); vm.$dispatch({ PerformPause: true }); }} disabled={state.buzy || !state.accept}>
                                        Bekräfta PAUS
                                    </LargeButton>
                                    <LargeButton color="secondary" variant="text" style={{ marginRight: '14px' }} onClick={() => { props.handleClose(); }}>
                                        {t('selfcare:button.cancel')}
                                    </LargeButton>
                                </div>
                            </Grid>
                        </Grid>
                        {state.buzy &&
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px' }} >
                                <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                            </div>

                        }
                    </Grid>
                </>
            }

            {state.Step === 2 &&
                <>
                    <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={10}>
                                <Icon name="play-button" size={40} color="#FFFFFF" />
                                <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                    Avbryt planerad pausning av ditt Stugbredband
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container direction="column" justifyContent="space-around" alignItems="center" style={{ paddingTop: '17px' }}>
                        <Grid item xs={12} sm={10} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                            <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                <div style={{ width: '100%' }}>
                                Ditt Stugbredband är aktivt, men kommer att pausas kl 24.00 i kväll.
                                Vill du avbryta den planerade pausningen och behålla abonnemanget aktivt, klicka nedan. Du kan sedan, när du vill, pausa det igen.
                                </div>
                                <FormControlLabel
                                    style={{ width: '100%' }}
                                    id="accept"
                                    control={
                                        <Checkbox
                                            disableRipple
                                            disabled={state.Disable}
                                            checked={state.accept}
                                            onChange={handleChangeAccept}
                                            color="primary"
                                            name="accept"
                                        />
                                    }
                                    label=
                                    {
                                        <div style={{ fontSize: '14px' }} className="noselect">
                                            Avbryt planerad pausning av ditt Stugbredband
                                        </div>
                                    }
                                />

                                <div style={{ paddingTop: '20px' }}>
                                    <LargeButton onClick={() => { setState({ buzy: true }); vm.$dispatch({ PerformCancelPause: true }); }} disabled={state.buzy || !state.accept}>
                                        Bekräfta
                                    </LargeButton>
                                    <LargeButton color="secondary" variant="text" style={{ marginRight: '14px' }} onClick={() => { props.handleClose(); }}>
                                        {t('selfcare:button.cancel')}
                                    </LargeButton>
                                </div>
                            </Grid>
                        </Grid>
                        {state.buzy &&
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px' }} >
                                <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                            </div>
                        }
                    </Grid>
                </>
            }
            {state.Step === 3 &&
                <>
                    <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={10}>
                                <Icon name="play-button" size={40} color="#FFFFFF" />
                                <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                    Starta ditt Stugbredband
                                </div>
                                <div>
                                    Här startar och aktiverar du ditt Stugbredband.
                                    Med Stugbredband kan du starta och stoppa din dagliga surf när du vill. För 25 kr/dag har du helt Fri Surf.
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container direction="column" justifyContent="space-around" alignItems="center" style={{ paddingTop: '17px' }}>
                        <Grid item xs={12} sm={10} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                            <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                <div style={{ width: '100%' }}>
                                När du väljer att starta ditt Stugbredband, så kommer simkortet att vara aktivt inom 5-10 minuter. Efter aktivering av ditt Stugbredband så är det aktivt fram tills dess att du själv pausar abonnemanget i MittChili. Kostnaden är 25 kr/dag (00:00 till 24:00).
                                </div>
                                <div style={{ width: '100%', marginTop: '20px' }}>
                                Bekräfta att du vill aktivera Stugbredbandet genom att klicka nedan.
                                </div>
                                <FormControlLabel
                                    style={{ width: '100%' }}
                                    id="accept"
                                    control={
                                        <Checkbox
                                            disableRipple
                                            disabled={state.Disable}
                                            checked={state.accept}
                                            onChange={handleChangeAccept}
                                            color="primary"
                                            name="accept"
                                        />
                                    }
                                    label=
                                    {
                                        <div style={{ fontSize: '14px' }} className="noselect">
                                            Aktivera Stugbredband
                                        </div>
                                    }
                                />

                                <div style={{ paddingTop: '20px' }}>
                                    <LargeButton onClick={() => { setState({ buzy: true }); vm.$dispatch({ PerformStart: true }); }} disabled={state.buzy || !state.accept}>
                                        Bekräfta START
                                    </LargeButton>
                                    <LargeButton color="secondary" variant="text" style={{ marginRight: '14px' }} onClick={() => { props.handleClose(); }}>
                                        {t('selfcare:button.cancel')}
                                    </LargeButton>
                                </div>
                            </Grid>
                        </Grid>
                        {state.buzy &&
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px' }} >
                                <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                            </div>

                        }
                    </Grid>
                </>
            }
        </>
    );
}