import React, { useState } from 'react';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import * as rdd from 'react-device-detect';
import globalState from '../../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import produce from 'immer';
import { Link } from 'react-tiger-transition';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import PostpaidTopup from '../../dialogs/PostpaidTopup';
import PrepaidTopup from '../../dialogs/PrepaidTopup';
import ChangePassword from '../../dialogs/ChangePassword';
import ChangeService from '../../dialogs/ChangeService';
import ChargePrepaid from '../../dialogs/ChargePrepaid';
import ChangePackage from '../../dialogs/ChangePackage';
import ChangeSubscriptionDataLevel from '../../dialogs/ChangeSubscriptionDataLevel';
import OrderSimcard from '../../dialogs/OrderSimcard';
import ChangeAddresses from '../../dialogs/ChangeAddresses';
import ChangePortingDate from '../../dialogs/ChangePortingDate';
import ActivateSimcard from '../../dialogs/ActivateSimcard';
import Blocking from '../../dialogs/Blocking';
import ViewPinPuk from '../../dialogs/ViewPinPuk';
import ChangePaymentMethod from '../../dialogs/ChangePaymentMethod';
import ChangePaymentCard from '../../dialogs/ChangePaymentCard';
import FUP from '../../dialogs/FUP';
import Friends from '../../dialogs/Friends';
import FriendsNO from '../../dialogs/FriendsNO';
import DirectDebit from '../../dialogs/DirectDebit';
import SubscriptionPause from '../../dialogs/SubscriptionPause';
import SubscriptionUnPause from '../../dialogs/SubscriptionUnPause';
import ChangeOwner from '../../dialogs/ChangeOwner';
import StrexTransactions from '../../dialogs/StrexTransactions';
import PostpaidEUTopup from '../../dialogs/PostpaidEUTopup';
import PostpaidSuperboost from '../../dialogs/PostpaidSuperboost';
import PostpaidDataReload from '../../dialogs/PostpaidDataReload';
import DailyData from '../../dialogs/DialyData';
import DiscountCoupon from '../../dialogs/DiscountCoupon';
import Insurance from '../../dialogs/Insurance';
import isWebview from 'is-ua-webview';
import { isDesktop } from 'react-device-detect';
import LeftChevronIcon from '../../assets/img/svg/left-chevron.svg';
import Button from '@mui/material/Button';

const { forwardRef, useImperativeHandle } = React;

const CustomDialog = withStyles({
    root: {
        '& .MuiDialog-paperFullScreen': {
            borderTopLeftRadius: '30px',
            borderTopRightRadius: '30px',
            marginTop: '50px',
            maxWidth: '760px',
            height: 'calc(100% - 50px)',
            overflowY: 'hidden',
        },
    },
})(Dialog);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={1000} ref={ref} {...props} />;
});

const SparkleDialog = forwardRef((props, ref) => {
    const gstate = useGlobalState(globalState);
    const wDim = useWindowDimensions();
    const theme = useTheme();
    const locale = process.env.REACT_APP_DEFAULTLOCALE;
    var isNorway = false;
    if (locale.toLowerCase() === 'nb-no') {
        isNorway = true;
    }
    var isSweden = !isNorway;

    const [localState, internalSetLocalState] = useState({ dialogOpen: false, url: '', type: '', title: '', backFunction: false, showBack: false, backText: isNorway ? 'Tilbake' : 'Tillbaka' });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    };
    const handleClose = () => {
        setLocalState({ dialogOpen: false });
        setLocalState({ type: '' });
        setLocalState({ url: '' });
    };
    const handleChangeDialog = (newType) => {
        handleClose();
        setLocalState({ type: newType });
        setLocalState({ dialogOpen: true });
    }
    const setTitle = (title) => {
        setLocalState({ title: title });
    }
    const setBackFunction = (f, backText) => {
        if (f !== null) {
            setLocalState({ showBack: true });
        } else {
            setLocalState({ showBack: false });
        }
        setLocalState({ backFunction: f });
        if (backText !== null) {
            setLocalState({ backText: backText })
        }
    }
    const isWebView = isWebview(window.navigator.userAgent);

    useImperativeHandle(ref, () => ({
        //Used from parent
        openUrl(url, newWindow) {
            setLocalState({ url: url });
            if (isWebView || isDesktop || newWindow) {
                var elem = document.getElementById('navlink');
                if (elem) {
                    elem.href = url;
                    elem.click();
                };
            }
            else {
                setLocalState({ type: 'iFrame' });
                setLocalState({ dialogOpen: true });
            }
        },
        showDialog(type, param) {
            setLocalState({ title: '' });
            setLocalState({ backFunction: null });
            setLocalState({ type: type });
            setLocalState({ param: param });
            setLocalState({ dialogOpen: true });
        },
        navigatePage(url, transition) {
            setLocalState({ url: url });
            setLocalState({ transition: transition });
            setTimeout(
                function () {
                    var e = document.getElementById('animatelink');
                    if (e)
                        e.click();
                },
                5
            );
        }
    }));
    //Used by children
    const openUrl = (url, newWindow) => {
        setLocalState({ url: url });
        if (isWebView || isDesktop || newWindow) {
            var elem = document.getElementById('navlink');
            if (elem) {
                elem.href = url;
                elem.click();
            };
        }
        else {
            setLocalState({ type: 'iFrame' });
            setLocalState({ dialogOpen: true });
        }
    }
    const showDialog = (type, param) => {
        setLocalState({ title: '' });
        setLocalState({ backFunction: null });
        setLocalState({ type: type });
        setLocalState({ param: param });
        setLocalState({ dialogOpen: true });
    };
    return (
        <div>
            <Grid item component={Link} id="animatelink" to={localState.url} transition={localState.transition} />
            <a id="navlink" href={localState.url} target='_blank' rel="noopener noreferrer" aria-hidden="true" style={{ maxHeight: '0px' }}> </a>
            <CustomDialog fullScreen open={localState.dialogOpen} onClose={handleClose} TransitionComponent={Transition}>
                <Toolbar variant="dense">
                    <div style={{ minWidth: '45px', height: '48px', marginLeft: '-10px', paddingTop: '5px' }}>
                        {localState.showBack &&
                            <Button aria-label={localState.backText} id="back" onClick={() => { localState.backFunction(); }} style={{ fontSize: '14px', fontFamily: theme.typography.fontFamily2, fontWeight: 300, marginLeft: '-10px', marginRight: '-48px' }}><img src={LeftChevronIcon} height="32" alt="" style={{ marginTop: '-5px', marginRight: '-6px' }} /><div style={{ verticalAlign: 'top', marginTop: '-2px', color: theme.palette.primary.main, display: 'inline-block' }}>{localState.backText}</div></Button>
                        }
                    </div>
                    <Grid container direction="row" justifyContent="space-around" alignItems="center">
                        {localState.title &&
                            <div style={{ fontSize: wDim.phone ? '16px' : '20px', fontWeight: 600 }}>{localState.title}</div>
                        }
                    </Grid>
                    <IconButton
                        color="inherit"
                        onClick={handleClose}
                        aria-label={gstate.IsSweden.get() ? 'Stäng' : 'Lukk'}
                        edge="end"
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                {localState.type !== 'iFrame' &&
                    <div id="common-top" style={{ overflowX: 'hidden', overflowY: rdd.isFirefox ? 'auto' : wDim.desktop ? 'overlay' : 'scroll', height: wDim.height, WebkitOverflowScrolling: 'touch', scrollbarWidth: rdd.isFirefox ? 'thin' : null }}>
                        {localState.type === 'changePassword' &&
                            <ChangePassword handleClose={handleClose} />}
                        {localState.type === 'postpaidTopup' &&
                            <PostpaidTopup handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} openUrl={openUrl} />}
                        {localState.type === 'postpaidEUTopup' &&
                            <PostpaidEUTopup handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'postpaidSuperboost' &&
                            <PostpaidSuperboost handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'postpaidDataReload' &&
                            <PostpaidDataReload handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} openUrl={openUrl} />}
                        {localState.type === 'prepaidTopup' &&
                            <PrepaidTopup handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'chargePrepaid' &&
                            <ChargePrepaid handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'changeUser' &&
                        <ChangeAddresses handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} type="user" openUrl={openUrl} showDialog={showDialog} />
                        }
                        {localState.type === 'changeService' &&
                            <ChangeService handleClose={() => {handleClose(); props.handleRefresh()}} subscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'changePackage' &&
                            <ChangePackage handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} openUrl={openUrl} param={localState.param} setTitle={setTitle} setBackFunction={setBackFunction} />}
                        {localState.type === 'changeSubscriptionDataLevel' &&
                            <ChangeSubscriptionDataLevel handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'orderSimcard' &&
                            <OrderSimcard handleClose={handleClose} handleRefresh={props.handleRefresh} handleChangeDialog={handleChangeDialog} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'changeAddress' &&
                        <ChangeAddresses handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} type="owner" openUrl={openUrl} showDialog={showDialog}/>
                        }
                        {localState.type === 'changePortingDate' &&
                            <ChangePortingDate handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} type="owner" openUrl={openUrl} showDialog={showDialog} setTitle={setTitle} />
                        }
                        {localState.type === 'activateSimcard' &&
                            <ActivateSimcard handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'blocking' &&
                            <Blocking handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'changePaymentMethod' &&
                            <ChangePaymentMethod handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'changePaymentCard' &&
                            <ChangePaymentCard handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {isSweden && localState.type === 'viewFriends' &&
                            <Friends handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {isNorway && localState.type === 'viewFriends' &&
                            <FriendsNO handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} setTitle={setTitle} setBackFunction={setBackFunction} openUrl={openUrl} />}
                        {localState.type === 'viewPinPuk' &&
                            <ViewPinPuk handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'FUP' &&
                            <FUP handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'autogiro' &&
                            <DirectDebit handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} openUrl={openUrl} />}
                        {localState.type === 'subscriptionpause' &&
                            <SubscriptionPause handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'subscriptionunpause' &&
                            <SubscriptionUnPause handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} />}
                        {localState.type === 'changeOwner' &&
                            <ChangeOwner handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} setTitle={setTitle} setBackFunction={setBackFunction} openUrl={openUrl} />}
                        {localState.type === 'strexTransactions' &&
                            <StrexTransactions handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} setTitle={setTitle} setBackFunction={setBackFunction} openUrl={openUrl} showDialog={showDialog} />}
                        {localState.type === 'dailyData' &&
                            <DailyData handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} setTitle={setTitle} setBackFunction={setBackFunction} openUrl={openUrl} showDialog={showDialog} />}
                        {localState.type === 'discountCoupon' &&
                            <DiscountCoupon handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} setTitle={setTitle} setBackFunction={setBackFunction} openUrl={openUrl} showDialog={showDialog} />}
                        {localState.type === 'insurance' &&
                            <Insurance handleClose={handleClose} handleRefresh={props.handleRefresh} SubscriptionNo={props.subscriptionNo} setTitle={setTitle} setBackFunction={setBackFunction} openUrl={openUrl} showDialog={showDialog} />}
                        <div style={{ height: '40px' }} />
                    </div>
                }
                {localState.type === 'iFrame' &&
                    <div style={{ width: '100%', height: '100%' }}>
                        <object type="text/html" data={localState.url} style={{ width: '100%', height: '100%' }}>
                        </object>
                    </div>
                }
            </CustomDialog>
        </div>
    );
});

SparkleDialog.displayName = 'SparkleDialog';

export default SparkleDialog;