import React from 'react';
import auth from "../auth";
import Version from "../version";
import { useConnect } from "dotnetify";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import ServerState from "../controls/ServerState";
import Grid from '@mui/material/Grid';
import ButtonX from '@mui/material/Button';
import ReactLoading from 'react-loading';
import Icon from "../components/Selfcare/Common/Icon";
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FriendsList from './FriendsList';
import { LargeButton } from '../controls/components/Buttons';

export default function Friends(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { vm, state, setState } = useConnect("Friends",
        {
            show: false,
            redirect: null,
            toUrl: null,
            OrderSent: false,
            OrderError: '',
            buzy: true,
            DataLoading: true,
            ErrorField: '',
            ErrorText: '',
            tooltipOpen: false,
            registerDialogOpen: false,
            PhoneNo: '',
            Details: false,
        },
        {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg: {
                SubscriptionNo: props.SubscriptionNo,
            },
            exceptionHandler: _ => auth.signOut()
        });
    const FriendsText = "Hej!<br/>Jag vill tipsa dig om att byta till Chilimobil så får vi båda rabatt. Kolla ditt erbjudande och fixa abonnemang här: https://chilimobil.se/kompisar";
    const FriendsClipboardText = "Hej!\r\nJag vill tipsa dig om att byta till Chilimobil så får vi båda rabatt. Kolla ditt erbjudande och fixa abonnemang här: https://chilimobil.se/kompisar";

    const onSuccess = () => {
        setState({ tooltipOpen: true });
        setTimeout(
            function () {
                setState({
                    tooltipOpen: false
                });
            },
            2000
        );
    }
    const handleCloseRegisterDialog = () => {
        setState({ registerDialogOpen: false });
    }
    return <>
        <ServerState serverState={state} setState={setState} handleClose={props.handleClose} />
        <div style={{ height: '100vh' }}>
            <Dialog
                open={state.registerDialogOpen}
                onClose={handleCloseRegisterDialog}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Icon name="friends" size={36} color="#e91e63" />
                    <div style={{ marginLeft: '60px', marginTop: '-45px' }}>Efterregistrera min kompisrabatt</div>
                </DialogTitle>
                <DialogContent>
                    <TextField fullWidth error={state.ErrorField === 'PhoneNo'}
                        autoFocus
                        disabled={state.dialogBuzy}
                        label="Ange din kompis/värvares mobilnummer"
                        id="Firstname"
                        inputProps={{ maxLength: 14 }}
                        InputLabelProps={{ shrink: true }}
                    />
                    {state.dialogBuzy && <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', width: '100%' }}>
                        <ReactLoading type={"bubbles"} color={"#e91e63"} height={'50px'} width={'50px'} />
                    </div>}
                </DialogContent>
                <DialogActions>
                    <LargeButton onClick={() => { vm.$dispatch({ RegisterFriend: state.PhoneNo }); setState({ dialogBuzy: true }); }} disabled={state.dialogBuzy}>
                        Registrera
                    </LargeButton>
                    <ButtonX onClick={handleCloseRegisterDialog} disabled={state.dialogBuzy}>
                        Avbryt
                    </ButtonX>
                </DialogActions>
            </Dialog>
            {!state.Details &&
                <>
                    <div style={{ backgroundColor: '#e91e63', color: '#FFF', padding: '20px' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={10}>
                                <Icon name="friends" size={48} color="#FFFFFF" />
                                <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                    Värva kompisar till Chilimobil!

                                </div>
                                <div style={{ fontSize: '14px' }}>
                                    Du får rabatt per kompis varje månad
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={10} style={{ padding: '20px' }} >
                                <div style={{ marginBottom: '20px' }}>
                                    <b>Så här funkar det</b><br /><br />
                                    Du kan bjuda in kompisar att bli kunder hos Chilimobil. När kompisen blir kund anger kompisen bara ditt mobilnummer vid registrering så får ni båda rabatt* <br />Värvar du många kompisar <b>så kan du helt 0:a din egen mobilkostnad!</b><br />
                                    Ganska bra deal va!.<br />
                                    <br />
                                    *rabatten kan variera över tid beroende på hur många kompisar du har och som fortfarande är kund,  samt beroende på rabattbeloppet som kan variera mellan 10-20 kr/mån .
                                </div>
                                <div style={{ marginBottom: '20px' }}>
                                    <b>Sätt igång direkt!</b><br /><br />
                                    Bjud in kompisar:<br />
                                    Kopiera denna text och länk nedan och messa till dina kompisar med sms, Whatsapp etc. du kan ju också bara prata med dem
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    <Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        open={state.tooltipOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title="Texten kopierad"
                                        placement="top"
                                        arrow
                                    >
                                        <button onClick={() => { navigator.clipboard.writeText(FriendsClipboardText); onSuccess(); }} >
                                            Kopiera text
                                        </button>
                                    </Tooltip>
                                </div>
                                <div style={{ padding: '4px', border: '1px solid #898989', borderLeft: '5px solid #898989', backgroundColor: '#FFF' }}>
                                    <i><span dangerouslySetInnerHTML={{ __html: FriendsText }} /></i>
                                </div>
                                {state.DataLoading ? <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', width: '100%' }}>
                                    <ReactLoading type={"bubbles"} color={"#e91e63"} height={'50px'} width={'50px'} />
                                </div>
                                    :
                                    <div style={{ width: '100%', height: '50px' }}>
                                    </div>}
                                <Grid item xs={12}>
                                    <div>
                                        <List>
                                            {state.CanBeEnlisted &&
                                                <React.Fragment>
                                                    <Divider variant="inset" component="li" />
                                                    <ListItem button onClick={() => { setState({ registerDialogOpen: true }); }}>
                                                        <ListItemAvatar>
                                                            <Icon name="add-friend" size={36} color="#e91e60" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Efterregistrera min kompisrabatt"
                                                            secondary="Jag glömde registrera mig vid beställning"
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <IconButton onClick={() => { setState({ registerDialogOpen: true }); }} size="large">
                                                                <Icon name="right-arrow" size={24} color="#e91e60" />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>}
                                            <Divider variant="inset" component="li" />
                                            <ListItem button onClick={() => { setState({ Details: true }); }}>
                                                <ListItemAvatar>
                                                    <Icon name="friends" size={36} color="#e91e60" />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Mina Kompisar"
                                                    secondary="Lista över Chilikompisar"
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton onClick={() => { setState({ Details: true }); }} size="large">
                                                        <Icon name="right-arrow" size={24} color="#e91e60" />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </List>
                                    </div>
                                </Grid>
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <LargeButton id='back' disabled={state.buzy} onClick={() => { props.handleClose(); }}><Icon name="back" size={32} color="#FFFFFF" />{t('selfcare:button.back')}</LargeButton>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </>
            }
            {state.Details &&
                <FriendsList SubscriptionNo={props.SubscriptionNo} GoBack={() => { setState({ Details: false }); }} />
            }
        </div>
    </>;

}

