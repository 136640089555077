import React, { useEffect, useRef } from "react";
import auth from "../auth";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Icon from "../components/Selfcare/Common/Icon";
import * as rdd from 'react-device-detect';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid, Button } from '@mui/material';
import { TextField, TextFieldLowerCase } from '../controls/TextField';
import { LargeButton } from '../controls/components/Buttons';
import { Box, BoxTitle } from '../controls/Box';
import ChangeOwnerImg from '../assets/img/ChangeOwner.jpg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Alert from '@mui/material/Alert';
import QRCode from 'qrcode.react'
import bankid from "../assets/img/bankid.png";

const BankIdButton = withStyles({
    root: {
        backgroundColor: '#225971',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#469CBE',
            color: '#FFF',
        },
        '&:active': {
            backgroundColor: '#469CBE',
            color: '#FFF',
        },
        '&:visited': {
            backgroundColor: '#225971',
            color: '#FFF',
        },
        '&:focus': {
            backgroundColor: '#469CBE',
            color: '#FFF',
        },
    },
})(Button);


export default function ChangeOwner(props) {
    let interval = useRef(0);
    let interval2 = useRef(0);
    const { t } = useTranslation();
    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    var biData, cbState;
    const callbackState = sessionStorage.CallbackStateRet;
    if (callbackState) {
        if (callbackState !== '[object Object]') {
            cbState = JSON.parse(callbackState);
            sessionStorage.removeItem('CallbackStateRet');
        }
        else {
            sessionStorage.removeItem('CallbackStateRet');
        }
    }
    const bankIdData = sessionStorage.BankIdData;
    if (bankIdData) {
        biData = JSON.parse(bankIdData);
        sessionStorage.removeItem('BankIdData');
    }
    const { vm, state, setState } = useConnect("ChangeOwner", { Step: 0, disabled: false, busy: false, EventListener: '', firstname: '', lastname: '', email: '', CancelButtonDisabled: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
            SubscriptionNo: props.SubscriptionNo,
            BankIdData: biData,
            CallbackState: cbState,
        },
        exceptionHandler: _ => auth.signOut()
    });
    useEffect(() => {

        return () => {
            if (gstate.IsSweden.get()) {
                clearInterval(interval.current);
                clearInterval(interval2.current);
            }
        }
    }, [])
    useEffect(() => {
        switch (state.Step) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                var title = gstate.IsNorway.get() ? "Bytte av eier" : "Ägarbyte";
                props.setTitle(title);
                props.setBackFunction(null);
                break;
            default:
                props.setTitle("");
                break;
        }
        if (state.Step === 2) {
            props.setBackFunction(() => { setState({ Step: 1 }); });
        }
        if (state.Step === 3) {
            props.setBackFunction(() => { setState({ Step: 2 }); });
        }
        if (state.Step === 4) {
            props.setBackFunction(() => {
                setState({ Step: 3 });
                if (gstate.IsSweden.get()) {
                    setState({ SetTimer: 'stop' });
                }
            });
        }
    }, [state.Step]);
    useEffect(() => {
        if (gstate.IsSweden.get()) {
            if (state.SetTimer !== undefined && state.SetTimer !== null && state.SetTimer !== '') {
                if (state.SetTimer === 'start') {
                    setState({ SetTimer: '' });
                    interval.current = setInterval(() => {
                        TimerElapsed();
                    }, 2000);
                    interval2.current = setInterval(() => {
                        UpdateImage();
                    }, 1000);
                    setState({ interval: interval, interval2: interval2 });
                }
                if (state.SetTimer === 'stop') {
                    setState({ SetTimer: '' });
                    clearInterval(interval.current);
                    clearInterval(interval2.current);
                }
            }
        }
    }, [state.SetTimer, gstate.IsSweden]);
    useEffect(() => {
        if (state.BankIdUrl !== undefined && state.BankIdUrl !== null && state.BankIdUrl !== '') {
            window.location.href = state.BankIdUrl;
        }
    }, [state.BankIdUrl]);

    useEffect(() => {
        if (state.BankIDUrlSchema !== undefined && state.BankIDUrlSchema !== null && state.BankIDUrlSchema !== '' && rdd.isIOS && state.BankIDStep === 'local') {
            window.location.assign(state.BankIDUrlSchema);
        }
        if (state.BankIDUrlSchema !== undefined && state.BankIDUrlSchema !== null && state.BankIDUrlSchema !== '' && rdd.isAndroid && state.BankIDStep === 'local') {
            window.location.assign(state.BankIDUrlSchema);
        }
    }, [state.BankIDUrlSchema, state.BankIDStep]);

    const handleChange = (event) => {
        vm.$dispatch({ CheckChange: event.target.value })
    }

    const TimerElapsed = () => {
        if (vm)
            vm.$dispatch({ TimerElapsed: true });
    }
    const UpdateImage = () => {
        if (vm) {
            vm.$dispatch({ UpdateImage: true });
        }
    }
    const initBankIDLocal = () => {
        console.log(window.location);
        var returnUrl;
        if (rdd.isIOS) {
            if (rdd.isSafari) {
                returnUrl = window.location.href; // window.location.protocol + "//" + window.location.host + "/";
            } else if (rdd.isChrome) {
                returnUrl = "googlechromes://";
            } else if (rdd.isFirefox) {
                returnUrl = "firefox:://";
            } else if (rdd.isEdge || rdd.isEdgeChromium) {
                returnUrl = "microsoft-edge-https://";
            } else {
                returnUrl = "null";
            }
        } else {
            returnUrl = "null";
        }
        vm.$dispatch({ InitBankIDSE: { returnUrl: returnUrl, type: 'local' } });
        setState({ BankIDBuzy: true, dialogDisabled: true, BankIDStep: 'local' });
    }
    const initBankIDOtherDevice = () => {
        console.log(window.location);
        var returnUrl;
        if (rdd.isIOS) {
            returnUrl = window.location.href; // window.location.protocol + "//" + window.location.host + "/";
        } else {
            returnUrl = "null";
        }
        vm.$dispatch({ InitBankIDSE: { returnUrl: returnUrl, type: 'qr' } });
        setState({ BankIDBuzy: true, dialogDisabled: true, BankIDStep: 'otherDevice' });
    }

    return (
        <>
            <ServerState serverState={state} setState={setState} />
            {state.Step === 0 &&
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                        <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'150px'} width={'150px'} />
                    </Grid>
                </Grid>
            }
            {state.Step === 1 &&
                <>
                    <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <img src={ChangeOwnerImg} style={{ margin: '0px', width: '100%' }} alt="" />
                        {state.OwnerChangeDisabledMessage && <div style={{ borderRadius: '10px', marginTop: '17px', backgroundColor: 'black', color: 'white', padding: '10px', textAlign: 'center' }}>{state.OwnerChangeDisabledMessage}</div>}

                        <div style={{ padding: '0px 20px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <div style={{ fontSize: '16px', marginTop: '29px', textAlign: 'center' }}>
                                {gstate.IsNorway.get() ? 'Vil du flytte nummeret ditt over til en annen person? Godkjenn med BankID' : 'Vill du flytta över ditt nummer till en annan person och godkänna med BankID?'}
                            </div>
                            <div style={{ marginTop: '23px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '470px' }}>
                                <div style={{ fontSize: '14px', textAlign: 'center' }}>
                                    {gstate.IsNorway.get() ? 'Viktig å vite' : 'Viktigt att veta'}
                                </div>
                                <div style={{ fontSize: '12px', paddingLeft: '17px' }}>
                                    <div style={{ marginBottom: '15px' }}>
                                        <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                        {gstate.IsNorway.get() ? <span>Du vil ikke lengre ha tilgang til å gjøre endringer på dette nummeret.</span> : <span>Du kommer inte längre att kunna göra ändringar på numret.</span>}
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                        {gstate.IsNorway.get() ? <span>Den nye eieren blir ny betaler av fakturaen.</span> : <span>Den nya ägaren blir betalningsansvarig för fakturan för numret.</span>}
                                    </div>
                                    <div style={{ marginBottom: '15px' }}>

                                        <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                        {gstate.IsNorway.get() ? <span>Du kan ikke ta nummeret tilbake uten samtykke fra den nye eieren.</span> : <span>Du kan inte ta tillbaka numret utan den nya ägarens medgivande.</span>}
                                    </div>
                                    <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                {gstate.IsNorway.get() ? <span>Den nye eieren må være over 18 år og må være bosatt i Norge.</span> : <span>Den nya ägaren måste vara över 18 år och bosatt i Sverige.</span>}
                                {gstate.IsSweden.get() &&
                                    <>
                                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                        <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                        <span>När tillträdande ägare har signerat med BankID, så sker bytet vid nästkommande månadsskifte.</span>
                                    </div>
                                        <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                        <span>Ett ägarbyte kostar 99 kr och betalas av tillträdande ägare och beloppet läggs på första fakturan.</span>

                                    </>}
                                </div>
                            </div>
                        </div>
                        <Grid container direction="row" justifyContent="center" style={{ marginTop: '24px' }}>
                            <LargeButton variant="text" color="secondary" style={{ marginRight: '10px' }} onClick={() => props.handleClose()}>Avbryt</LargeButton>
                            <LargeButton disabled={state.disabled} onClick={() => { setState({ Step: 2 }); }}>{gstate.IsNorway.get() ? 'Bytt eier' : 'Byt ägare'}</LargeButton>
                        </Grid>
                    </div>
                </>
            }
            {state.Step === 2 &&
                <>
                    <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', }} className='noselect'>
                        <div style={{ fontSize: '16px', marginTop: '30px', marginBottom: '39px' }}>
                            {gstate.IsNorway.get() ? 'Hvilke nummer skal flyttes til ny eier?' : 'Vilket/vilka nummer skall flyttas till ny ägare?'}
                        </div>
                        <Grid container style={{ maxWidth: '600px' }} direction="row" justifyContent="space-around">
                            {state.Subscriptions ? state.Subscriptions.map((subscription, index) => {
                                return (
                                    <FormControlLabel key={index} style={{ borderRadius: '10px', padding: '18px 18px 18px 8px', marginBottom: '20px', marginRight: '0px', marginLeft: '0px', width: '100%', maxWidth: '280px', border: subscription.Selected ? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.border.main}`, background: subscription.Selected ? `${theme.palette.background.selected} 0% 0% no-repeat padding-box` : '#FFFFFF 0% 0% no-repeat padding-box' }}
                                        value={subscription.SubscriptionNo}
                                        control={<Checkbox color="primary" checkedIcon={<CheckCircleIcon />} icon={<RadioButtonUncheckedIcon />} checked={subscription.Selected} onChange={handleChange} />}
                                        label={<div><div style={{ fontSize: '18px', fontWeight: 700 }}>{subscription.MSISDN}</div><div style={{ fontSize: '14px', fontWeight: 200, marginTop: '-5px' }}>{subscription.Username}</div></div>}
                                        labelPlacement="end"
                                        disabled={state.buzy || state.DisableButtons}
                                    />
                                )
                            }) : null}
                        </Grid>
                        <Grid container direction="row" justifyContent="center" style={{ marginTop: '24px' }}>
                            <LargeButton variant="text" color="secondary" style={{ marginRight: '10px' }} onClick={() => props.handleClose()}>Avbryt</LargeButton>
                            <LargeButton disabled={!state.CanContinue} onClick={() => { setState({ Step: 3 }); }}>{gstate.IsNorway.get() ? 'Neste' : 'Nästa'}</LargeButton>
                        </Grid>
                    </div>
                </>
            }
            {state.Step === 3 &&
                <>
                    <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', }}>
                        <div style={{ fontSize: '16px', marginTop: '30px', marginBottom: '19px' }}>
                            {gstate.IsNorway.get() ? 'Hvem skal nummer flyttes til?' : 'Vem skall nummer flyttas till?'}
                        </div>
                        <Grid container style={{ maxWidth: '600px' }}>
                            <TextField
                                localState={state}
                                name="firstname"
                                setLocalState={setState}
                                id="firstname"
                                label={gstate.IsNorway.get() ? "Fornavn" : "Förnamn"}
                                disabled={state.buzy || state.Disabled}
                                denseBottom
                            />
                            <TextField
                                localState={state}
                                name="lastname"
                                setLocalState={setState}
                                id="lastname"
                                label={gstate.IsNorway.get() ? "Etternavn" : "Efternamn"}
                                disabled={state.buzy || state.Disabled}
                                denseBottom
                            />
                            <TextFieldLowerCase
                                localState={state}
                                name="email"
                                setLocalState={setState}
                                id="email"
                                label="E-post"
                                type="email"
                                disabled={state.buzy || state.Disabled}
                                denseBottom
                            />
                        </Grid>
                        <div style={{ fontSize: '14px', marginTop: '10px', marginBottom: '37px', maxWidth: '600px', padding: '0px 17px' }}>
                            {gstate.IsNorway.get() ? 'E-post med link for å godkjenne eierskifte med BankID, sendes til e-posten over etter du har godkjent overføringen.' : 'Ett e-post meddelande med länk för att godkänna ägarbytet med BankID, skickas till adressen ovan efter att du godkänt överföringen.'}
                        </div>

                        <Grid container direction="row" justifyContent="center" style={{ marginTop: '24px' }}>
                            <LargeButton variant="text" color="secondary" style={{ marginRight: '10px' }} onClick={() => props.handleClose()}>Avbryt</LargeButton>
                            <LargeButton disabled={!state.CanContinue} onClick={() => { vm.$dispatch({ ValidateReceipient: { firstname: state.firstname, lastname: state.lastname, email: state.email } }) }}>{gstate.IsNorway.get() ? 'Gå videre' : 'Fortsätt'}</LargeButton>
                        </Grid>
                    </div>
                </>
            }
            {state.Step === 4 &&
                <>
                    <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', }}>
                        {gstate.IsNorway.get() ?
                            <>
                                <div style={{ fontSize: '16px', marginTop: '30px', marginBottom: '39px', padding: '0px 17px' }}>
                                    Godkjenn at nummer kan overføres til {state.firstname + ' ' + state.lastname} med BankID
                                </div>
                                {state.HasPendingConversions && <div style={{ marginBottom: '39px', maxWidth: '600px', padding: '0px 17px' }}>
                                    <b>NB!</b> Du har bestilt en endring av abonnement ditt. Når du bestiller et eierskifte samtidig, vil endringen av abonnementet ikke kunne gjennomføres. Husk å legg inn endringen av abonnementet ditt etter eierskiftet er ferdig i slutten av måneden
                                </div>}
                                <LargeButton onClick={() => { setState({ buzy: true }); vm.$dispatch({ InitBankId: true }); }} disabled={state.buzy} style={{ marginRight: '10px' }}>Godkjenn med BankID</LargeButton>
                            </>
                            :
                            <>
                                {state.BankIDStep === 'start' &&
                                    <Grid container direction="column" justifyContent="center" alignItems="center">
                                        <div style={{ fontSize: '16px', marginTop: '30px', marginBottom: '39px', padding: '0px 17px' }}>
                                            Godkänn att nummer överförs till {state.firstname + ' ' + state.lastname} med BankID
                                        </div>
                                        {state.HasPendingConversions && <div style={{ marginBottom: '39px', maxWidth: '600px', padding: '0px 17px' }}>
                                        <b>OBS! </b>OBS! Du har beställt byte av abonnemangsform på numret. När du samtidigt beställer ägarbyte, så kommer bytet av abonnemangsform ej att gå igenom. Kom ihåg att be tillträdande ägare att på nytt beställa byte av abonnemangsform efter att ägarbytet skett.
                                        </div>}
                                        <img src={bankid} alt="Logga in med BankID" style={{ marginTop: '30px', marginBottom: '80px' }} />
                                        {!state.failureText &&
                                            <div style={{ maxWidth: '280px' }}>
                                                {rdd.isMobile &&
                                                    <React.Fragment>
                                                        <BankIdButton onClick={(event) => { initBankIDLocal(); }} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled} style={{ marginBottom: '10px' }}>
                                                            BankID på denna enhet
                                                        </BankIdButton>
                                                        <BankIdButton onClick={(event) => { initBankIDOtherDevice(); }} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled}>
                                                            BankID på annan enhet
                                                        </BankIdButton>
                                                    </React.Fragment>
                                                }
                                                {!rdd.isMobile &&
                                                    <>
                                                        <BankIdButton onClick={(event) => { initBankIDOtherDevice(); }} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled} style={{ marginBottom: '10px' }}>
                                                            Mobilt BankID
                                                        </BankIdButton>
                                                        <BankIdButton onClick={(event) => { initBankIDLocal(); }} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled}>
                                                            BankID på denna enhet
                                                        </BankIdButton>
                                                    </>
                                                }
                                            </div>}
                                        {state.failureText &&
                                            <React.Fragment>
                                                <Alert severity="error"><span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: state.failureText }} /></Alert>
                                                <Button style={{ marginTop: '10px' }} variant="outlined" color="primary" size="large" fullWidth onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '', failureText: null }); }}>
                                                    Försök igen
                                                </Button>
                                            </React.Fragment>}
                                    </Grid>}
                                {state.BankIDStep === "otherDevice" &&
                                    <div style={{ padding: '0px', textAlign: 'center' }}>
                                        {state.startMessageText && !state.failureText &&
                                            <div style={{ marginBottom: '10px' }}>
                                                {state.startMessageText}
                                            </div>}
                                        {state.failureText &&
                                            <>
                                                <Alert severity="error"><span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: state.failureText }} /></Alert>
                                                <BankIdButton style={{ marginTop: '10px' }} variant="outlined" color="primary" size="large" fullWidth onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '', failureText: null, startMessageText: null }); }}>
                                                    Försök igen
                                                </BankIdButton>
                                            </>}
                                        {(state.BankIDBuzy || state.startMessageText) && !state.failureText &&
                                            <div style={{ width: '100%', paddingBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                <ReactLoading type={"spin"} color='#469CBE' height={'36px'} width={'36px'} />
                                            </div>}
                                        {!state.startMessageText && state.QRValue &&
                                            <>
                                                <div style={{ marginTop: '50px', marginBottom: '10px', width: '100%' }}>
                                                    Starta din BankID-app
                                                </div>
                                                <QRCode value={state.QRValue} size={200} />
                                                <div style={{ fontSize: '12px', marginBottom: '20px', marginTop: '10px' }}>
                                                    Starta BankID-appen på din mobil eller surfplatta.<br />
                                                    Klicka på QR-ikonen i appen och läs av QR-koden ovan.
                                                </div>
                                            </>}
                                        {!state.failureText && <Button variant="outlined" color="primary" size="large" fullWidth disabled={state.dialogDisabled} onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '', failureText: null, startMessageText: null }); }}>
                                            Avbryt
                                        </Button>}

                                    </div>}
                                {state.BankIDStep === "local" &&
                                    <>
                                        {!rdd.isMobile && state.BankIDUrlSchema &&
                                            <iframe title="BankId" src={state.BankIDUrlSchema} width={0} height={0} frameBorder={0} />}
                                        <div style={{ padding: '0px', textAlign: 'center' }}>
                                            {state.startMessageText && !state.failureText &&
                                                <div style={{ marginBottom: '10px' }}>
                                                    {state.startMessageText}
                                                </div>}
                                            {state.failureText &&
                                                <>
                                                    <Alert severity="error"><span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: state.failureText }} /></Alert>
                                                    <Button style={{ marginTop: '10px' }} variant="outlined" color="primary" size="large" fullWidth onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '', failureText: null, startMessageText: null }); }}>
                                                        Försök igen
                                                    </Button>
                                                </>}
                                            {!state.failureText && state.startMessageText &&
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    <ReactLoading type={"spin"} color='#469CBE' height={'36px'} width={'36px'} />
                                                </div>}

                                            {!state.failureText && !state.startMessageText &&
                                                <React.Fragment>
                                                    <div>
                                                        Startar BankID appen
                                                    </div>
                                                    <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                        <ReactLoading type={"spin"} color='#469CBE' height={'36px'} width={'36px'} />
                                                    </div>
                                                    <BankIdButton href={state.BankIDUrlSchema} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled}>
                                                        Öppna BankID
                                                    </BankIdButton>
                                                    <Button style={{ marginTop: '10px' }} variant="outlined" color="primary" size="large" fullWidth onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '' }); }}>
                                                        Avbryt BankId
                                                    </Button>
                                                </React.Fragment>}
                                        </div>
                                    </>}

                            </>
                        }
                        {state.buzy &&
                            <Grid item>
                                <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                            </Grid>
                        }

                    </div>
                </>
            }
            {state.Step === 5 &&
                <>
                    <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', padding: '0px 17px' }}>
                        <div style={{ fontSize: '16px', marginTop: '30px', marginBottom: '27px', textAlign: 'center' }}>
                            {gstate.IsNorway.get() ?
                                state.ChangeInfo.ToConfirmed ? <><span>Eierskifte godkjent.</span><br /><span>Overføring til ny eier trer i kraft:<br />{state.ChangeDate}</span></> : <span>Status på eierskiftet</span>
                                :
                                state.ChangeInfo.ToConfirmed ? <><span>Ägarbyte godkänt.</span><br /><span>Överföring till den nya ägaren sker:<br />{state.ChangeDate}</span></> : <span>Status på ägarbytet</span>
                            }
                        </div>
                        <Grid container direction="row" justifyContent="space-between" style={{ maxWidth: '700px' }}>
                            <Box background>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ flexWrap: 'nowrap' }}>
                                    <div style={{ marginRight: '17px', alignSelf: 'flex-start' }}>
                                        <CheckCircleIcon style={{ fontSize: '30px', color: '#3EE665' }} />
                                    </div>
                                    <div>
                                        <div style={{ fontSize: '18px', fontWeight: 600 }}>
                                            {state.ChangeInfo.FromFullName}
                                        </div>
                                        <div style={{ fontSize: '16px' }}>
                                            {gstate.IsNorway.get() ? 'Godkjent' : 'Godkänt'}
                                        </div>
                                    </div>
                                </Grid>
                            </Box>
                            <Box background>
                                <Grid container direction="row" justifyContent="flex-start" flexWrap="nowrap" alignItems="center" style={{ flexWrap: 'nowrap' }}>
                                    <div style={{ marginRight: '17px', alignSelf: 'flex-start' }}>
                                        {state.ChangeInfo.ToConfirmed ? <CheckCircleIcon style={{ fontSize: '30px', color: '#3EE665' }} /> : <FiberManualRecordIcon style={{ fontSize: '38px', color: '#FDB33C', marginTop: '-4px' }} />}
                                    </div>
                                    <div>
                                        <div style={{ fontSize: '18px', fontWeight: 600 }}>
                                            {state.ChangeInfo.ToFullName}
                                        </div>
                                        <div style={{ fontSize: '16px' }}>
                                            {gstate.IsNorway.get() ?
                                                state.ChangeInfo.ToConfirmed ? 'Godkjent' : 'Venter på godkjenning'
                                                :
                                                state.ChangeInfo.ToConfirmed ? 'Godkänt' : 'Väntar på godkännande'
                                            }
                                        </div>
                                    </div>
                                </Grid>
                            </Box>
                        </Grid>
                        {!state.ChangeInfo.ToConfirmed &&
                            <div style={{ fontSize: '14px', marginBottom: '30px', textAlign: 'center' }}>
                            {gstate.IsNorway.get() ? <span>E-post er sendt til:<br /></span> : <span>E-post skickad till:<br /></span> }{state.ChangeInfo.ToEmail}
                            </div>
                        }
                        <Box background>
                            <div style={{ fontSize: '14px', textAlign: 'center' }}>
                            {gstate.IsNorway.get() ? 'Nummer som blir overført:' : 'Nummer för överföring:'}
                            </div>
                            {state.ChangeInfo.MSISDNs.map(element => {
                                return (
                                    <Grid key={element} container direction="row" style={{ marginTop: '10px' }}>
                                        <FiberManualRecordIcon style={{ fontSize: '14px', color: '#E3264E', marginRight: '13px' }} />
                                        <div key={element}>{element}</div>
                                    </Grid>
                                )
                            })}
                        </Box>
                        {!state.ChangeInfo.ToConfirmed &&
                            <>
                                <div style={{ fontSize: '14px', marginBottom: '34px', textAlign: 'center' }}>
                            {gstate.IsNorway.get() ? 'Når begge parter har godkjent, vil nummeret flyttes over til ny eier ved månedskiftet.' : 'När båda parter har godkänt, så kommer nummer att flyttas till ny ägare vid månadsskiftet.'}
                                </div>
                        <LargeButton disabled={state.CancelButtonDisabled} variant="outlined" onClick={() => { setState({ CancelButtonDisabled : true }); vm.$dispatch({ CancelOwnerChange: true }); }}>
                            {gstate.IsNorway.get() ? 'Avbryt eierskifte X' : 'Avbryt ägarbyte'}
                                </LargeButton>
                                <div style={{ fontSize: '12px', fontStyle: 'italic', marginBottom: '34px', textAlign: 'center', marginTop: '10px' }}>
                            {gstate.IsNorway.get() ? 'Hvis navnet eller e-posten er skrevet feil så velg denne knappen for å begynne på nytt' : 'Om namnet eller e-postadressen är felaktig så klicka på knappen för att börja på nytt'}
                                </div>
                            </>
                        }
                        {state.ChangeDate &&
                            <div style={{ fontSize: '12px', maxWidth: '300px', textAlign: 'center', marginTop: '4px' }}>
                                For å kansellere overføringen, ta kontakt med kundeservice.
                            </div>
                        }
                    </div>
                </>
            }
        </>
    );
}