import React from 'react';
import auth from "../auth";
import Version from "../version";
import ServerState from "../controls/ServerState";
import { useConnect } from "dotnetify";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import { LargeButton } from '../controls/components/Buttons';
import ReactLoading from 'react-loading';
import Icon from "../components/Selfcare/Common/Icon";

export default function FriendsList(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { vm, state, setState } = useConnect("FriendsList",
        {
            show: false,
            redirect: null,
            toUrl: null,
            buzy: true,
            DataLoading: true,
            ErrorField: '',
            ErrorText: '',
        },
        {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg: {
                SubscriptionNo: props.SubscriptionNo,
            },
            exceptionHandler: _ => auth.signOut()
        });
    return (
        <>
            <ServerState serverState={state} setState={setState} handleClose={props.handleClose} />
            <div style={{ height: '100vh' }}>
                <div style={{ backgroundColor: '#e91e63', color: '#FFF', padding: '20px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            <Icon name="friends" size={48} color="#FFFFFF" />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                Mina Kompisar
                            </div>
                            <div style={{ fontSize: '14px' }}>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10} style={{ padding: '20px' }} >
                            <div style={{ marginBottom: '4px' }}>
                                <b>Lista över Chilikompisar</b>
                            </div>
                            <div style={{ fontStyle: 'italic', marginLeft: '10px', marginBottom: '20px' }}>
                                {state.HasEnlistedByMe ?
                                    <div>
                                        {state.EnlistedByMe.map((name, index) => {
                                            return (
                                                <div key={index}>{name}</div>
                                            )

                                        })}
                                    </div>
                                    :
                                    <div>
                                        Du har inte värvat några kompisar ännu.
                                    </div>
                                }
                            </div>
                            <div style={{ fontStyle: 'italic' }}>
                                {state.EnlistedBy &&
                                    <div>
                                        Jag är värvad av {state.EnlistedBy}
                                    </div>
                                }
                            </div>
                            <div style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '50px', marginBottom: '-10px' }}>
                                Din rabatt per månad just nu:
                            </div>
                            <div style={{ fontSize: '100px', fontWeight: 'bold' }}>
                                {state.EnlistedDiscount} kr
                            </div>
                            <div style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '-20px' }}>
                                Rabatt/mån
                            </div>
                            {state.DataLoading ? <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', width: '100%' }}>
                                <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                            </div>
                                :
                                <div style={{ width: '100%', height: '50px' }}>
                                </div>}
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <LargeButton id='back' disabled={state.buzy} onClick={() => { props.GoBack(); }}><Icon name="back" size={32} color="#FFFFFF" />{t('selfcare:button.back')}</LargeButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}
