import React from "react"
import { Grid, Fade, Collapse } from "@mui/material"
import { SmallButton, LargeButton } from "./components/Buttons"

const PotButtons = ({
  pots,
  state,
  localState,
  disabled,
  handleBuyData,
  handleBuyDataReload,
  handleBuySuperboost,
  handleBuyEUData,
  handleDailyData,
  handleChangeDataLevel,
  handleSubscriptionPause,
  showLegend
}) => {
  const disableSuperboost = !pots?.[0]?.CanBuySuperboost ?? false
  const disableDataReload = !pots?.[0]?.CanBuyDataReload ?? false

  return (
    <Collapse in={!showLegend}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Fade
          in={localState.showBuyDataReloadButton}
          style={{
            display: localState.showBuyDataReloadButton ? "block" : "none"
          }}
        >
          <div>
            <LargeButton
              disabled={disabled || disableDataReload}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleBuyDataReload()
              }}
            >
              {state.IsSweden ? "Köp Data Reload" : "Bestill Data Reload"}
            </LargeButton>
          </div>
        </Fade>
        <Fade
          in={localState.showBuySuperboostButton}
          style={{
            display: localState.showBuySuperboostButton ? "block" : "none"
          }}
        >
          <div>
            <LargeButton
              disabled={disabled || disableSuperboost}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleBuySuperboost()
              }}
            >
              {state.IsSweden ? "Köp Full Fart" : "+ Bestill Superboost"}
            </LargeButton>
          </div>
        </Fade>
        <Fade
          in={localState.showBuyDataButton}
          style={{ display: localState.showBuyDataButton ? "block" : "none" }}
        >
          <div>
            <LargeButton
              disabled={disabled}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleBuyData()
              }}
            >
              {state.IsSweden ? "Köp mer Surf" : "+ Bestill datapakke"}
            </LargeButton>
          </div>
        </Fade>
        <Fade
          in={localState.showBuyEUDataButton}
          style={{ display: localState.showBuyEUDataButton ? "block" : "none" }}
        >
          <div>
            <LargeButton
              disabled={disabled}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleBuyEUData()
              }}
            >
              {state.IsSweden ? "Köp mer EU-Surf" : "+ Bestill EU-datapakke"}
            </LargeButton>
          </div>
        </Fade>
        <Fade
          in={localState.showChangeDataLevelButton}
          style={{
            display: localState.showChangeDataLevelButton ? "block" : "none"
          }}
        >
          <div>
            <LargeButton
              disabled={disabled}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleChangeDataLevel()
              }}
            >
              Endre datagrense
            </LargeButton>
          </div>
        </Fade>
        <Fade
          in={localState.showStartPauseButton}
          style={{
            display: localState.showStartPauseButton ? "block" : "none"
          }}
        >
          <div>
            <SmallButton
              disabled={disabled}
              style={{ margin: "5px 8px 17px" }}
              onClick={() => {
                handleDailyData()
              }}
            >
              {state.StartPauseButtonText}
            </SmallButton>
          </div>
        </Fade>
        <Fade
          in={localState.showPauseButton}
          style={{ display: localState.showPauseButton ? "block" : "none" }}
        >
          <div>
            <LargeButton
              disabled={disabled}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleSubscriptionPause(true)
              }}
            >
              Sett på pause
            </LargeButton>
          </div>
        </Fade>
        <Fade
          in={localState.showUnPauseButton}
          style={{ display: localState.showUnPauseButton ? "block" : "none" }}
        >
          <div>
            <LargeButton
              disabled={disabled}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleSubscriptionPause(false)
              }}
            >
              Gjenåpne abonnement
            </LargeButton>
          </div>
        </Fade>
      </Grid>
    </Collapse>
  )
}

export default PotButtons
