import React from "react";
import globalState from '../../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import MenuIcon from './MenuIcon';
import NavigationHelper from '../../utils/NavigationHelper';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

const nh = NavigationHelper;

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 13,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        fontFamily: theme.typography.fontFamilyRoboto,
        padding: '2px',
    },
}))(Badge);

const MenuItem = (props) => {
    const { t } = useTranslation();
    const gstate = useGlobalState(globalState);    
    const wDim = useWindowDimensions();
    const iconSize = 32;
    const theme = useTheme();
    const getPageName = function (index, packageName) {
        return t(nh.getPageName(index, packageName));
    }
    const currentIndex = gstate.currentIndex.get();
    const nav = index => {
        var elem = document.getElementById('menu-item-' + index);
        elem.click();
    }
    const invCount = gstate.InvoiceCount.get();
    return <>
        <Grid item aria-label={getPageName(props.index, gstate.PackageType.get())} role="tab" aria-selected={currentIndex === props.index} aria-controls="tabpanel" id={"tab-" + props.index} style={{ textAlign: 'center', textDecoration: 'none', cursor: 'pointer', marginLeft: wDim.desktop ? '-25px' : 'unset' }} onClick={(e) => { nav(props.index); } } className="noselect">
            <StyledBadge badgeContent={props.index === 1 ? invCount === 'undefined' ? 0 : invCount : 0} color="primary" overlap="rectangular">
                <IconButton size="large">
                    <MenuIcon name={currentIndex === props.index ? 'menu-' + props.index + '-fill' : 'menu-' + props.index} size={iconSize} />
                </IconButton>
            </StyledBadge>
            <div style={{ color: currentIndex === props.index ? theme.palette.primary.main : theme.palette.text.primary, marginTop: '-10px' }}>{getPageName(props.index, gstate.PackageType.get())}</div>
        </Grid>
    </>;
}

export default MenuItem;