import React from "react";
import MenuLink from './components/MenuLink';

const MenuLinks = () => {

    return (
        <div id="menu-links" style={{ display: 'none' }}>
            <MenuLink profile />
            <MenuLink index={0} />
            <MenuLink index={1} />
            <MenuLink index={2} />
            <MenuLink index={3} />
            <MenuLink index={4} />
        </div>
    );
};

export default MenuLinks;