import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import { useTranslation } from 'react-i18next';


var locale = process.env.REACT_APP_DEFAULTLOCALE;
i18n
    .use(XHR)
    .init({
        ns: ['selfcare'],
        debug: false,
        lng: locale,
        fallbackLng: locale, // use nb-NO if detected lng is not available
        preload: [locale],
        defaultNS: 'selfcare',
        keySeparator: false, // we do not use keys in form messages.welcome
        react: {
            useSuspense: false,
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        backend: {
            loadPath: window.location.origin + '/api/locales/load/{{lng}}/{{ns}}.json',
        },
    });

export default i18n;