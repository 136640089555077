import { React, useState } from "react"
import { useConnect } from "dotnetify"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

import auth from "../../auth"
import Version from "../../version"
import PageContainer from "../../controls/PageContainer"
import { SmallButton } from "../../controls/components/Buttons"

import styles from "./styles.module.css"
import Data from "./Data"
import SMS from "./SMS"
import Voice from "./Voice"

const Category = Object.freeze({
  DataNorway: 0,
  DataEU: 1,
  DataOutsideEU: 2,
  VoiceInNorway: 3,
  VoiceInEU: 4,
  VoiceOutsideEU: 5,
  VoiceFromNorwayToEU: 6,
  VoiceFromNorwayToOutsideEU: 7,
  SMSInNorway: 8,
  SMSInEU: 9,
  SMSOutsideEU: 10,
  SMSFromNorwayToEU: 11,
  SMSFromNorwayToOutsideEU: 12,
  Unknown: 13
})

const TabIndex = Object.freeze({
  Data: 0,
  SMS: 1,
  Voice: 2
})

export const ButtonLink = ({ href, text }) => (
  <SmallButton fullWidth>
    <a href={href} target="_blank" rel="noreferrer" style={{ all: "unset" }}>
      {text}
    </a>
  </SmallButton>
)

const History = () => {
  const { state } = useConnect(
    "History",
    {
      MonthlyHistory: []
    },
    {
      headers: {
        Authorization: "Bearer " + auth.getAccessToken(),
        ClientVersion: Version.getVersion(),
        ServerToken: window.sessionStorage.ServerToken
      },
      exceptionHandler: _ => auth.signOut()
    }
  )
  const {
    MonthlyHistory,
    PricesOutsideEU,
    PricesFromNorwayToAbroad,
    PricesSatellite,
    EUCountriesAgreement
  } = state

  const sumForMonth = (month, categories) =>
    month.Entries.map(e =>
      categories.includes(e.Category) ? e.Quantity : 0
    ).reduce((acc, q) => acc + q, 0)

  const dataSeries = categories =>
    MonthlyHistory.map(m => sumForMonth(m, categories))

  const [tab, setTab] = useState(0)

  const xLabels = MonthlyHistory.map(m => m.Month)

  const smsMonthlySummary = MonthlyHistory.map(m => {
    const sum = category => sumForMonth(m, [category])
    return {
      month: m.Month,
      norway: sum(Category.SMSInNorway),
      eu: sum(Category.SMSInEU),
      outsideEU: sum(Category.SMSOutsideEU),
      fromNorwayToEU: sum(Category.SMSFromNorwayToEU),
      fromNorwayToOutsideEU: sum(Category.SMSFromNorwayToOutsideEU)
    }
  }).toReversed()

  const voiceMonthlySummary = MonthlyHistory.map(m => {
    const sum = category => Math.round(sumForMonth(m, [category]) / 60)
    return {
      month: m.Month,
      norway: sum(Category.VoiceInNorway),
      eu: sum(Category.VoiceInEU),
      outsideEU: sum(Category.VoiceOutsideEU),
      fromNorwayToEU: sum(Category.VoiceFromNorwayToEU),
      fromNorwayToOutsideEU: sum(Category.VoiceFromNorwayToOutsideEU)
    }
  }).toReversed()

  return (
    <PageContainer back noSelect title="Forbruk">
      <Tabs
        value={tab}
        onChange={(_, newTab) => setTab(newTab)}
        textColor="secondary"
        className={styles.tabs}
        classes={{
          indicator: styles.indicator,
          scroller: styles.scroller
        }}
        sx={{
          backgroundColor: "boxGreyBackground2.main"
        }}
      >
        {["Data", "SMS", "Tale"].map(label => (
          <Tab
            key={label}
            label={label}
            disableRipple
            className={styles.tab}
            classes={{ selected: styles.selected }}
          />
        ))}
      </Tabs>
      {tab === TabIndex.Data ? (
        <Data
          norway={dataSeries([Category.DataNorway])}
          eu={dataSeries([Category.DataEU])}
          outsideEU={dataSeries([Category.DataOutsideEU])}
          xLabels={xLabels}
          pricesOutsideEU={PricesOutsideEU}
          euCountriesAgreement={EUCountriesAgreement}
          pricesSatellite={PricesSatellite}
        />
      ) : tab === TabIndex.SMS ? (
        <SMS
          norway={dataSeries([Category.SMSInNorway])}
          eu={dataSeries([Category.SMSInEU, Category.SMSFromNorwayToEU])}
          outsideEU={dataSeries([
            Category.SMSOutsideEU,
            Category.SMSFromNorwayToOutsideEU
          ])}
          xLabels={xLabels}
          monthlySummary={smsMonthlySummary}
          pricesOutsideEU={PricesOutsideEU}
          pricesFromNorwayToAbroad={PricesFromNorwayToAbroad}
          pricesSatellite={PricesSatellite}
        />
      ) : (
        tab === TabIndex.Voice && (
          <Voice
            norway={dataSeries([Category.VoiceInNorway])}
            eu={dataSeries([Category.VoiceInEU, Category.VoiceFromNorwayToEU])}
            outsideEU={dataSeries([
              Category.VoiceOutsideEU,
              Category.VoiceFromNorwayToOutsideEU
            ])}
            xLabels={xLabels}
            monthlySummary={voiceMonthlySummary}
            pricesOutsideEU={PricesOutsideEU}
            pricesFromNorwayToAbroad={PricesFromNorwayToAbroad}
            pricesSatellite={PricesSatellite}
          />
        )
      )}
    </PageContainer>
  )
}

export default History
