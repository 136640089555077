import React, { useEffect } from "react"

import PotDisplay from "./PotDisplay"
import PotButtons from "./PotButtons"

const PotContainer = ({
  pots,
  extState,
  localState,
  setLocalState,
  dailyDataStatus,
  disabled,
  handleBuyData,
  handleBuyDataReload,
  handleBuySuperboost,
  handleBuyEUData,
  handleDailyData,
  handleChangeDataLevel,
  handleSubscriptionPause,
  onClick
}) => {
  const showLegend = pots && pots.length > 0 && pots[0].ShowDetails
  const handleButtons = () => {
    const buttonConfig = [
      {
        condition: extState.CanBuyData && !showLegend,
        showProp: "showBuyDataButton"
      },
      {
        condition: extState.CanBuyEUData && !showLegend,
        showProp: "showBuyEUDataButton"
      },
      {
        condition: extState.CanBuySuperboost && !showLegend,
        showProp: "showBuySuperboostButton"
      },
      {
        condition: extState.CanBuyDataReload && !showLegend,
        showProp: "showBuyDataReloadButton"
      },
      {
        condition:
          extState.IsNorway &&
          extState.CanChangePriceplan &&
          extState.IsData &&
          !showLegend,
        showProp: "showChangeDataLevelButton"
      },
      {
        condition: extState.IsDailyData && !showLegend,
        showProp: "showStartPauseButton"
      },
      {
        condition: extState.CanPause,
        showProp: "showPauseButton"
      },
      {
        condition: extState.CanUnPause,
        showProp: "showUnPauseButton"
      }
    ]

    buttonConfig.forEach(({ condition, showProp }) => {
      setLocalState({ [showProp]: condition })
    })
  }

  useEffect(() => handleButtons(), [extState])

  return (
    <>
      <PotDisplay
        pots={pots}
        dailyDataStatus={dailyDataStatus}
        onClick={() => {
          handleButtons()
          if (!!onClick) onClick()
        }}
        showLegend={showLegend}
        legendShadow={true}
      />
      <PotButtons
        pots={pots}
        state={extState}
        localState={localState}
        disabled={disabled}
        handleBuyData={handleBuyData}
        handleBuyDataReload={handleBuyDataReload}
        handleBuySuperboost={handleBuySuperboost}
        handleBuyEUData={handleBuyEUData}
        handleDailyData={handleDailyData}
        handleChangeDataLevel={handleChangeDataLevel}
        handleSubscriptionPause={handleSubscriptionPause}
        showLegend={showLegend}
      />
    </>
  )
}

export default PotContainer
