import React, { useState, useEffect } from "react";
import produce from 'immer';
import auth from "../auth";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Icon from "../components/Selfcare/Common/Icon";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Divider } from '@mui/material';
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import { TextField } from '../controls/TextField';

export default function PostpaidTopup(props) {
    const { t } = useTranslation();
    const { vm, state, setState } = useConnect("PostpaidTopup", { EnterPasswordDialogOpen: false, termsAccepted: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });
    useEffect(() => {
        if (state.pInfo) {
            gstate.paymentInfo.set(state.pInfo);
        }
        if (state.GoNets)
            window.location.assign(state.GoNets);
    }, [state.GoNets]);

    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    const handleChange = event => {
        setState({ value: event.target.value, canbuy: true });
    };
    const handleInputChange = (name, obj) => event => {
        if (event.target.type === 'checkbox') { // Handle checkboxes
            if (!obj)
                setState({ [name]: event.target.checked, ErrorField: '', ErrorText: '' });
            else
                setState({ [obj.objId]: { ...obj, [name]: event.target.checked } });//

        } else { //Handle other controls
            if (!obj)
                setState({ [name]: event.target.value, ErrorField: '', ErrorText: '' });
            else
                setState({ [obj.objId]: { ...obj, [name]: event.target.value } });//
        }
        //Clear any validation errors
        setState({ ErrorField: '', ErrorText: '', OrderError: '' });
    }
    return (
        <>
            <ServerState serverState={state} setState={setState} handleClose={props.handleClose} handleRefresh={props.handleRefresh} />
            {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
            <div>
                <Dialog
                    open={state.EnterPasswordDialogOpen}
                    onClose={() => { setState({ EnterPasswordDialogOpen: false, buzy: false }); }}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <div style={{ display: 'flex' }}>
                            <Icon name="edit" size={36} color={theme.palette.primary.main} />
                            <span style={{ marginLeft: '20px', fontWeight: '700' }}>{t('selfcare:title.code-set-by-legal-owner')}</span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {state.PasswordText}<br /><br />
                        <TextField fullWidth
                            autoFocus
                            localState={state}
                            name="password"
                            setLocalState={setState.bind(this)}
                            id="password"
                            label={t('selfcare:label.code')}
                            type="text"
                            margin="normal"
                        />
                        {state.PasswordErrorText && <div style={{ width: '100%', padding: '5px', backgroundColor: theme.palette.primary.main, color: 'white', fontSize: '12px' }}>{state.PasswordErrorText}</div>}
                    </DialogContent>
                    <DialogActions>
                        <LargeButton onClick={() => { vm.$dispatch({ SetPassword: state.password }); }}>
                            {t('selfcare:button.proceed-uc')}
                        </LargeButton>
                        <LargeButton variant="text" onClick={() => { setState({ EnterPasswordDialogOpen: false, buzy: false }); props.handleClose(); }} color="secondary">
                            {t('selfcare:button.cancel-uc')}
                        </LargeButton>
                    </DialogActions>
                </Dialog>
                <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            <Icon name="money" size={48} color="#FFFFFF" />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                {state.IsSweden ? <div>Köp mer surf - för användning inom Sverige</div> : <div>Kjøp mer data</div>}
                            </div>
                            {state.IsSweden && <div style={{ fontSize: '14px' }}>
                                De surfpaket som går att beställa här gäller enbart för surf inom Sverige. Vill du köpa extra surf som skall användas inom EU/EES, så väljer du ”Köp mer surf – för användning inom EU/EES
                            </div>}
                        </Grid>
                    </Grid>
                </div>
                <form onSubmit={(e) => { e.preventDefault(); setState({ buzy: true }); vm.$dispatch({ PerformChange: { SelectedProductNo: state.value, termsAccepted: state.termsAccepted } }); }}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            <div style={{ paddingTop: '20px', textAlign: 'center' }}>
                                {state.IsSweden ? <div>Välj surfpaket för användning i Sverige</div> : <div>Velg datapakke</div>}
                            </div>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label={t('selfcare:label.datapackets')}
                                    name="data"
                                    value={state.value}
                                    onChange={handleChange}
                                >
                                    {state.TopUps ? state.TopUps.map((option) => {
                                        return (
                                            <FormControlLabel key={option.ProductNo} value={option.ProductNo} control={<Radio color="primary" />} label={option.Description} style={{ fontWeight: 700 }} />
                                        );
                                    }) : null}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {state.IsSweden ?
                            <Grid item>
                                {t('selfcare:item.datapacket-duration')}
                            </Grid>
                            :
                            <>
                                <div style={{ textAlign: 'left', width: '100%', maxWidth: '400px', marginLeft: '20px', paddingRight: '20px' }}>
                                    <FormControlLabel
                                        style={{ display: 'table', marginTop: '10px' }}
                                        control={
                                            <Checkbox
                                                id="termsAccepted"
                                                disabled={state.Disabled || state.buzy}
                                                checked={state.termsAccepted}
                                                onChange={handleInputChange('termsAccepted')}
                                                color="primary"
                                                style={{ display: 'table-cell', verticalAlign: 'top' }}
                                            />
                                        }
                                        label=
                                        {
                                            <div style={{ fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>
                                                <div>Datapakken varer i 30 dager. Ekstra datapakke kan ikke angres. Denne datapakken kan kun brukes i Norge og i EU/EØS-land.</div>
                                                <br />
                                                <div><Link color="primary" onClick={(e) => { props.openUrl(state.EUCountriesAgreement, true); e.preventDefault(); }} style={{ fontSize: '14px', fontFamily: theme.typography.fontFamily2, cursor: 'pointer' }}>Se land med EU/EØS-avtale →</Link></div>
                                                <div><Link color="primary" onClick={(e) => { props.openUrl(state.PricesOutsideEU, true); e.preventDefault(); }} style={{ fontSize: '14px', fontFamily: theme.typography.fontFamily2, cursor: 'pointer' }}>Utenfor EU/EØS gjelder egne priser →</Link></div>
                                            </div>
                                        }
                                    />
                                </div>
                                {state.ErrorField === 'termsAccepted' && <div style={{ color: theme.palette.error.main, marginTop: '5px' }}>{state.ErrorText}</div>}
                            </>
                        }
                        {state.IsSweden && <Grid item xs={12} sm={10} style={{ padding: '20px' }}>
                            Surfpaketen betalas direkt med kort- eller swishbetalning, du skickas vidare till Nets efter bekräftat köp.
                        </Grid>}
                        <Grid item style={{ paddingTop: '20px' }}>
                            <Grid container direction="column" justifyContent="center">
                                <Grid container justifyContent="center" direction="row">
                                    <LargeButton type="submit" disabled={!state.canbuy || state.OrderOpen || state.buzy} style={{ marginRight: '10px' }} >{t('selfcare:button.confirm-purchase')}</LargeButton>
                                    <LargeButton id='cancel' variant="text" color="secondary" onClick={() => { props.handleClose() }}>{t('selfcare:button.cancel')}</LargeButton>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    {(state.Processing || state.buzy) && <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
}