import { Grid } from '@mui/material';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { useConnect } from 'dotnetify';
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import auth from "../auth";
import ServerState from '../controls/ServerState';
import { LargeButton } from '../controls/components/Buttons';
import Version from "../version";
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/nb';
import 'dayjs/locale/sv';
import { nbNO, svSE } from '@mui/x-date-pickers/locales';

export default function ChangePortingDate(props) {
    const { t } = useTranslation();
    const formatDate = date => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    };
    const toDisplayDate = (date, time) => {
        const pad = n => n.toString().padStart(2, '0');
        const d = new Date(date),
            day = pad(d.getDate()),
            month = pad(d.getMonth() + 1),
            year = d.getFullYear()
        return `${day}.${month}.${year} klokken ${time}`
    }

    const { vm, state, setState } = useConnect("ChangePortingDate", { Options: ['09:00', '10:00'], PortingTime: '09:00', PortingDate: formatDate(new Date()), CheckSimReceived: false, BypassSimWarning: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });

    const isTimeDisabled = (option) => state.DisabledTimes?.includes(option)

    const getDate = () => {
        var y = dayjs(state.PortingDate);
        return y;
    //    var x = new Date(Date.parse(state.PortingDate));
    //    return x;
    }
    const handleDateChange = date => {
        var d = date.format('YYYY-MM-DD');
        setState({ PortingDate: d, PortingTime: state.Options[0], BypassSimWarning: false });
        if (vm) {
            vm.$dispatch({ SetDate: d });
            vm.$dispatch({ ShouldCheckSimReceived: d });
        }
    };

    const shouldDisableDate = (date) => {
        if (date.day() === 0 || date.day() === 6) {
            return true; //Weekend
        }
        if (state.Holidays && state.Holidays.includes(date.format('YYYY-MM-DD'))) {
            return true; //Red day from Sparkle Calendar
        }
        return false;
    }

    const addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return dayjs(result);
    }

    const currentDate = new Date();
    const minDate = dayjs(currentDate);
    const maxDate = dayjs(addDays(currentDate, 80));


    const [displayWarning, setDisplayWarning] = useState(false);

    const onCloseSubdialog = () => {
        setDisplayWarning(false);
        setDisplayMultipleSubscriptionsInfo(false);
        setCheckboxChecked(false);
        props.setTitle("Endre aktiveringsdato");
    }

    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [checkboxDisabled, setCheckboxDisabled] = useState(true);
    useEffect(() => {
        if (displayWarning) {
            setCheckboxDisabled(true);
            const timeout = setTimeout(() => {
                setCheckboxDisabled(false);
            }, 5000);
            return () => clearTimeout(timeout);
        }
    }, [displayWarning]);


    // TODO: Simplify CSS
    const theme = useTheme();
    const textStyle = {
        fontSize: '14px',
        fontFamily: theme.typography.fontFamily2,
        maxWidth: '400px',
        padding: '0px 17px'
    }

    const [displayMultipleSubscriptionsInfo, setDisplayMultipleSubscriptionsInfo] = useState(false);

    const performChange = () => {
        if (state.SubscriptionCount && state.SubscriptionCount > 1) {
            props.setTitle("Ønsker du å endre aktiveringsdato på flere abonnement?");
            setDisplayMultipleSubscriptionsInfo(true);
        }
        vm.$dispatch({ PerformChange: { date: formatDate(state.PortingDate), time: state.PortingTime } });
    }

    useEffect(() => props.setTitle("Endre aktiveringsdato"), []);

    return (
        <>
            <div style={{
                backgroundColor: 'white',
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: '100',
                visibility: displayWarning ? 'visible' : 'hidden',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ...textStyle
                }} >
                    <Checkbox disabled={checkboxDisabled} checked={checkboxChecked} onChange={(event) => setCheckboxChecked(event.target.checked)} />
                    <div>Merk at ditt gamle SIM-kort hos tidligere selskap vil slutte å fungere samtidig som nytt SIM-kort fra oss, begynner å fungere.</div>
                </div>
                <div style={{ ...textStyle, paddingLeft: '59px' }} >Posten bruker oftest mellom 4-6 dager på å levere SIM-kort.</div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    ...textStyle
                }}
                >
                    <div>Aktivering satt til:</div>
                    <div style={{ fontWeight: 'bold' }}>{toDisplayDate(state.PortingDate, state.PortingTime)}</div>
                    <div>Nummer: {state.Msisdn}</div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    alignItems: 'center'
                }}>
                    <LargeButton
                        disabled={!checkboxChecked}
                        onClick={() => {
                            onCloseSubdialog();
                            performChange();
                        }}>
                        Godkjenn endring
                    </LargeButton>
                    <LargeButton
                        color="secondary"
                        variant="text"
                        onClick={onCloseSubdialog}>
                        Avbryt
                    </LargeButton>
                </div>
            </div>
            <div style={{
                backgroundColor: 'white',
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: '100',
                visibility: !displayWarning && displayMultipleSubscriptionsInfo ? 'visible' : 'hidden',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center'
            }}>
                <div style={textStyle}>
                    Hvis du vil endre dato på dine andre abonnement, så bla helt ned på siden og gjør endringen på hver enkelt abonnement.
                </div>
                <LargeButton
                    onClick={onCloseSubdialog}
                >
                    Ok
                </LargeButton>
            </div>
            <ServerState serverState={state} localState={state} setLocalState={setState} handleClose={props.handleClose} handleRefresh={props.handleRefresh} />
            {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
            {state.buzy &&
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px' }} >
                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                </div>
            }
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <div style={textStyle}>
                    Velg ny dag og tidspunkt under. Teknisk er det noen begrensninger ved hvor raskt man kan flytte et mobilnummer, disse begrensingene er tatt med i dine valgmuligheter for ny dag og tidspunkt.<br />
                    Alle overføringer skjer mellom klokken 09:00 og 14:00
                </div>
                <div style={{ maxWidth: '500px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={state.IsNorway ? 'nb' : 'sv'}>
                        <DateCalendar
                            minDate={minDate}
                            maxDate={maxDate}
                            value={getDate()}
                            firstDayOfWeek={1}
                            open={true}
                            onChange={handleDateChange}
                            shouldDisableDate={shouldDisableDate}
                        />
                    </LocalizationProvider>
                </div>
                <div>
                    <Select
                        native
                        value={state.PortingTime}
                        onChange={(event) => setState({ PortingTime: event.target.value })}
                        style={{ width: 150 }}
                    >
                        {state.Options.map(
                            option => <option key={option} value={option} disabled={isTimeDisabled(option)}>{option}</option>
                        )}
                    </Select>
                </div>
                <Grid container direction="row" justifyContent="center" style={{ marginTop: '24px' }}>
                    <LargeButton onClick={() => {
                        if (state.CheckSimReceived && !state.BypassSimWarning) {
                            setDisplayWarning(true);
                            props.setTitle("Har du mottatt SIM-kortet fra Chilimobil?");
                            return;
                        }
                        performChange();
                        setState({ buzy: true });
                    }} disabled={state.buzy}>{state.IsNorway ? 'Godkjenn' : 'Godkänn'}</LargeButton>
                </Grid>
            </Grid>

        </>
    );
}