import React from "react"
import Version from "../version"
import auth from "../auth"
import { useConnect } from "dotnetify"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material/styles"
import ServerState from "../controls/ServerState"
import { Checkbox, Grid } from "@mui/material"
import { LargeButton } from "../controls/components/Buttons"
import { TextField } from "../controls/TextField"
import ReactLoading from "react-loading"
import Icon from "../components/Selfcare/Common/Icon"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"
import FormHelperText from "@mui/material/FormHelperText"

const Blocking = ({ SubscriptionNo, handleClose, handleRefresh }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { vm, state, setState } = useConnect(
    "Blocking",
    {
      show: false,
      redirect: null,
      toUrl: null,
      Password: "",
      OrderSent: false,
      OrderError: "",
      buzy: false,
      ErrorField: "",
      ErrorText: "",
      Type: "",
      reason: ""
    },
    {
      headers: {
        Authorization: "Bearer " + auth.getAccessToken(),
        ClientVersion: Version.getVersion(),
        ServerToken: window.sessionStorage.ServerToken
      },
      vmArg: {
        SubscriptionNo
      },
      exceptionHandler: _ => auth.signOut()
    }
  )

  const handleChange = name => event => {
    setState({ [name]: event.target.value, ErrorField: "" })
  }

  const [checkboxChecked, setCheckboxChecked] = React.useState(false)

  return (
    <>
      <ServerState
        serverState={state}
        setState={setState}
        handleClose={handleClose}
        handleRefresh={handleRefresh}
      />
      <div style={{ height: "100vh" }}>
        {state.IsAdministrating && (
          <div
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "5px",
              textAlign: "center"
            }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t("selfcare:info.now-administrating-number", {
                  0: state.AdministratedMsisdn
                })
              }}
            />
          </div>
        )}
        <div
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            padding: "20px"
          }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10}>
              <Icon
                name="block-sim"
                size={48}
                color={theme.palette.common.white}
              />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  maxWidth: "900px"
                }}
              >
                {t("selfcare:item.crisismustblock")}
              </div>
              <div style={{ fontSize: "14px", lineHeight: "16px" }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("selfcare:item.howtoblock", { 0: state.Type })
                  }}
                />
                {state.Type === "abonnement" && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("selfcare:item.blockedfixedprices")
                    }}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <form
          onSubmit={e => {
            e.preventDefault()
            setState({ buzy: true, ErrorField: "" })
            vm.$dispatch({
              PerformChange: { Password: state.Password, Reason: state.reason }
            })
          }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10}>
              {state.Type && (
                <>
                  {state.BlockingMessage && (
                    <div
                      style={{
                        paddingTop: "20px",
                        paddingLeft: "10px",
                        paddingRight: "10px"
                      }}
                    >
                      {state.BlockingMessage}
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <div style={{ paddingTop: "10px" }}>
                          <LargeButton
                            id="back"
                            disabled={state.buzy}
                            onClick={() => handleClose()}
                          >
                            <Icon name="back" size={32} color="#FFFFFF" />
                            {t("selfcare:button.back")}
                          </LargeButton>
                        </div>
                      </Grid>
                    </div>
                  )}
                  {!state.BlockingMessage && !state.Blocked && (
                    <div
                      style={{
                        paddingTop: "20px",
                        paddingLeft: "10px",
                        paddingRight: "10px"
                      }}
                    >
                      <span style={{ fontWeight: "700" }}>
                        {t("selfcare:info.block-sim-for")}{" "}
                      </span>
                      <span
                        style={{
                          color: theme.palette.primary.main,
                          fontWeight: "700"
                        }}
                      >
                        {state.FormattedMSISDN}
                      </span>
                      <div style={{ fontWeight: "700", paddingTop: "10px" }}>
                        {t("selfcare:phrase.reason")}:
                      </div>
                      <FormControl
                        component="fieldset"
                        required
                        error={state.ErrorField === "Reason"}
                      >
                        <RadioGroup
                          aria-label={t("selfcare:label.reason")}
                          name="reason"
                          value={state.reason}
                          onChange={handleChange("reason")}
                        >
                          <FormControlLabel
                            value="4"
                            control={
                              <Radio color="primary" disabled={state.buzy} />
                            }
                            label={t("selfcare:label.lostphone")}
                          />
                          <FormControlLabel
                            value="5"
                            control={
                              <Radio color="primary" disabled={state.buzy} />
                            }
                            label={t("selfcare:label.stolenphone")}
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {state.ErrorField === "Reason" && state.ErrorText}
                        </FormHelperText>
                      </FormControl>
                      <div style={{ maxWidth: "320px", paddingTop: "17px" }}>
                        <TextField
                          fullWidth
                          noPadding
                          disabled={state.buzy}
                          localState={state}
                          setLocalState={setState}
                          label={t("selfcare:label.password")}
                          id="Password"
                          name="Password"
                          type="text"
                          maxLength={30}
                          margin="normal"
                        />
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontStyle: "italic",
                          paddingTop: "20px"
                        }}
                      >
                        {t("selfcare:info.iploggedblock", { 0: state.Type })}
                      </div>
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxChecked}
                              onChange={() =>
                                setCheckboxChecked(!checkboxChecked)
                              }
                            />
                          }
                          label={
                            <p style={{ fontSize: "0.875rem" }}>
                              Faste månedspriser løper selv om du sperrer
                              abonnementet ditt. Hvis du heller ønsker å
                              avslutte abonnementet ditt, ta kontakt med
                              kundeservice eller{" "}
                              <a
                                href="https://www.chilimobil.no/sporsmal-hjelp/avslutte-ditt-chilimobil-abonnement/"
                                target="__blank"
                                rel="noopener"
                              >
                                følg denne lenken
                              </a>
                              .
                            </p>
                          }
                        />
                        <div>
                          <LargeButton
                            type="submit"
                            disabled={state.buzy || !checkboxChecked}
                            style={{ marginRight: "10px" }}
                          >
                            {t("selfcare:button.block")}
                          </LargeButton>
                          <LargeButton
                            id="cancel"
                            color="secondary"
                            variant="text"
                            disabled={state.buzy}
                            onClick={() => {
                              handleClose()
                            }}
                          >
                            {t("selfcare:button.cancel")}
                          </LargeButton>
                        </div>
                        {state.buzy && (
                          <Grid item>
                            <ReactLoading
                              type={"bubbles"}
                              color={theme.palette.primary.main}
                              height={"50px"}
                              width={"50px"}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  )}
                  {!state.BlockingMessage &&
                    state.Blocked &&
                    state.CanUnblock && (
                      <div
                        style={{
                          paddingTop: "20px",
                          paddingLeft: "10px",
                          paddingRight: "10px"
                        }}
                      >
                        <span style={{ fontWeight: "700" }}>
                          {t("selfcare:info.open-sim-for")}{" "}
                        </span>
                        <span
                          style={{
                            color: theme.palette.primary.main,
                            fontWeight: "700"
                          }}
                        >
                          {state.FormattedMSISDN}
                        </span>
                        <div style={{ maxWidth: "320px", paddingTop: "17px" }}>
                          <TextField
                            fullWidth
                            error={state.ErrorField === "Password"}
                            noPadding
                            disabled={state.buzy}
                            localState={state}
                            setLocalState={setState}
                            label={t("selfcare:label.password")}
                            name="Password"
                            id="Password"
                            inputProps={{ maxLength: 30, type: "password" }}
                            margin="normal"
                          />
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            paddingTop: "20px"
                          }}
                        >
                          {t("selfcare:info.iploggedunlock", { 0: state.Type })}
                        </div>
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-around"
                          alignItems="center"
                        >
                          <div style={{ paddingTop: "20px" }}>
                            <LargeButton
                              type="submit"
                              disabled={state.buzy}
                              style={{ marginRight: "10px" }}
                            >
                              {t("selfcare:button.unlock")}
                            </LargeButton>
                            <LargeButton
                              id="cancel"
                              variant="text"
                              color="secondary"
                              disabled={state.buzy}
                              onClick={() => handleClose()}
                            >
                              {t("selfcare:button.cancel")}
                            </LargeButton>
                          </div>
                          {state.buzy && (
                            <Grid item>
                              <ReactLoading
                                type={"bubbles"}
                                color={theme.palette.primary.main}
                                height={"50px"}
                                width={"50px"}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    )}
                </>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  )
}

export default Blocking
