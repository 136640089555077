import React from "react"
import DataMeter from "./components/DataMeter"
import Icon from "../components/Selfcare/Common/Icon"
import theme from "./../theme"

const PotControl = ({ Pot, multiple, hideChevron }) => {
  var left,
    right = false
  if (multiple) {
    if (Pot.Index % 2 === 0) {
      right = true
    } else {
      left = true
    }
  }
  return (
    <div
      style={{
        padding: multiple
          ? left
            ? "0px 10px 0px 0px"
            : "0px 0px 0px 10px"
          : "0px 25px 0px 25px",
        marginRight: multiple ? (right ? "-20px" : "unset") : "unset",
        marginLeft: multiple ? (left ? "-20px" : "unset") : "unset",
        marginBottom: "1rem"
      }}
    >
      {Pot && (
        <React.Fragment>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "12px",
              lineHeight: "12px",
              fontWeight: "300",
              marginBottom: "8px",
              marginTop: "5px"
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: Pot.Title }} />
          </div>
          <DataMeter Pot={Pot} />
          {!hideChevron && Pot.HasDetails && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "-40px",
                marginBottom: "20px"
              }}
            >
              <div
                style={{ height: "24px" }}
                className={Pot.ShowDetails ? "downarrow active" : "downarrow"}
              >
                <Icon
                  name="down-arrow"
                  size={24}
                  color={theme.palette.primary.main}
                />
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default PotControl
