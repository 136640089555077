import { createState } from '@hookstate/core';
//import { Persistence } from '@hookstate/persistence';
import CreatePersistor from 'hookstate-persist';


const initialState = {
    //menuVisible: false,
    //profileVisible: false,
    //topModuleName: "",
    //topModuleUrl: "",
    //currentIndex: 0,
    //firstname: '',
    //toUrl: '',
};
const globalState = createState(initialState);
const persistor = CreatePersistor({
    key: '@selfcare_settings', // store name
    engine: sessionStorage, // storage engine which implements getItem & setItem
});
globalState.attach(persistor);
//globalState.attach(Persistence('@selfcare_settings'))
export default globalState;