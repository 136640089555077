import React, { useRef, useState } from "react"
import produce from "immer"
import auth from "../auth"
import Version from "../version"
import useWindowDimensions from "../hooks/useWindowDimensions"
import { useConnect } from "dotnetify"
import globalState from "../state/GlobalState"
import { useState as useGlobalState } from "@hookstate/core"
import TopContainer from "../controls/TopContainer"
import { Box, BoxTitle, BoxContent, BoxLink } from "../controls/Box"
import ServerState from "../controls/ServerState"
import SparkleDialog from "../controls/components/SparkleDialog"
import { Grid, Divider } from "@mui/material"
import { LargeButton, SmallButton } from "../controls/components/Buttons"
import theme from "../theme"
import Chatbot from "../assets/img/Chatbot.png"
import CustomerServicePerson from "../assets/img/CustomerServicePerson.png"
import SupportIcon from "../controls/components/SupportIcon"
import Tooltip from "@mui/material/Tooltip"
import withStyles from '@mui/styles/withStyles';

// TODO: Reuse of design component from Invoice.jsx, move somewhere so both refer to the same component
const BlackTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 11,
        fontFamily: "Arial"
    },
    arrow: {
        color: theme.palette.common.black
    }
}))(Tooltip)

export default function Support() {
    const { state } = useConnect(
        "Support",
        {},
        {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            exceptionHandler: _ => auth.signOut()
        }
    )
    const [localState, internalSetLocalState] = useState({
        disabled: false,
        busy: false,
        showBuyDataButton: false,
        dialogOpen: false,
        ViaplayBuzy: false,
        CancelViaplayDialogOpen: false,
        ActivateViaplayDialogOpen: false
    })
    const setLocalState = o => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0]
            })
        )
    }

    const gstate = useGlobalState(globalState)
    const dialogRef = useRef()

    const openUrl = url => {
        dialogRef.current.openUrl(url)
    }
    const wDim = useWindowDimensions()
    var supportLinks
    if (gstate.IsNorway.get()) {
        supportLinks = [
            {
                description: "Har du problemer med mobilen?",
                url: " https://www.chilimobil.no/sporsmal-hjelp/mobilhjelp-og-oppsett/mobilproblem-mobiloppsett/",
                icon: "phone",
                type: "background"
            },
            {
                description: "Fakturaspørsmål",
                url: "https://www.chilimobil.no/sporsmal-hjelp/faktura/",
                icon: "question",
                type: "background"
            },
            {
                description: "EU/EØS og utlandet - priser",
                url: "https://www.chilimobil.no/sporsmal-hjelp/mobilabonnement-norge-utlandet/",
                icon: "question",
                type: "background"
            },
            {
                description: "Annet - Spørsmål og hjelp",
                url: "https://www.chilimobil.no/sporsmal-hjelp/",
                icon: "question",
                type: "background"
            }
        ]
    } else {
        supportLinks = [
            {
                description: "Abonnemang",
                url: "https://chilimobil.se/support/abonnemang/",
                icon: "question",
                type: "background"
            },
            {
                description: "Betalning",
                url: "https://chilimobil.se/support/betalning/",
                icon: "question",
                type: "background"
            },
            {
                description: "I utlandet",
                url: "https://chilimobil.se/support/i-utlandet/",
                icon: "question",
                type: "background"
            },
            {
                description: "Leverans",
                url: "https://chilimobil.se/support/leverans/",
                icon: "question",
                type: "background"
            },
            {
                description: "Surfmängd",
                url: "https://chilimobil.se/support/surfmangd/",
                icon: "question",
                type: "background"
            },
            {
                description: "Teknik - Fix & trix",
                url: "https://chilimobil.se/support/teknik-fix-trix/",
                icon: "question",
                type: "background"
            }
        ]
    }

    const right = "-2.5rem" // Magic number for shifting the chatbot image

    const [emailTooltipOpen, setEmailTooltipOpen] = useState(false)
    const [phoneTooltipOpen, setPhoneTooltipOpen] = useState(false)
    const onCopySuccess = field => {
        const setTooltipOpen =
            field === "phone"
                ? setPhoneTooltipOpen
                : field === "email"
                    ? setEmailTooltipOpen
                    : () => { }
        setTooltipOpen(true)
        setTimeout(() => setTooltipOpen(false), 2000)
    }

    return (
        <React.Fragment>
            <ServerState
                serverState={state}
                localState={localState}
                setLocalState={setLocalState}
            />
            <SparkleDialog ref={dialogRef} />
            <TopContainer
                hideprofile={gstate.IsNorway.get()}
                title={gstate.IsSweden.get() ? "Support" : "Support"}
                background={gstate.IsNorway.get()}
            >
                {gstate.IsNorway.get() && (
                    <Box
                        background
                        style={{
                            width: wDim.desktop ? "90%" : `calc(100% - 1.25rem)`,
                            position: "relative",
                            right: "0.6125rem",
                            marginBottom: "2rem"
                        }}
                    >
                        <Grid container direction="row" justifyContent="space-between">
                            <div style={{ width: "80%" }}>
                                <h3>Spør Chatboten?</h3>
                                <p>Få hjelp døgnet rundt med chatboten vår.</p>
                                <a href={state.ChatbotUrl} target="_blank" rel="noreferrer">
                                    <SmallButton>Spør chatboten</SmallButton>
                                </a>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: right
                                }}
                            >
                                <img
                                    src={Chatbot}
                                    alt=""
                                    style={{
                                        width: "84px",
                                        height: "84px",
                                        position: "relative",
                                        right
                                    }}
                                />
                            </div>
                        </Grid>
                    </Box>
                )}
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    {supportLinks.map((link, index) => {
                        return (
                            <Box
                                key={index}
                                border={link.type === "border"}
                                background={link.type === "background"}
                                linkAction={() => {
                                    openUrl(link.url)
                                }}
                            >
                                <BoxLink icon={link.icon} text={link.description} />
                            </Box>
                        )
                    })}
                </Grid>
                {gstate.IsNorway.get() && (
                    <Box
                        background
                        style={{ width: wDim.desktop ? "47%" : "100%", marginTop: "1rem" }}
                    >
                        <BoxTitle>Åpningstider</BoxTitle>
                        <BoxContent>
                            <Grid
                                container
                                style={{
                                    height: "60px",
                                    fontSize: "16px",
                                    marginTop: "0.5rem"
                                }}
                            >
                                <Box
                                    style={{ background: "white", width: "75%", padding: "10px" }}
                                >
                                    <Grid container justifyContent="space-between">
                                        <div style={{ fontWeight: "bold" }}>{state.CSDays}</div>
                                        <div>{state.CSHours}</div>
                                    </Grid>
                                </Box>
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="flex-end"
                                    style={{
                                        background: "transparent",
                                        width: "25%",
                                        position: "relative"
                                    }}
                                >
                                    <img
                                        src={CustomerServicePerson}
                                        alt=""
                                        style={{
                                            height: "120px",
                                            position: "absolute",
                                            top: "-60px"
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Divider style={{ width: "100%" }} />
                            <div style={{ margin: "0.5rem 0 0.5rem 0", textAlign: "center" }}>
                                Trykk for å kopiere
                            </div>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignContent="center"
                                style={{ margin: "0.5rem" }}
                            >
                                <SupportIcon
                                    name="phonecustomerservice"
                                    color={theme.palette.primary.main}
                                    size={32}
                                />
                                <BlackTooltip
                                    open={phoneTooltipOpen}
                                    title={"Nummeret er kopiert"}
                                    placement="top"
                                    arrow
                                >
                                    <button
                                        style={{
                                            background: "white",
                                            width: "85%",
                                            fontSize: "16px",
                                            color: theme.palette.primary.main,
                                            textAlign: "left",
                                            border: "none",
                                            borderRadius: "10px"
                                        }}
                                        onClick={() => { navigator.clipboard.writeText("+47 915 02 445"); onCopySuccess("phone"); }}
                                    >
                                        +47 915 02 445
                                    </button>
                                </BlackTooltip>
                            </Grid>
                            <Grid
                                container
                                justifyContent="space-between"
                                style={{ margin: "0.5rem" }}
                            >
                                <SupportIcon
                                    name="emailcustomerservice"
                                    color={theme.palette.primary.main}
                                    size={32}
                                />
                                <BlackTooltip
                                    open={emailTooltipOpen}
                                    title={"E-post er kopiert"}
                                    placement="top"
                                    arrow
                                >
                                    <button
                                        style={{
                                            background: "white",
                                            width: "85%",
                                            fontSize: "16px",
                                            color: theme.palette.primary.main,
                                            textAlign: "left",
                                            border: "none",
                                            borderRadius: "10px"
                                        }}
                                        onClick={() => { navigator.clipboard.writeText("kundeservice@chilimobil.no"); onCopySuccess("email"); }}
                                    >
                                        kundeservice@chilimobil.no
                                    </button>
                                </BlackTooltip>
                            </Grid>
                        </BoxContent>
                    </Box>
                )}
                {gstate.IsSweden.get() && (
                    <>
                        <Box
                            background
                            linkAction={() => {
                                openUrl("https://chilimobil.se/kontakta-oss/")
                            }}
                        >
                            <BoxLink icon={"question"} text={"Kontakta oss"} />
                        </Box>
                        <Box background>
                            <BoxTitle>Om Chilimobil MittChili</BoxTitle>
                            <BoxContent>
                                MittChili är utvecklad av Elkraps AB.
                                <br />
                                Server version: {state.ServerVersion} Klient version{" "}
                                {state.ClientVersion}
                            </BoxContent>
                        </Box>
                    </>
                )}
                {!wDim.large && (
                    <LargeButton
                        style={{ marginTop: "27px" }}
                        onClick={() => auth.signOutManually()}
                    >
                        {gstate.IsNorway.get() ? "Logg ut" : "Logga ut"}
                    </LargeButton>
                )}
            </TopContainer>
        </React.Fragment>
    )
}
