import React, { useEffect } from "react"
import auth from "../auth"
import Version from "../version"
import { useConnect } from "dotnetify"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material/styles"
import ServerState from "../controls/ServerState"
import { LargeButton, SmallButton } from "../controls/components/Buttons"
import { TextField } from "../controls/TextField"
import ReactLoading from "react-loading"
import Icon from "../components/Selfcare/Common/Icon"
import { Grid } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import Popover from "../controls/components/Popover/Popover"

export default function OrderSimcard(props) {
  const { t } = useTranslation()
  const theme = useTheme()
  var simType = sessionStorage.simType || "main"
  var biData, cbState
  const callbackState = sessionStorage.CallbackStateRet
  if (callbackState) {
    if (callbackState !== "[object Object]") {
      cbState = JSON.parse(callbackState)
      sessionStorage.removeItem("CallbackStateRet")
    } else {
    }
  }
  const bankIdData = sessionStorage.BankIdData
  if (bankIdData) {
    biData = JSON.parse(bankIdData)
    sessionStorage.removeItem("BankIdData")
  }
  const { vm, state, setState } = useConnect(
    "OrderSimcard",
    {
      show: false,
      redirect: null,
      toUrl: null,
      OrderSent: false,
      OrderError: "",
      buzy: true,
      ErrorField: "",
      ErrorText: "",
      EnterPassCodeDialogOpen: false,
      PassCodeText: "",
      PassCode: "",
      anchorEl: null,
      OrderButtonText: t("selfcare:button.order"),
      SelectedSimType: "",
      SimType: ""
    },
    {
      headers: {
        Authorization: "Bearer " + auth.getAccessToken(),
        ClientVersion: Version.getVersion(),
        ServerToken: window.sessionStorage.ServerToken
      },
      vmArg: {
        SimType: simType,
        SubscriptionNo: props.SubscriptionNo,
        HostUrl: window.location.host,
        Path: window.location.pathname,
        BankIdData: biData,
        CallbackState: cbState
      },
      exceptionHandler: _ => auth.signOut()
    }
  )
  useEffect(() => {
    if (state.Address) {
      setState({ buzy: false })
    }
  }, [state.Address])

  useEffect(() => {
    if (state.SelectedSimType && state.SimType === "main") {
      if (state.IsNorway && state.SelectedSimType === "eSim") {
        setState({ OrderButtonText: "Godkjenn med BankID" })
      } else {
        setState({ OrderButtonText: t("selfcare:button.order") })
      }
    }
  }, [state.SelectedSimType])

  useEffect(() => {
    if (
      state.BankIdUrl &&
      state.BankIdUrl !== "" &&
      state.BankIdUrl !== undefined &&
      state.BankIdUrl !== null
    ) {
      window.location.replace(state.BankIdUrl)
    }
  }, [state.BankIdUrl])

  const handleEnterPassCodeDialogClose = () => {
    setState({ EnterPassCodeDialogOpen: false })
    setState({ buzy: false })
  }

  return (
    <>
      <ServerState
        serverState={state}
        setState={setState}
        handleClose={props.handleClose}
      />
      <div style={{ height: "100vh" }}>
        <Dialog
          open={state.EnterPassCodeDialogOpen}
          onClose={(event, reason) => {
            if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
              handleEnterPassCodeDialogClose()
            }
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <div style={{ display: "flex" }}>
              <Icon name="edit" size={36} color={theme.palette.primary.main} />
              <span style={{ marginLeft: "20px", fontWeight: "700" }}>
                Tast inn kode fra SMS
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            {state.PassCodeText}
            <br />
            <br />
            <TextField
              fullWidth
              autoFocus
              localState={state}
              name="PassCode"
              setLocalState={setState}
              id="PassCode"
              label={t("selfcare:label.code")}
              type="text"
              margin="normal"
              afterChange={value => {
                setState({ PassCodeErrorText: "" })
              }}
            />
            {state.PassCodeErrorText && (
              <div
                style={{
                  width: "100%",
                  padding: "5px",
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.common.white,
                  fontSize: "12px"
                }}
              >
                {state.PassCodeErrorText}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <LargeButton
              color="primary"
              onClick={() => {
                vm.$dispatch({
                  SetPassCode: {
                    PassCode: state.PassCode,
                    SimType: state.SelectedSimType
                  }
                })
              }}
            >
              {t("selfcare:button.send-order-uc")}
            </LargeButton>
            <LargeButton
              variant="text"
              color="secondary"
              onClick={handleEnterPassCodeDialogClose}
            >
              {t("selfcare:button.cancel-uc")}
            </LargeButton>
          </DialogActions>
        </Dialog>
        {state.IsAdministrating && (
          <div
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "5px",
              textAlign: "center"
            }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t("selfcare:info.now-administrating-number", {
                  0: state.AdministratedMsisdn
                })
              }}
            />
          </div>
        )}
        <div
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            padding: "20px"
          }}
          className="white-links"
        >
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10}>
              <Icon
                name="simcard"
                size={48}
                color={theme.palette.common.white}
              />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  maxWidth: "900px"
                }}
              >
                {state.PricePlanType === "postpaid"
                  ? t("selfcare:item.didlosesim")
                  : t("selfcare:item.didlosesim-prepaid")}
              </div>
              <div style={{ fontSize: "14px" }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      state.PricePlanType === "postpaid"
                        ? t("selfcare:info.simorder", {
                            0: state.SimPrice,
                            1: state.eSimPrice
                          })
                        : t("selfcare:info.simorder-prepaid", {
                            0: state.SimPrice,
                            1: state.eSimPrice
                          })
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10}>
              {!state.OrderOpen && (
                <div>
                  {(state.IsSweden || state.PricePlanType === "prepaid") && (
                    <React.Fragment>
                      <div style={{ fontSize: "16px", paddingTop: "20px" }}>
                        {state.Title}
                      </div>
                      <div
                        style={{
                          paddingTop: "20px",
                          paddingLeft: "10px",
                          fontWeight: "700"
                        }}
                      >
                        {t("selfcare:item.simsentto")}:
                      </div>
                      <div
                        style={{ paddingLeft: "10px" }}
                        dangerouslySetInnerHTML={{ __html: state.Address }}
                      />
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <div style={{ paddingTop: "20px" }}>
                          <LargeButton
                            color="primary"
                            onClick={() => {
                              setState({ buzy: true })
                              vm.$dispatch({
                                PerformChange: { SimType: "Standard" }
                              })
                            }}
                            disabled={state.buzy}
                            style={{ marginRight: "10px" }}
                          >
                            {t("selfcare:button.order")}
                          </LargeButton>
                          <LargeButton
                            id="cancel"
                            color="secondary"
                            variant="text"
                            disabled={state.buzy}
                            onClick={() => {
                              props.handleRefresh()
                              props.handleClose()
                            }}
                          >
                            {t("selfcare:button.cancel")}
                          </LargeButton>
                        </div>
                      </Grid>
                    </React.Fragment>
                  )}
                  {state.IsNorway && state.PricePlanType === "postpaid" && (
                    <div style={{ padding: "20px" }}>
                      <div style={{ fontSize: "16px", marginBottom: "17px" }}>
                        {state.Title}
                      </div>
                      <div style={{ marginBottom: "5px" }}>
                        <b>Velg SIM-type:</b>
                      </div>
                      <FormControl
                        error={state.ErrorField === "SelectedSimType"}
                      >
                        <FormGroup>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FormControlLabel
                              control={
                                <Radio
                                  color="primary"
                                  checked={state.SelectedSimType === "Standard"}
                                  onChange={() => {
                                    setState({
                                      SelectedSimType: "Standard",
                                      SendSimCard: true
                                    })
                                  }}
                                  value="Standard"
                                  disabled={
                                    state.DisableButtons ||
                                    !state.CanChangeServices
                                  }
                                />
                              }
                              label={
                                t("selfcare:label.physical-sim") +
                                " - " +
                                state.SimPrice
                              }
                              labelPlacement="end"
                            />
                            <Popover heading="Fysisk SIM-kort">
                              {t("selfcare:info.after-order-sim-is-sent")}
                              <b>{state.Address}</b>
                            </Popover>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FormControlLabel
                              control={
                                <Radio
                                  color="primary"
                                  checked={state.SelectedSimType === "eSim"}
                                  onChange={() => {
                                    setState({
                                      SelectedSimType: "eSim",
                                      SendSimCard: true
                                    })
                                  }}
                                  value="eSim"
                                  disabled={
                                    state.DisableButtons ||
                                    !state.CanChangeServices
                                  }
                                />
                              }
                              label={
                                t("selfcare:label.esim") +
                                " - " +
                                state.eSimPrice
                              }
                              labelPlacement="end"
                            />
                            <Popover heading="eSIM">
                              {t("selfcare:info.esim")}
                              <b>{"\n" + state.SimEmail}</b>
                            </Popover>
                          </div>
                        </FormGroup>
                        <FormHelperText>
                          {state.ErrorField === "SelectedSimType" &&
                            state.ErrorText}
                        </FormHelperText>
                      </FormControl>
                      {state.SelectedSimType === "Standard" && (
                        <Grid item>
                          <p>
                            <b>{t("selfcare:item.simsentto")}:</b>
                          </p>
                          <span
                            dangerouslySetInnerHTML={{ __html: state.Address }}
                          />
                        </Grid>
                      )}
                      {state.SelectedSimType === "eSim" && (
                        <Grid item>
                          <p>
                            <b>{t("selfcare:info.qr-esim-sent")}:</b>
                          </p>
                          <span
                            dangerouslySetInnerHTML={{ __html: state.SimEmail }}
                          />
                        </Grid>
                      )}
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <div style={{ paddingTop: "20px" }}>
                          <LargeButton
                            color="primary"
                            onClick={() => {
                              setState({ buzy: true })
                              vm.$dispatch({
                                PerformChange: {
                                  SimType: state.SelectedSimType,
                                  PassCode: state.PassCode
                                }
                              })
                            }}
                            disabled={state.buzy}
                            style={{ marginRight: "10px" }}
                          >
                            {state.OrderButtonText}
                          </LargeButton>
                          <LargeButton
                            id="cancel"
                            color="secondary"
                            variant="text"
                            disabled={state.buzy}
                            onClick={() => {
                              props.handleRefresh()
                              props.handleClose()
                            }}
                          >
                            {t("selfcare:button.cancel")}
                          </LargeButton>
                        </div>
                      </Grid>
                    </div>
                  )}
                </div>
              )}
              {state.OrderOpen && (
                <Grid
                  container
                  direction="column"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <div style={{ paddingTop: "20px", paddingLeft: "10px" }}>
                    {state.OrderStatus}
                    {state.CanActivate && (
                      <SmallButton
                        onClick={() => {
                          setState({ buzy: true })
                          props.handleChangeDialog("activateSimcard")
                        }}
                        disabled={state.buzy}
                        style={{ marginLeft: "10px" }}
                      >
                        {t("selfcare:button.toactivation")}
                      </SmallButton>
                    )}
                  </div>
                  <div style={{ paddingTop: "30px" }}>
                    <LargeButton
                      id="back"
                      disabled={state.buzy}
                      onClick={() => {
                        props.handleRefresh()
                        props.handleClose()
                      }}
                    >
                      <div style={{ marginRight: "5px", marginTop: "-5px" }}>
                        <Icon name="back" size={24} color="#FFFFFF" />
                      </div>
                      {t("selfcare:button.back")}
                    </LargeButton>
                  </div>
                </Grid>
              )}
              {state.buzy && (
                <Grid
                  container
                  direction="column"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <ReactLoading
                    type={"bubbles"}
                    color={theme.palette.primary.main}
                    height={"50px"}
                    width={"50px"}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}
