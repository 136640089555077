import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Icon from "../../components/Selfcare/Common/Icon";
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';

const CustomDialog = withStyles({
    root: {
        '& .MuiDialog-paperFullScreen': {
            maxWidth: '350px',
            height: '300px',
            overflowY: 'hidden',
            backgroundColor: 'black',
            color: 'white',
            position: 'absolute',
            bottom: 0,
        },
    },
})(Dialog);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={1000} ref={ref} {...props} />;
});

export default function ShareDialog(props) {
    const theme = useTheme();
    return (
        <CustomDialog fullScreen open={props.dialogOpen} onClose={props.handleClose} TransitionComponent={Transition}>
            <Toolbar variant="dense">
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        Del vervelenke på
                    </div>
                    <IconButton
                        color="inherit"
                        onClick={props.handleClose}
                        aria-label='Lukk'
                        edge="end"
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Toolbar>
            <DialogContent>
                <Grid container direction="row" justifyContent="flex-start" spacing={6} alignItems="center">
                    <Grid item>
                        <Button style={{ width: '60px', height: '60px', borderRadius: '20px', backgroundColor: 'blue' }} href={`mailto:?subject=${props.title}&body=${props.emailMessage}%0D%0A${props.url}%0D%0A%0D%0A`} onClick={() => { props.handleClose(); }}>
                            <Icon name="share-email" size={48} color="#fff" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button style={{ width: '60px', height: '60px', borderRadius: '20px', backgroundColor: '#34cb34' }} onClick={() => { props.openSMS(); props.handleClose(); } }>
                            <Icon name="share-sms" size={48} color="#fff" />
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" fullWidth style={{ color: 'white', backgroundColor: '#FFFFFF2E' }} onClick={async () => { await navigator.clipboard.writeText(props.url); props.handleClose(); }}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item edge="start">
                            Kopier vervelenke
                        </Grid>
                        <Grid item edge="end" style={{ marginBottom: '-7px' }}>
                            <Icon name="copy" size={24} color="#FFFFFF" />
                        </Grid>
                    </Grid>
                </Button>
            </DialogActions>
        </CustomDialog >
    );
}
