import React, { useState, useEffect } from "react";
import auth from "../auth";
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import { LargeButton } from '../controls/components/Buttons';
import Icon from "../components/Selfcare/Common/Icon";
import ShareDialog from '../controls/components/ShareDialog';
import ChiliKompisImg from '../assets/img/ChiliKompis.jpg';
import RightArrow from '../assets/img/svg/right-arrow.svg';
import DoneIcon from '@mui/icons-material/Done';
import { TextField } from '@mui/material';
import TextMaskMsisdn from '../components/Selfcare/TextMasks/TextMaskMsisdn';
import { Button, Link } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import * as rdd from 'react-device-detect';

const CustomDialog = withStyles({
    root: {
        '& .MuiDialog-paperFullScreen': {
            maxWidth: '350px',
            height: '500px',
            overflowY: 'hidden',
            backgroundColor: 'black',
            color: 'white',
            position: 'absolute',
            bottom: 0,
        },
    },
})(Dialog);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={1000} ref={ref} {...props} />;
});

const SMSDialog = (props) => {
    const theme = useTheme();
    return <>
        <CustomDialog fullScreen open={props.dialogOpen} onClose={props.handleClose} TransitionComponent={Transition}>
            <Toolbar variant="dense">
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        Send tekstmelding
                    </div>
                    <IconButton
                        color="inherit"
                        onClick={props.handleClose}
                        aria-label='Lukk'
                        edge="end"
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </Grid>                </Toolbar>
            <DialogContent>
                <Grid container direction='column' justifyContent="center" alignItems="flex-start" style={{ fontFamily: theme.typography.fontFamilyRoboto, overflowY: 'scroll' }}>
                    <Grid item style={{ color: '#8c8c8c' }}>
                        <label>Avsender: {props.sender}</label>
                    </Grid>
                    <Grid item >
                        <label style={{ color: '#8c8c8c', marginRight: '10px' }}>Til:</label><TextField value={props.state.to} onChange={(e) => { props.setState({ to: e.target.value }); }} variant="outlined" inputProps={{ style: { backgroundColor: '#000', color: '#fff', margin: "-2px 0px 0px 0px", fontFamily: theme.typography.fontFamilyRoboto, fontSize: '14px' }, ref: (node) => { if (node) { node.style.setProperty("padding", "2px 0px 0px 2px", "important"); } } }} InputProps={{ inputComponent: TextMaskMsisdn }} autoFocus maxLength={8} style={{ backgroundColor: '#000', color: '#fff', padding: '0px' }} />
                    </Grid>
                    <Grid item style={{ color: '#8c8c8c', marginTop: '2px' }}>
                        <label>Melding: &nbsp;</label>
                    </Grid>
                    <TextField
                        variant="outlined"
                        multiline
                        spellCheck={false}
                        rows={8}
                        onChange={(e) => { props.setState({ message: e.target.value }); }}
                        defaultValue={props.message}
                        inputProps={{ maxlength: 320, style: { backgroundColor: '#000', color: '#fff', fontFamily: theme.typography.fontFamilyRoboto, fontSize: '14px' }, ref: (node) => { if (node) { node.style.setProperty("padding", "2px 0px 0px 2px", "important"); } } }} style={{ width: '100%', backgroundColor: '#000', color: '#fff', padding: "0px" }}
                    />
                    <Grid item style={{ alignSelf: 'flex-end', paddingTop: '5px' }}>
                        {props.linkData &&
                            <div style={{ width: '200px', cursor: 'pointer' }} onClick={() => { window.open(props.url, '_blank'); }}>
                                <img src={props.linkData.ImageUrl} alt="" style={{ width: '200px', borderRadius: '10px 10px 0px 0px' }} />
                                <div style={{ backgroundColor: '#404040', padding: '5px', marginTop: '-7px' }}>
                                    <div style={{ fontSize: '10px' }}>
                                        {props.linkData.Title}
                                    </div>
                                    <div style={{ textTransform: 'uppercase', fontFamily: theme.typography.fontFamilyRoboto, fontSize: '8px' }}>
                                        {props.linkData.Snippet}
                                    </div>
                                </div>
                            </div>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disabled={props.state.buzy} fullWidth style={{ color: 'white', backgroundColor: '#FFFFFF2E' }} onClick={() => { props.sendSMS(); props.setState({ buzy: true }); }}>                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item edge="start">
                            Send melding
                        </Grid>
                        <Grid item edge="end" style={{ marginBottom: '-7px', marginRight: '-10px' }}>
                            <img src={RightArrow} alt="" style={{ width: '24px' }} />
                        </Grid>
                    </Grid>
                </Button>
            </DialogActions>
        </CustomDialog >
    </>;
}


export default function FriendsNO(props) {
    const { t } = useTranslation();
    const { vm, state, setState } = useConnect("FriendsNO", { buzy: false, shareDialogOpen: false, smsDialogOpen: false, DataLoading: true, to: '', currentPage: 'firstpage', stack: ['firstpage'] }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });
    const theme = useTheme();
    useEffect(() => {
        setTitle('firstpage');
    }, [])
    const navigateTo = (page) => {
        var s = state.stack;
        var currentPage = s[s.length - 1];
        if (currentPage === page)
            return;
        s.push(page);
        setState({ stack: s, currentPage: page });
        setTitle(page);
    }
    const goBack = () => {
        var s = state.stack;
        var page = s.pop();
        page = s[s.length - 1];
        setState({ stack: s, currentPage: page });
        setTitle(page);
    }
    const share = async () => {
        if (window.navigator.canShare) {
            var param = {};
            param.title = state.RecruitTitle;
            param.text = state.RecruitMessage;
            param.url = state.RecruitUrl;
            try {
                await window.navigator.share(param);
            }
            catch (error) {
                console.log(error);
            }
        } else if (typeof Android !== "undefined") {
            //eslint-disable-next-line no-undef
            Android.actionShare(state.RecruitTitle, state.RecruitUrl, state.RecruitMessage);
        } else {
            setState({ message: state.RecruitMessage, buzy: false, shareDialogOpen: true });
        }
    }
    const sendSMS = () => {
        vm.$dispatch({ SendMessage: { to: state.to, body: state.message } });
    }
    const setTitle = (page) => {
        switch (page) {
            case 'firstpage':
                props.setTitle('Verving');
                props.setBackFunction(null);
                break;
            case 'recruit':
                props.setTitle('Verv en kompis');
                props.setBackFunction(goBack);
                break;
            case "friendslist":
                props.setTitle('Chili kompis');
                props.setBackFunction(goBack);
                //console.log(state.PreviewData.Url);
                break;
            default:
        }
    }

    return (
        <>
            <ServerState serverState={state} setState={setState} />
            <ShareDialog dialogOpen={state.shareDialogOpen} handleClose={() => { setState({ shareDialogOpen: false }); }} url={state.RecruitUrl} title={state.RecruitTitle} message={state.RecruitMessage} emailMessage={state.RecruitEmailMessage} openSMS={() => { setState({ smsDialogOpen: true }); }} />
            <SMSDialog dialogOpen={state.smsDialogOpen} handleClose={() => { setState({ smsDialogOpen: false }); }} sendSMS={sendSMS} url={state.RecruitUrl} title={state.RecruitTitle} message={state.RecruitMessage} emailMessage={state.RecruitEmailMessage} sender={state.SMSSenderFormatted} linkData={state.PreviewData} vm={vm} state={state} setState={setState} />            {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
            {state.currentPage === 'firstpage' &&
                <>
                    <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <img src={ChiliKompisImg} style={{ margin: '0px', width: '100%' }} alt="" />
                        <div style={{ textAlign: 'center', padding: '0px 17px', marginTop: '24px' }}>
                            <div style={{ fontSize: '18px', fontWeight: 700, marginBottom: '12px' }}>Verv en kompis</div>
                            <div style={{ fontSize: '16px', marginBottom: '14px' }}>Få 10 kr rabatt hver måned så lenge din kompis er kunde.</div>
                            <div style={{ fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>Begge sparer 10 kr hver måned.</div>
                        </div>
                        <LargeButton style={{ margin: '28px 0px' }} onClick={() => navigateTo('recruit')}>Verv en kompis</LargeButton>
                        {(state.HasEnlistedByMe || state.EnlistedBy) ?
                            <LargeButton variant="outlined" style={{ padding: '12px 4px 12px 12px', borderRadius: '10px' }} onClick={() => navigateTo('friendslist')} disabled={state.DataLoading}>
                                <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Icon name='venner' size={36} style={{ display: 'inline-block' }} />
                                    <div style={{ marginLeft: '10px', textAlign: 'left', color: theme.palette.text.primary, paddingTop: '7px' }}>
                                        <div style={{ fontSize: '14px' }}>Se din rabatt</div>
                                        <div style={{ fontSize: '10px', fontWeight: 200 }}>Se hvem du har vervet og din rabatt</div>
                                    </div>
                                    <div style={{ marginLeft: '40px', paddingTop: '5px' }}>
                                        <Icon name='right-arrow' size={16} color="#BABABA" style={{ display: 'inline-block' }} />
                                    </div>
                                </div>
                            </LargeButton>
                            :
                            <>
                                {state.DataLoading &&
                                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'62px'} width={'62px'} />
                                }
                            </>
                        }
                        <Link color="primary" onClick={() => { props.openUrl(state.TermsUrl); }} style={{ marginTop: '22px', fontSize: '16px', cursor: 'pointer' }}>Se vilkår for verving</Link>

                        <div style={{ maxWidth: '450px', margin: '60px 30px 40px 17px' }}>
                            <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
                                <li>
                                    <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '17px' }}>
                                        <div><DoneIcon style={{ color: theme.palette.primary.main }} /></div>
                                        <div style={{ paddingLeft: '10px' }}>
                                            <div style={{ fontWeight: 700 }}>Inviter en venn til å bli Chili Kompis</div>
                                            <div style={{ fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>Det er enkelt å verve venner i Chilimobil. Bare del lenken med de du ønsker å verve.</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '17px' }}>
                                        <div><DoneIcon style={{ color: theme.palette.primary.main }} /></div>
                                        <div style={{ paddingLeft: '10px' }}>
                                            <div style={{ fontWeight: 700 }}>Når en venn bruker lenken din</div>
                                            <div style={{ fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>Når en venn bruker lenken din for å bestille abonnement, blir dette automatisk registrert både på deg og på din venn.</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <div><DoneIcon style={{ color: theme.palette.primary.main }} /></div>
                                        <div style={{ paddingLeft: '10px' }}>
                                            <div style={{ fontWeight: 700 }}>Spar penger!</div>
                                            <div style={{ fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>Når din venn har blitt Chili Kompis, vil både du og din Chili Kompis få rabatten automatisk trukket fra fakturaen. Og husk: desto flere du verver, desto mer sparer du!</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </>
            }
            {
                state.currentPage === 'recruit' &&
                <>
                    <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div style={{ fontSize: '14px', fontWeight: 500, marginBottom: '10px', marginTop: '50px' }}>Din personlige verve-link</div>
                        <div style={{ padding: '0px 17px', maxWidth: '450px', width: '100%' }}>
                            <TextField InputProps={{ style: { fontSize: '14px', padding: 0, margin: 0 } }} color="primary" readOnly multiline fullWidth value={state.RecruitUrl} />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Button variant="outlined" size="large" color="primary" style={{ borderRadius: '10px', paddingTop: '15px' }} onClick={() => { share(); }}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <div><Icon name="share" size={24} color={theme.palette.primary.main} /></div>
                                    <div style={{ marginLeft: '10px' }}>Del verve-link</div>
                                </Grid>
                            </Button>
                        </div>
                    </div>
                </>
            }
            {
                state.currentPage === 'friendslist' &&
                <>
                    <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div style={{ marginTop: '30px' }}>
                            <Icon name="venner" size={120} />
                        </div>
                        <div style={{ backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', margin: '-8px 17px 0px 17px', maxWidth: '340px', width: '100%', textAlign: 'center' }}>
                            <div style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '13px' }}>
                                Din rabatt per måned
                            </div>
                            <div style={{ fontSize: '71px', fontWeight: 'bold', marginBottom: '-13px' }}>
                                {state.EnlistedDiscount} kr
                            </div>
                        </div>
                        <div style={{ maxWidth: '320px', width: '100%', margin: '32px 17px 0px 17px' }}>
                            <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '4px' }}>
                                Liste over Chilikompiser
                            </div>
                            <div style={{ fontFamily: theme.typography.fontFamily2 }}>
                                {state.HasEnlistedByMe ?
                                    <div>
                                        {state.EnlistedByMe.map((name, index) => {
                                            return (
                                                <div key={index}>- {name}</div>
                                            )

                                        })}
                                    </div>
                                    :
                                    <div>
                                        Du har ikke vervet noen kompiser enda.
                                    </div>
                                }
                            </div>
                            {state.EnlistedBy &&
                                <div style={{ fontStyle: 'italic', fontSize: '12px', fontWeight: '200', marginTop: '16px' }}>
                                    Du er vervet av {state.EnlistedBy} og har derfor rabatt på 10 kr.
                                </div>
                            }
                        </div>
                        <LargeButton style={{ margin: '30px 0px 60px' }} onClick={() => navigateTo('recruit')}>Verv en kompis</LargeButton>
                    </div>
                </>
            }
        </>
    );
}