import React, { useEffect, useRef } from "react";
import auth from "../auth";
import Version from "../version";
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import ServerState from '../controls/ServerState';
import SparkleDialog from '../controls/components/SparkleDialog';
import Icon from '../components/Selfcare/Common/Icon';
import { Grid } from '@mui/material';
import { Checkbox, FormControlLabel } from '@mui/material';
import { LargeButton } from '../controls/components/Buttons';
import { TextField } from '../controls/TextField';

export default function SetNewPassword(props) {
    const { t } = useTranslation();
    const dialogRef = useRef();
    const theme = useTheme();

    const { vm, state, setState } = useConnect("SetNewPassword", {
        buzy: false,
        show: false,
        Username: '',
        Password: '',
        PasswordRepeat: '',
        Email: '',
        AcceptSendPasswordToEmail: false,
    }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        exceptionHandler: _ => auth.signOut()
    });

    useEffect(() => {
        setTimeout(
            function () {
                setState({ show: true });
                var el = document.getElementById("usernamebox");
                el.style.display = "none";
                el = document.getElementById("Password");
                el.type = "password";
                el = document.getElementById("PasswordRepeat");
                el.type = "password";
            },
            100
        );
    }, [])
    useEffect(() => {
        if (state.UnAuthorized) {
            auth.signOut();
        }
    }, [state.UnAuthorized])

    useEffect(() => {
        if (state.EncryptedPassword) {
            if (hasLocalStorage() && localStorage.chRememberMe) {
                localStorage.chPassword = state.EncryptedPassword;
            }
        }
    }, [state.EncryptedPassword])

    const hasLocalStorage = () => {
        try {
            window.localStorage.setItem("__test", "data");
            window.localStorage.removeItem("__test");
        } catch (e) {
            return false;
        }
        return true;
    };

    const handleChange = name => event => {
        vm.$dispatch({ [name]: event.target.checked, ClearErrors: true });
    };


    return (
        <>
            <ServerState serverState={state} setState={setState} />
            <SparkleDialog ref={dialogRef} />
            <Grid container justifyContent="center">
                <div style={{ height: '100vh', maxWidth: '1024px', width: '100%' }}>
                    <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12}>
                                <Icon name="lock" size={32} color="#FFFFFF" />
                                <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                    {t('selfcare:item.choose-personal-password')}.
                                </div>
                                <div style={{ fontSize: '12px', paddingTop: '20px' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.personal-password') }} /></div>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ padding: '20px' }}>
                        <form autoComplete="off" onSubmit={(e) => { e.preventDefault(); setState({ buzy: true }); vm.$dispatch({ Password: state.Password, PasswordRepeat: state.PasswordRepeat, Email: state.Email, AcceptSendPasswordToEmail: state.AcceptSendPasswordToEmail }); vm.$dispatch({ ChangePassword: false }); }}>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item xs={12} >
                                    <b>{t('selfcare:title.select-a-new-password')}</b>
                                    <Grid container direction="row">
                                        <Grid item xs={12} sm={6} style={{ padding: '20px 10px 0px 0px' }}>
                                            <div id="usernamebox">
                                                <TextField fullWidth
                                                    localState={state}
                                                    name="Username"
                                                    setLocalState={setState}
                                                    id="Username"
                                                    label='Mobilnummer'
                                                    type="tel"
                                                    noPadding
                                                    disabled={state.buzy}
                                                />
                                            </div>
                                            <TextField fullWidth
                                                autoFocus
                                                localState={state}
                                                name="Password"
                                                setLocalState={setState}
                                                id="Password"
                                                label={t('selfcare:label.enter-new-password')}
                                                type="text"
                                                noPadding
                                                disabled={state.buzy}
                                                autoComplete="new-password"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ padding: '20px 10px 0px 0px' }}>
                                            <TextField fullWidth
                                                localState={state}
                                                name="PasswordRepeat"
                                                setLocalState={setState}
                                                id="PasswordRepeat"
                                                label={t('selfcare:label.repeat-new-password')}
                                                type="text"
                                                noPadding
                                                disabled={state.buzy}
                                            />
                                        </Grid>
                                        {state.ShowEmail &&
                                            <Grid item xs={12} style={{ paddingTop: '20px' }}>
                                                <TextField fullWidth
                                                    localState={state}
                                                    name="Email"
                                                    setLocalState={setState}
                                                    id="Email"
                                                    label={t('selfcare:label.enter-email')}
                                                    type="email"
                                                    noPadding
                                                    disabled={state.buzy}
                                                />

                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color="secondary"
                                                            checked={state.AcceptSendPasswordToEmail}
                                                            onChange={handleChange('AcceptSendPasswordToEmail')}
                                                            value="AcceptSendPasswordToEmail"
                                                            disabled={state.buzy}
                                                        />
                                                    }
                                                    label={t('selfcare:label.email-can-receive-password')}
                                                />
                                            </Grid>}
                                    </Grid>
                                    <div style={{ paddingTop: '20px' }}>
                                        <LargeButton disabled={state.buzy} type="submit" >{t('selfcare:button.change-password')}</LargeButton>
                                        {state.ShowEmail &&
                                            <LargeButton disabled={state.buzy} variant="outlined" onClick={(e) => { setState({ buzy: true }); e.preventDefault(); vm.$dispatch({ Password: state.Password, PasswordRepeat: state.PasswordRepeat, Email: state.Email }); vm.$dispatch({ ChangePassword: true }); }} style={{ marginLeft: '10px' }}>{t('selfcare:button.proceed-without-email')}</LargeButton>}
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Grid>
        </>
    );
}