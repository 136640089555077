import React, { useState, useRef, useEffect } from "react";
import auth from "../../auth";
import globalState from '../../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import Version from "../../version";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ServerState from '../ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import MasonryLayout from '../MasonryLayout';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { Grid, Divider } from '@mui/material';
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Icon from "../../components/Selfcare/Common/Icon";
import TopContainer from '../TopContainer';
import { Box, BoxTitle, BoxContent } from '../Box';
import { ActionBox } from '../ActionBox';
import * as rdd from 'react-device-detect';
import { LargeButton, SmallButton } from './Buttons';
import { TextField } from '../TextField';
import SparkleDialog from './SparkleDialog';
import ChiliNoLogo from '../../assets/img/svg/ChiliNoLogoSmall.svg';
import Collapse from '@mui/material/Collapse';
import SIMCardImg from '../../assets/img/SIM-kort.png';
import eSIMImg from '../../assets/img/eSIM.png';
import LeftChevronIcon from '../../assets/img/svg/left-chevron.svg';


const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 12,
        borderRadius: 8,
    },
    colorPrimary: {
        backgroundColor: '#FFF',
    },
    bar: {
        borderRadius: 8,
        background: 'transparent linear-gradient(90deg, #FC3C65 0%, #FD703C 100%) 0% 0% no-repeat padding-box',
        height: 12,
    },
}))(LinearProgress);

export default function HomeOrder() {
    const { t } = useTranslation();
    var biData, cbState;
    const callbackState = sessionStorage.CallbackStateRet;
    if (callbackState) {
        if (callbackState !== '[object Object]') {
            cbState = JSON.parse(callbackState);
            sessionStorage.removeItem('CallbackStateRet');
        }
        else {

        }
    }
    const bankIdData = sessionStorage.BankIdData;
    if (bankIdData) {
        biData = JSON.parse(bankIdData);
        sessionStorage.removeItem('BankIdData');
    }
    const { vm, state, setState } = useConnect("HomeOrder", {
        days: '',
        hours: '',
        minutes: '',
        activationMessage: '',
        portingMessage: 'Vi sender flyttemelding til ditt gamle selskap',
        portingMessageExpanded: false,
        reason: '',
        Name: '',
        NewName: '',
        AddressLine: '',
        PostalCode: '',
        City: '',
        UseNewName: false,
    }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
            BankIdData: biData,
            CallbackState: cbState,
        },
        exceptionHandler: _ => auth.signOut()
    });

    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    const wDim = useWindowDimensions();
    const dialogRef = useRef();

    let timer = null;

    useEffect(() => {
        if (state.ActivationDate) {
            var countDownDate = new Date(state.ActivationDate).getTime();
            const updateTimer = () => {
                var now = new Date().getTime();
                var timeleft = countDownDate - now;
                if (timeleft < 0) {
                    setState({ activationMessage: isNorway ? 'SIM-kort er aktivert!' : 'SIM-kortet är aktiverat!' });
                    clearInterval(timer);
                }
                else {
                    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
                    var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
                    setState({ days, hours, minutes });
                }
            }
            updateTimer();
            timer = setInterval(updateTimer, 10000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [state.ActivationDate]);
    useEffect(() => {
        if (state.BankIdUrl !== undefined && state.BankIdUrl !== null && state.BankIdUrl !== '') {
            window.location.href = state.BankIdUrl;
        }
    }, [state.BankIdUrl])
    var isNorway = gstate.IsNorway.get();
    var isSweden = !isNorway;
    const handleChange = (event) => {
        setState({ reason: event.target.value });
    };
    const handlePortingClick = () => {
        if (state.portingMessageExpanded) {
            setState({ portingMessageExpanded: false, portingMessage: 'Vi sender flyttemelding til ditt gamle selskap' });
        } else {
            setState({ portingMessageExpanded: true, portingMessage: 'Vi sender flyttemelding til ditt gamle selskap for deg slik at du slipper å si fra selv. På tidspunktet over begynner ditt nye SIM-kort fra oss å fungere, samtidig slutter ditt gamle SIM-kort å fungere.' });
        }
    }
    const handleRefresh = () => {
        vm.$dispatch({ Refresh: true });
    }
    const handleNewName = () => {
        setState({ UseNewName: true });
        setTimeout(
            function () {
                var elem = document.getElementById("NewName");
                if (elem) {
                    elem.focus();
                }
            },
            50
        );
    }
    const handleWrongAddress = () => {
        setState({ Step: 2, reason: 'wrong' });
        setTimeout(
            function () {
                var elem = document.getElementById("AddressLine");
                if (elem) {
                    elem.focus();
                }
            },
            50
        );
    }
    return (
        <>
            <ServerState serverState={state} setState={setState} />
            <SparkleDialog ref={dialogRef} handleRefresh={handleRefresh} subscriptionNo={state.SubscriptionNo} />
            {state.Ready &&
                <div style={{ width: '100%', overflowY: rdd.isFirefox ? 'auto' : wDim.desktop ? 'overlay' : 'scroll', height: wDim.height, WebkitOverflowScrolling: 'touch', scrollbarWidth: rdd.isFirefox ? 'thin' : null }}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
                        <div style={{ backgroundColor: state.HasError ? "#FFEFD1" : theme.palette.boxBackground.main, width: '100%', maxWidth: '600px', padding: '10px' }}>
                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <div style={{ width: '108px' }}></div>
                                    <div style={{ width: wDim.desktop ? '65px' : '54px', paddingTop: '27px', paddingBottom: '15px' }}><img src={ChiliNoLogo} height={wDim.desktop ? "41" : "32"} alt="" /></div>
                                    <LargeButton onClick={() => { sessionStorage.removeItem("FromOrder"); auth.signOutManually(); }}>{isNorway ? 'Logg ut' : 'Logga ut'}</LargeButton>
                                </Grid>
                                <Grid container direction="column" style={{ maxWidth: '400px', borderRadius: '10px', backgroundColor: "#FFF", padding: '17px 17px 12px 17px', marginBottom: '19px', fontSize: '12px' }}>
                                    <Grid container direction="row" justifyContent="space-between">
                                        <div>{state.Msisdn}</div>
                                        <div>{state.PackageName}</div>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="space-between" style={{ fontWeight: 200 }}>
                                        <div>{state.Username}</div>
                                        <div>{state.PackagePrice}</div>
                                    </Grid>
                                </Grid>
                                {state.HasError ?
                                    <>
                                        <div style={{ maxWidth: '400px' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <div style={{ fontSize: '16px', fontWeight: 700 }}>{state.ErrorTitle}</div>
                                            </div>
                                            <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', padding: '17px 55px 17px 55px', textAlign: 'left' }}>
                                                <div dangerouslySetInnerHTML={{ __html: state.ErrorTextLocal }} />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {!state.activationMessage ?
                                            <>
                                                <div style={{ fontSize: '12px', fontWeight: 200 }}>{isNorway ? <><span>SIM-kortet for </span><span style={{ fontWeight: 600 }}>{state.Msisdn}</span><span> aktiveres om:</span></> : <><span>SIM-kort för </span><span style={{ fontWeight: 600 }}>{state.Msisdn}</span><span> aktiveras om:</span></>}</div>
                                                <Grid container direction="row" justifyContent="space-around" style={{ maxWidth: '150px' }}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <div style={{ fontSize: '24px', fontWeight: 600 }}>{state.days}</div>
                                                        <div style={{ fontSize: '10px', fontWeight: 200 }}>{isNorway ? 'Dager' : 'Dagar'}</div>
                                                    </div>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <div style={{ fontSize: '24px', fontWeight: 600 }}>{state.hours}</div>
                                                        <div style={{ fontSize: '10px', fontWeight: 200 }}>{isNorway ? 'Timer' : 'Timmar'}</div>
                                                    </div>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <div style={{ fontSize: '24px', fontWeight: 600 }}>{state.minutes}</div>
                                                        <div style={{ fontSize: '10px', fontWeight: 200 }}>{isNorway ? 'Minutter' : 'Minuter'}</div>
                                                    </div>
                                                </Grid>
                                            </>
                                            :
                                            <>
                                                <div style={{ fontSize: '20px', fontWeight: 600 }}>
                                                    {state.activationMessage}
                                                </div>
                                            </>}
                                        <div style={{ backgroundColor: '#FFF', padding: '2px', borderRadius: '8px', width: '240px', marginTop: '5px' }}>
                                            <BorderLinearProgress variant={state.ActivationDate ? 'determinate' : 'indeterminate'} value={state.DaysPercentage} />
                                        </div>
                                        <div style={{ fontSize: '10px', fontWeight: '200', marginTop: '10px' }}>{isNorway ? 'SIM-kortet aktiveres ' : 'SIM-kortet aktiveras '}<span style={{ fontWeight: 600 }}>{state.PortingTimeDisplay}</span></div>
                                        <SmallButton disabled={!state.CanChangeOrder} style={{ marginTop: '14px' }} onClick={() => dialogRef.current.showDialog('changePortingDate')}>{isNorway ? 'Endre aktiveringsdato' : 'Ändra aktiveringsdatum'}</SmallButton>
                                        {!state.HasESim && state.ShowNotReceivedSIM &&
                                            <SmallButton style={{ marginTop: '14px' }} onClick={() => setState({ Step: 2, reason: '' })}>{isNorway ? 'Ikke mottatt SIM-kort' : 'Ej mottagit SIM-kort'}</SmallButton>}
                                        {!state.IsAdministrating && <SmallButton disabled={!state.CanChangeOrder} style={{ marginTop: '14px' }} onClick={() => { vm.$dispatch({ AdminNumber: state.SubscriptionNo }); }}>{isNorway ? 'Endre eller legg til tjenester' : 'Ändra eller lägg till tjänster'}</SmallButton>}
                                        <div style={{ marginBottom: '14px' }}></div>
                                    </>
                                }
                            </Grid>
                        </div>
                        {state.Step > 0 &&
                            <>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ maxWidth: '400px', marginTop: '37px' }}>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ width: '86px', cursor: 'pointer' }} onClick={() => { vm.$dispatch({ GoBack: true }); setState({ UseNewName: false }); }}>
                                        <img src={LeftChevronIcon} height="32" alt="" style={{ marginTop: '-5px', marginRight: '-6px' }} />
                                        <div style={{ marginLeft: '6px' }}>{isNorway ? 'Tilbake' : 'Tillbaka'}</div>
                                    </Grid>
                                    <div>
                                        {state.Step === 2 ?
                                            <div style={{ fontSize: '20px', fontWeight: 700 }}>{isNorway ? 'Ikke mottatt SIM-kort' : 'Ej mottagit SIM-kort'}</div>
                                            :
                                            state.IsAdministrating &&
                                            <div style={{ fontSize: '20px', fontWeight: 700 }}>Administrerer</div>
                                        }

                                    </div>
                                    <div style={{ width: '77px' }}></div>
                                </Grid>
                                <Grid container direction="row" justifyContent="space-around">
                                    {state.Step === 2 ?
                                        <div>Tlf: {state.Msisdn}</div>
                                        :
                                        state.IsAdministrating &&
                                        <div>{state.AdministratedMsisdn}</div>
                                    }
                                </Grid>
                            </>
                        }
                        {state.Step === 1 &&
                            <>
                                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" style={{ maxWidth: '400px', marginTop: '20px', padding: '0px 7px' }} >
                                    {state.CanChangePackage &&
                                        <ActionBox
                                            title={isSweden ? t('selfcare:item.change-subscription-type-swe') : 'Endre abonnement'}
                                            subTitle={state.PackageName}
                                            icon="money"
                                            buttonText={isNorway ? "Endre" : "Byt"}
                                            action={() => { sessionStorage.FromOrder = true; dialogRef.current.showDialog('changePackage'); }}
                                        />
                                    }
                                    <ActionBox
                                        title={t('selfcare:item.pinslashpuk')}
                                        subTitle={t('selfcare:item.showpinandpuk')}
                                        icon="pin"
                                        buttonText={isNorway ? "Vis" : "Visa"}
                                        action={() => { sessionStorage.FromOrder = true; dialogRef.current.showDialog('viewPinPuk'); }}
                                    />

                                    {state.ActiveServices && state.ActiveServices.length > 0 ? state.ActiveServices.map((service) => {
                                        return (
                                            <ActionBox key={service.ServiceNo}
                                                title={service.Name}
                                                subTitle={service.Status}
                                                icon={service.Image}
                                                buttonText={isNorway ? "Administrer" : "Hantera"}
                                                action={() => { sessionStorage.FromOrder = true; sessionStorage.ServiceNo = service.ServiceNo; sessionStorage.GroupId = service.GroupId; dialogRef.current.showDialog('changeService'); }}
                                            />
                                        );
                                    }) : null}

                                    {state.OtherServices && state.OtherServices.length > 0 ? state.OtherServices.map((service) => {
                                        return (
                                            <ActionBox key={service.ServiceNo}
                                                title={service.Name}
                                                subTitle={service.Status}
                                                icon={service.Image}
                                                buttonText={gstate.IsNorway.get() ? "Administrer" : "Hantera"}
                                                action={() => { sessionStorage.FromOrder = true; sessionStorage.ServiceNo = service.ServiceNo; sessionStorage.GroupId = service.GroupId; dialogRef.current.showDialog('changeService'); }}
                                            />
                                        );
                                    }) : null}
                                </Grid>
                            </>
                        }
                        {state.Step === 0 &&
                            <>
                                <Grid container direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '20px' }}>
                                    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                                        <div style={{ marginRight: '5px', marginTop: '8px', fontSize: '16px' }}>
                                            🤝
                                        </div>
                                        <div>
                                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                                <Collapse in={state.portingMessageExpanded} collapsedSize={40} style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', fontWeight: 200, width: '250px', cursor: 'pointer' }} onClick={handlePortingClick}>
                                                    {state.portingMessage}
                                                    {!state.portingMessageExpanded && <div>{isNorway ? 'Les mer ' : 'Läs mer '}<span style={{ color: theme.palette.primary.main, fontSize: '14px' }}>{'\u2192'}</span></div>}
                                                </Collapse>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container direction="column" justifyContent="center" alignItems="center" style={{ padding: '17px' }}>
                                    <div style={{ backgroundColor: theme.palette.boxBackground.main, padding: '20px', maxWidth: '400px', width: '100%', borderRadius: '10px' }}>
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                            {state.HasESim ?
                                                <>
                                                    <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', fontWeight: 200 }}>
                                                        <div style={{ fontFamily: theme.typography.fontFamily, fontSize: '12px', fontWeight: 500 }}>
                                                            {isNorway ? 'Ditt eSIM er sendt på mail' : 'Ditt eSIM är skickat på e-post'}
                                                        </div>
                                                        {state.SIMAddressHTML}
                                                        <div>{isNorway ? 'Last ned ditt nye eSIM fra e-posten.' : 'Ladda ner ditt nya eSIM från e-posten.'}</div>
                                                    </div>
                                                    <div style={{ textAlign: 'right' }} >
                                                        <img src={eSIMImg} alt="" width="50%" style={{ marginTop: '-10px', marginBottom: '-10px' }} />
                                                    </div>

                                                </>
                                                :
                                                <>
                                                    <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', fontWeight: 200 }}>
                                                        <div style={{ fontFamily: theme.typography.fontFamily, fontSize: '12px', fontWeight: 500 }}>
                                                            {isNorway ? 'Adresse for mottak av SIM-kort:' : 'SIM-kortet är skickas till:'}
                                                        </div>
                                                        <div style={{ lineHeight: '14px' }} dangerouslySetInnerHTML={{ __html: state.SIMAddressHTML }} />
                                                        {state.SIMSent ?
                                                            <div style={{ fontSize: '10px' }}>
                                                                Sendt {state.SIMSentDate}
                                                            </div>
                                                            :
                                                            <div style={{ fontSize: '10px' }}>
                                                                {isNorway ? 'Ingen simkort tilordnet til bestilling ennå' : 'Inget simkort tilldelat för beställning ännu'}
                                                            </div>}
                                                    </div>
                                                    <div style={{ textAlign: 'right' }} >
                                                        <img src={SIMCardImg} alt="" style={{ marginTop: '20px', width: '84px' }} />
                                                    </div>
                                                </>}
                                        </Grid>
                                        {!state.HasESim &&
                                        <SmallButton disabled={!state.CanChangeOrder} onClick={() => {handleWrongAddress()}}>{isNorway ? 'Send til ny adresse' : 'Skicka till annan adress'}</SmallButton>}
                                    </div>
                                </Grid>
                                <Grid container direction="column" justifyContent="center" alignItems="center">
                                    <div style={{ fontSize: '14px', marginTop: '10px' }}>
                                        {isNorway ? 'Skreddersy abonnementene dine' : 'Skräddarsy dina abonnemang'}
                                    </div>
                                </Grid>
                                <Grid container direction="column" justifyContent="center" alignItems="center" style={{ padding: '10px 17px 17px 17px' }}>
                                    <div style={{ backgroundColor: theme.palette.boxGreyBackground.main, padding: '20px', maxWidth: '310px', width: '100%', borderRadius: '10px' }}>
                                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap' }}>
                                            <div style={{ fontSize: '16px', marginRight: '5px' }}>
                                                🙋‍♂️
                                            </div>
                                            <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', fontWeight: 200 }}>
                                                {isNorway ? 'Du kan allerede nå legge til og endre tjenester du vil ha på abonnementet. Vil du for eksempel legge til sperrer for barna?' : 'Du kan redan nu lägga till och ändra tjänster du vill ha på abonnemanget. Vill du kanske lägga till spärrar på barnen?'}
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                                {state.Subscriptions && state.Subscriptions.length > 0 &&
                                    <Grid container direction="column" justifyContent="center" alignItems="center" style={{ fontSize: '14px', padding: '10px 17px 17px 17px' }}>
                                        {state.Subscriptions?.map((subscription, index) => {
                                            return (
                                                <div key={index} style={{ backgroundColor: theme.palette.boxBackground.main, padding: '20px', maxWidth: '400px', width: '100%', borderRadius: '10px' }}>
                                                    <div>{subscription.Username}</div>
                                                    <div style={{ fontWeight: 200, fontFamily: theme.typography.fontFamily2, marginBottom: '6px' }}>
                                                        {subscription.Msisdn} - {subscription.PackageName} {subscription.PackagePrice}
                                                    </div>
                                                    <SmallButton disabled={!subscription.CanChangeOrder} onClick={() => { vm.$dispatch({ AdminNumber: subscription.SubscriptionNo }); }}>{isNorway ? 'Endre eller legg til tjenester' : 'Ändra eller lägg till tjänster'}</SmallButton>
                                                </div>
                                            )
                                        })}
                                    </Grid>
                                }
                            </>
                        }
                        {state.Step === 2 &&
                            <>
                                <div style={{ maxWidth: '400px', width: '100%', padding: '0px 17px', marginTop: '10px' }}>
                                    <div style={{ backgroundColor: theme.palette.boxBackground.main, padding: '20px', borderRadius: '10px' }}>
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                            <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', fontWeight: 200 }}>

                                                <div style={{ fontFamily: theme.typography.fontFamily, fontSize: '12px', fontWeight: 500 }}>
                                                    {isNorway ? 'Adresse for mottak av SIM-kort:' : 'SIM-kortet är skickas till:'}
                                                </div>
                                                <div style={{ lineHeight: '14px' }} dangerouslySetInnerHTML={{ __html: state.SIMAddressHTML }} />
                                                {state.SIMSent ?
                                                    <div style={{ fontSize: '10px' }}>
                                                        Sendt {state.SIMSentDate}
                                                    </div>
                                                    :
                                                    <div style={{ fontSize: '10px' }}>
                                                        {isNorway ? 'Ingen simkort tilordnet til bestilling ennå' : 'Inget simkort tilldelat för beställning ännu'}
                                                    </div>}
                                            </div>
                                            <div style={{ textAlign: 'right' }} >
                                                <img src={SIMCardImg} alt="" style={{ marginTop: '20px', width: '84px' }} />
                                            </div>
                                        </Grid>
                                    </div>
                                </div>
                                {!state.UseNewName ? <FormControl component="fieldset">
                                    <RadioGroup aria-label="gender" name="gender1" value={state.reason} onChange={handleChange}>
                                        <FormControlLabel value="correct" control={<Radio color="primary" />} label={isNorway ? <span style={{ fontSize: '12px', fontFamily: theme.typography.fontFamily2 }}>SIM-kortet er sendt til <span style={{ color: '#2FA74B' }}>riktig</span> adresse</span> : <span></span>} />
                                        <FormControlLabel value="wrong" control={<Radio color="primary" />} label={isNorway ? <span style={{ fontSize: '12px', fontFamily: theme.typography.fontFamily2 }}>SIM-kortet er sendt til <span style={{ color: '#E3264E' }}>feil</span> adresse</span> : <span></span>} />
                                    </RadioGroup>
                                </FormControl>
                                    :
                                    <div style={{ maxWidth: '400px', width: '100%', padding: '17px', fontFamily: theme.typography.fontFamily2, fontSize: '14px', marginBottom: '-20px' }}>
                                        Når SIM-kortet skal sendes til en person med et annet navn, må det fortsatt godkjennes med BankID av juridisk eier som er: <b>{state.LegalOwner}</b>. Følg stegene under.
                                    </div>
                                }
                                {state.reason === 'wrong' &&
                                    <>
                                        <div style={{ fontWeight: 700, marginTop: '20px' }}>Ny adresse</div>
                                        {!state.UseNewName && <div style={{ width: '100%', maxWidth: '400px', paddingRight: '17px', textAlign: 'right', marginBottom: '-20px' }}>
                                            <SmallButton onClick={() => handleNewName()} >Endre navn</SmallButton>
                                        </div>}
                                        <Grid container direction="column" style={{ maxWidth: '400px' }}>
                                            <TextField
                                                fullWidth
                                                disabled={true}
                                                label="Navn"
                                                id="Name"
                                                name="Name"
                                                maxLength={30}
                                                localState={state}
                                                setLocalState={setState}
                                            />
                                            {state.UseNewName && <TextField
                                                fullWidth
                                                label="Nytt navn for denne utsendelsen"
                                                id="NewName"
                                                name="NewName"
                                                maxLength={30}
                                                localState={state}
                                                setLocalState={setState}
                                            />}
                                            <TextField
                                                fullWidth
                                                disabled={state.buzy}
                                                label="Adresse"
                                                id="AddressLine"
                                                name="AddressLine"
                                                localState={state}
                                                setLocalState={setState}
                                            />
                                            <div style={{ width: '100%' }}>
                                                <div style={{ display: 'inline-block', width: '120px' }}>
                                                    <TextField
                                                        fullWidth
                                                        disabled={state.buzy}
                                                        label="Postnummer"
                                                        id="PostalCode"
                                                        name="PostalCode"
                                                        maxLength={4}
                                                        type="tel"
                                                        localState={state}
                                                        setLocalState={setState}
                                                        afterChange={(value) => { vm.$dispatch({ Lookup: value }); }}
                                                    />
                                                </div>

                                                <div style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        label="Poststed"
                                                        id="City"
                                                        name="City"
                                                        localState={state}
                                                        setLocalState={setState}
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                <LargeButton disabled={state.buzy} style={{ marginBottom: '100px' }} onClick={() => { vm.$dispatch({ ChangeAddress: { Name: state.Name, UseNewName: state.UseNewName, NewName: state.NewName, AddressLine: state.AddressLine, PostalCode: state.PostalCode, City: state.City }}) } }>Godkjenn med BankkID</LargeButton>

                                    </>
                                }
                                {state.reason === 'correct' &&
                                    <>
                                        <div style={{ fontSize: '12px', fontFamily: theme.typography.fontFamily2, maxWidth: '400px', width: '100%', padding: '0px 17px', marginTop: '10px' }}>
                                            Pass på at navnet: {state.Name}, står på din postkasse. Posten vil ikke levere ditt SIM-kort hvis navnet ditt ikke er synlig. Merk at posten bruker mellom 3-6 dager på levere post.
                                        </div>
                                        <LargeButton style={{ marginTop: '20px' }} disabled={!state.CanChangeOrder} onClick={() => { vm.$dispatch({ DelayDelivery: true }); }}>Utsett overføringen med 2 dager</LargeButton>
                                    </>
                                }
                            </>
                        }
                    </Grid >
                </div >}
        </>
    );
}