import { createTheme, adaptV4Theme } from "@mui/material/styles";

var locale = process.env.REACT_APP_DEFAULTLOCALE;
var theme;
if (locale.toLowerCase() === 'nb-no') {
    theme = createTheme({
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        fontSize: '0.875rem',
                        lineHeight: 1.43,
                        letterSpacing: '0.01071em',
                    },
                },
            },
        },
        props: {
            MuiButtonBase: {
                //disableRipple: true
            }
        },
        shape: {
            borderRadius: 0,
        },
        palette: {
            primary: {
                //main: '#FD3C65'
                main: '#E3264E'
            },
            //primary: { main: '#00FF00' },
            secondary: {
                main: '#212529',
            },

            //error: { main: '#FF3D66' },
            error: { main: '#E3264E' },
            pink: '#FF3D66',
            text: { primary: '#212529', secondary: '#212529', error: '#2125296' },
            icon: { primary: '#AA142F', secondary: '#AA142F', inverse: '#FFFFFF' },
            //link: { primary: '#007bff', hover: '#0056b3' },
            link: { primary: '#DA2E63', hover: '#c2153a' },
            border: { main: '#ACACAC' },
            boxBackground: {
                /*main: '#FFF8F8',*/
                main: '#FFEFEF',
            },
            boxGreyBackground: {
                main: '#F6F6F6',
            },
            boxGreyBackground2: {
                main: '#F7F7F7',
            },
            background: {
                default: "#FFF",
                selected: '#ffefef',
            },
            color: {
                default: "#2122529"
            },
            // Used by `getContrastText()` to maximize the contrast between
            // the background and the text.
            contrastThreshold: 3,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.05,
        },
        typography: {
            useNextVariants: true,
            fontFamily2: "'neuzeit-grotesk','sans-serif'",
            fontFamilyHeading: "'itc-avant-garde-gothic-pro', 'sans-serif'",
            fontFamily: "'itc-avant-garde-gothic-pro', 'sans-serif'",
            fontFamilyRoboto: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            subtitle2: {
                fontWeight: 600
            },
            fontSize: 14,
            //No uppercase for buttons
            button: {
                textTransform: 'none'
            },
        },
        overrides: {
            MuiInputBase: {
                multiline: {
                    padding: '0px !important',
                }
            },
            MuiListItem: {
                root: {
                    "&$selected": {
                        backgroundColor: "#da2f6380",
                        "&:hover": {
                            backgroundColor: "#da2f6380",
                        },
                    },
                },
                button: {
                    "&:hover": {
                        backgroundColor: "#E3264E",
                    },
                },
            },
            MuiInput: {
                root: {
                    color: '#212529',
                },
                input: {
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0px 1000px white inset',
                        transition: 'background-color 5000s ease-in-out 0s',
                        '&::first-line': {
                            fontFamily: "'itc-avant-garde-gothic-pro', 'sans-serif'",
                            fontSize: '16px',
                        },
                    },

                    border: '0 !important;',
                    paddingTop: '6px !important;',
                    paddingBottom: '7px !important;',
                },
                underline: {
                    '&:before': {
                        borderBottomColor: '#969696'
                    }
                },
            },
            MuiFormHelperText: {
                root: {
                    marginTop: '0px !important;',
                    marginBottom: '0px !important;',
                    marginLeft: '3px !important',
                    fontSize: '0.9rem !important',
                    fontFamily: "'neuzeit-grotesk','sans-serif'",
                }
            },
            MuiButton: {
                root: {
                },
                contained: {
                    "-webkit-text-fill-color": "#FFF",
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '&:active': {
                        boxShadow: 'none',
                    },
                },
            },
            MuiPaper: {
                root: {
                    width: "100%",
                    borderRadius: "10px"
                }
            }
        }
    });
} else {
    theme = createTheme({
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        fontSize: '0.875rem',
                        lineHeight: 1.43,
                        letterSpacing: '0.01071em',
                    },
                },
            },
        },
        props: {
            MuiButtonBase: {
                //disableRipple: true
            }
        },
        shape: {
            borderRadius: 0,
        },
        palette: {
            primary: {
                //main: '#FD3C65'
                main: '#E3264E'
            },
            //primary: { main: '#00FF00' },
            secondary: {
                main: '#212529',
            },

            //error: { main: '#FF3D66' },
            error: { main: '#E3264E' },
            pink: '#FF3D66',
            text: { primary: '#212529', secondary: '#212529', error: '#2125296' },
            icon: { primary: '#AA142F', secondary: '#AA142F', inverse: '#FFFFFF' },
            //link: { primary: '#007bff', hover: '#0056b3' },
            link: { primary: '#DA2E63', hover: '#c2153a' },
            border: { main: '#ACACAC' },
            boxBackground: {
                /*main: '#FFF8F8',*/
                main: '#F6F6F6',
            },
            boxGreyBackground: {
                main: '#F6F6F6',
            },
            boxGreyBackground2: {
                main: '#F7F7F7',
            },
            background: {
                default: "#FFF"
            },
            color: {
                default: "#2122529"
            },
            // Used by `getContrastText()` to maximize the contrast between
            // the background and the text.
            contrastThreshold: 3,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.05,
        },
        typography: {
            useNextVariants: true,
            fontFamily2: "'neuzeit-grotesk','sans-serif'",
            fontFamilyHeading: "'itc-avant-garde-gothic-pro', 'sans-serif'",
            fontFamily: "'itc-avant-garde-gothic-pro', 'sans-serif'",
            fontFamilyRoboto: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            //Old fonts
            //fontFamilyHeading: "'Patua One', cursive;",
            //fontFamily2: "'Source Sans Pro', 'sans-serif'",
            subtitle2: {
                fontWeight: 600
            },

            //No uppercase for buttons
            button: {
                textTransform: 'none'
            },
        },
        overrides: {
            MuiListItem: {
                root: {
                    "&$selected": {
                        backgroundColor: "#da2f6380",
                        "&:hover": {
                            backgroundColor: "#da2f6380",
                        },
                    },
                },
                button: {
                    "&:hover": {
                        backgroundColor: "#E3264E",
                    },
                },
            },
            MuiInput: {
                root: {
                    color: '#212529',
                },
                input: {
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0px 1000px white inset',
                        transition: 'background-color 5000s ease-in-out 0s',
                        '&::first-line': {
                            fontFamily: "'itc-avant-garde-gothic-pro', 'sans-serif'",
                            fontSize: '16px',
                        },
                    },

                    border: '0 !important;',
                    paddingTop: '6px !important;',
                    paddingBottom: '7px !important;',
                },
                underline: {
                    '&:before': {
                        borderBottomColor: '#969696'
                    }
                },
            },
            MuiFormHelperText: {
                root: {
                    marginTop: '0px !important;',
                    marginBottom: '0px !important;',
                    marginLeft: '3px !important',
                    fontSize: '0.9rem !important',
                    fontFamily: "'neuzeit-grotesk','sans-serif'",
                }
            },
            MuiButton: {
                root: {
                },
                contained: {
                    "-webkit-text-fill-color": "#FFF",
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '&:active': {
                        boxShadow: 'none',
                    },
                },
            },
        }
    });
}
export default theme;
