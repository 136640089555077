import React, { useState, useEffect } from "react";
import produce from 'immer';
import auth from "../auth";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Icon from "../components/Selfcare/Common/Icon";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Divider } from '@mui/material';
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import { TextField } from '../controls/TextField';

export default function PostpaidSuperboost(props) {
    const { t } = useTranslation();
    const { vm, state, setState } = useConnect("PostpaidSuperboost", { EnterPasswordDialogOpen: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });
    useEffect(() => {
        if (state.pInfo) {
            gstate.paymentInfo.set(state.pInfo);
        }
        if (state.GoNets)
            window.location.assign(state.GoNets);
    }, [state.GoNets]);

    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    const handleChange = event => {
        setState({ value: event.target.value, canbuy: true });
    };
    return (
        <>
            <ServerState serverState={state} handleClose={props.handleClose} handleRefresh={props.handleRefresh}/>
            {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
            <div>
                <Dialog
                    open={state.EnterPasswordDialogOpen}
                    onClose={() => { setState({ EnterPasswordDialogOpen: false, buzy: false }); }}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <div style={{ display: 'flex' }}>
                            <Icon name="edit" size={36} color={theme.palette.primary.main} />
                            <span style={{ marginLeft: '20px', fontWeight: '700' }}>{t('selfcare:title.code-set-by-legal-owner')}</span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {state.PasswordText}<br /><br />
                        <TextField fullWidth
                            autoFocus
                            localState={state}
                            name="password"
                            setLocalState={setState.bind(this)}
                            id="password"
                            label={t('selfcare:label.code')}
                            type="text"
                            margin="normal"
                        />
                        {state.PasswordErrorText && <div style={{ width: '100%', padding: '5px', backgroundColor: theme.palette.primary.main, color: 'white', fontSize: '12px' }}>{state.PasswordErrorText}</div>}
                    </DialogContent>
                    <DialogActions>
                        <LargeButton onClick={() => { vm.$dispatch({ SetPassword: state.password }); }}>
                            {t('selfcare:button.proceed-uc')}
                        </LargeButton>
                        <LargeButton variant="text" onClick={() => { setState({ EnterPasswordDialogOpen: false, buzy: false }); props.handleClose(); }} color="secondary">
                            {t('selfcare:button.cancel-uc')}
                        </LargeButton>
                    </DialogActions>
                </Dialog>
                <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            <Icon name="money" size={48} color="#FFFFFF" />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                {state.IsSweden ? <div>Maxa din hastighet upp till 100 Mbit/s</div> : <div>Kjøp Superboost</div>}
                            </div>
                            {state.IsSweden && <div style={{ fontSize: '14px' }}>
                                Här kan du under en period maxa din hastighet på ditt abonnamang. Kanske har du behov under ett dygn, helg eller vecka att ha högsta hastigheten upp till 100 Mbit/s.
                            </div>}
                        </Grid>
                    </Grid>
                </div>
                <form onSubmit={(e) => { e.preventDefault(); vm.$dispatch({ PerformChange: { SelectedProductNo: state.value } }); }}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            <div style={{ paddingTop: '20px', textAlign: 'center' }}>
                                {state.IsSweden ? <div>Välj tidsperiod för högsta hastighet.<br />Efter beställning aktiveras max hastighet inom 5 minuter.</div> : <div>Velg Superboost</div>}
                            </div>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label={t('selfcare:label.datapackets-superboost')}
                                    name="data"
                                    value={state.value}
                                    onChange={handleChange}
                                >
                                    {state.TopUps ? state.TopUps.map((option) => {
                                        return (
                                            <FormControlLabel key={option.ProductNo} value={option.ProductNo} control={<Radio color="primary" />} label={option.Description} style={{ fontWeight: 700 }} />
                                        );
                                    }) : null}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {state.IsSweden && <Grid xs={12} sm={10} item style={{ padding: '20px' }}>
                            Den maxade hastigheten gäller 24, 72 respektive 168 timmar efter beställning.<br/>
                            Beställningen betalas direkt med kort- eller swishbetalning, du skickas vidare till Nets för betalning efter bekräftat köp.
                        </Grid>}
                        <Grid item style={{ paddingTop: '20px' }}>
                            <Grid container direction="column" justifyContent="center">
                                <Grid container justifyContent="center" direction="row">
                                    <LargeButton type="submit" disabled={!state.canbuy || state.OrderOpen} style={{ marginRight: '10px' }} >{t('selfcare:button.confirm-purchase')}</LargeButton>
                                    <LargeButton id='cancel' variant="text" color="secondary" onClick={() => { props.handleClose() }}>{t('selfcare:button.cancel')}</LargeButton>
                                </Grid>
                                {state.Processing && <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />}
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
}