import React, { useEffect } from "react";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import auth from "../auth";
import Version from "../version";
import { useConnect } from 'dotnetify';
import ServerState from '../controls/ServerState';
import { useTheme } from '@mui/material/styles';
import { Grid, Modal, Typography, LinearProgress } from "@mui/material";

export default function AuthCallback() {
    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    const getParameterByName = (name) => {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };

    const { vm, state, setState } = useConnect("Callback", { Buzy: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            Uri: window.location.href,
        },
        exceptionHandler: _ => auth.signOut()
    });
    useEffect(() => {
        var ret = {};
        ret.status = getParameterByName('result') || "";
        ret.sessionId = getParameterByName('sessionId') || "";
        ret.externalReference = getParameterByName('externalReference') || "";
        ret.errorDescription = getParameterByName('error_description') || "";

        var data = JSON.stringify(ret);
        sessionStorage.BankIdData = data;

        const Path = sessionStorage.CallbackPath;
        sessionStorage.removeItem('CallbackPath');

        const State = sessionStorage.CallbackState;
        sessionStorage.CallbackStateRet = State;
        sessionStorage.removeItem("CallbackState");

        const Dialog = sessionStorage.CallbackDialog;
        sessionStorage.CallbackDialogRet = Dialog;
        sessionStorage.removeItem("CallbackDialog");
        window.location.replace(Path);
    }, [])

    return (
        <>
            <ServerState serverState={state} setState={setState} />
            <Modal
                open={state.Buzy}
            >
                <div style={{ width: '300px', top: '50%', left: '50%', transform: `translate(-50%, -50%)`, position: 'absolute', backgroundColor: theme.palette.background.paper, boxShadow: theme.shadows[5], padding: theme.spacing(2), outline: 'none' }}>
                    <Typography id="modal-title">
                        {state.BuzyText}
                    </Typography>
                    {state.BuzySubText}
                    <LinearProgress color="primary" variant="query" />
                </div>
            </Modal>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    
                </Grid>
        </>
    );
}