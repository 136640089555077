import React, { useState, useEffect } from "react";
import produce from 'immer';
import auth from "../auth";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Icon from "../components/Selfcare/Common/Icon";
import { Grid, Divider } from '@mui/material';
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import { TextField } from '../controls/TextField';
import TextMaskAmount from '../components/Selfcare/TextMasks/TextMaskAmount';

export default function ViewPinPuk(props) {
    const { t } = useTranslation();
    const fromOrder = sessionStorage.FromOrder;
    const { vm, state, setState } = useConnect("ViewPinPuk", { SelectSimType: false, Done: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            SubscriptionNo: props.SubscriptionNo,
            FromOrder: fromOrder,
        },
        exceptionHandler: _ => auth.signOut()
    });
    const [localState, internalSetLocalState] = useState({ selectedSimType: '', disabled: false, busy: false });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    const gstate = useGlobalState(globalState);
    const theme = useTheme();

    return (
        <>
            <ServerState serverState={state} localState={localState} setLocalState={setLocalState} />
            {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
            <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10}>
                        <Icon name="pin" size={48} color="#FFFFFF" />
                        <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                            PIN & PUK
                        </div>
                    </Grid>
                </Grid>
            </div>
            {state.Done && state.SelectSimType &&
                <>
                    <form onSubmit={(e) => { e.preventDefault(); setLocalState({ buzy: true }); vm.$dispatch({ SelectType: localState.selectedSimType }); }}>
                        <Grid container direction="column" justifyContent="space-around" alignItems="center" style={{ paddingTop: '17px' }}>
                            <Grid item xs={12} sm={10} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{state.IsNorway ? 'Hvilket SIM-kort vil du se PIN/PUK-koden til?' : 'Vilket SIM-kort vill du se PIN/PUK koderna för?'}</FormLabel>
                                    <RadioGroup aria-label="SIM typ" name="simtype" value={localState.selectedSimType} onChange={(event) => { setLocalState({ selectedSimType: event.target.value }); setLocalState({ SimError: '' }); }}>
                                        {state.SimTypes.map((row, index) => {
                                            return (
                                                <FormControlLabel key={index} value={row.type} control={<Radio color="primary" />} label={row.name} />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                                {localState.SimError &&
                                    <div style={{ color: theme.palette.primary.main }}>{localState.SimError}</div>
                                }
                            </Grid>
                            <Grid item xs={12} sm={10} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <LargeButton type="submit">{state.IsNorway ? 'Vis PIN/PUK' : 'Visa PIN/PUK'}</LargeButton>
                                <LargeButton color="secondary" variant="outlined" style={{ marginLeft: '15px' }} onClick={() => { props.handleClose(); }}>Avbryt</LargeButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    {localState.buzy &&
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px' }} >
                            <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                        </div>
                    }
                </>
            }
            {state.Done && state.Pin &&
                <Grid container direction="column" justifyContent="space-around" alignItems="center" style={{ paddingTop: '17px' }}>
                    {state.Pin === "NA" ?
                        <Grid item xs={12} sm={10} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                            <div>{state.ICC}</div>
                        </Grid>
                        :
                        <Grid item xs={12} sm={10} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                            <div dangerouslySetInnerHTML={{ __html: t('selfcare:dialog.pinpuksim', { statePin: state.Pin, statePuk: state.Puk, stateICC: state.ICC }) }} />
                        </Grid>}
                    <Grid item xs={12} sm={10} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                        <Grid container direction="column" justifyContent="space-around" alignItems="center">
                            <LargeButton color="primary" style={{ marginRight: '14px' }} onClick={() => { props.handleClose(); }}> {t('selfcare:button.done')}</LargeButton>
                        </Grid>
                    </Grid>
                    {localState.buzy &&
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px' }} >
                            <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                        </div>
                    }
                </Grid>
            }
        </>
    );
}