import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useTheme } from '@mui/material/styles';
import YouTube from 'react-youtube';

export const YouTubeVideo = (props) => {
    const wDim = useWindowDimensions();
    const theme = useTheme();

    const opts = {
        height: wDim.phone ? wDim.width * 0.61 : '360',
        width: wDim.phone ? wDim.width : '600',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };
    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    return (
        <YouTube videoId={props.VideoUrl} opts={opts} onReady={_onReady} />
    );
};


