import React from "react";
import PropTypes from 'prop-types';
import svg4everybody from "svg4everybody";
import Icons from "../../assets/img/MenuIcons.svg";

//Initialize svg
svg4everybody();

const MenuIcon = ({ name, color, size }) => (
    <svg className={`icon icon-${name}`} fill={color} width={size} height={size}>
        <use xlinkHref={`${Icons}#icon-${name}`} />
    </svg>
);

MenuIcon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.number
};

export default MenuIcon;