import React from "react";
import auth from "../auth";
import Version from "../version";
import { useConnect } from "dotnetify";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import ServerState from "../controls/ServerState";
import { Grid } from "@mui/material";
import { LargeButton } from '../controls/components/Buttons';
import { TextField } from '../controls/TextField';
import ReactLoading from 'react-loading';
import Icon from "../components/Selfcare/Common/Icon";

export default function ActivateSimcard(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { vm, state, setState } = useConnect("ActivateSimcard", {
        show: false,
        redirect: null,
        toUrl: null,
        Password: '',
        SimNo: '',
        OrderSent: false,
        OrderError: '',
        buzy: false,
        ErrorField: '',
        ErrorText: '',
    },
        {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg:
            {
                SubscriptionNo: props.SubscriptionNo,
            },
            exceptionHandler: _ => auth.signOut()
        });

    return (
        <>
            <ServerState
                serverState={state}
                setState={setState}
                handleClose={props.handleClose}
                handleRefresh={props.handleRefresh}
            />

            <div style={{ height: '100vh' }}>
                <div style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white, padding: '20px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            <Icon name="simcard" size={48} color={theme.palette.common.white} />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                {t('selfcare:item.activate-new-sim')}
                            </div>
                            <div style={{ fontSize: '14px' }}>
                                {t('selfcare:item.ordered-sim-sent')}
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <form onSubmit={(e) => { e.preventDefault(); setState({ buzy: true, ErrorField: '' }); vm.$dispatch({ PerformChange: { Password: state.Password, SimNo: state.SimNo } }); }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            {state.UnblockFirst && <div style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                <p style={{ fontSize: '14px' }}>{t('selfcare:info.to-activate-sim-open-subscription')}</p>
                                <div style={{ maxWidth: '320px' }}>
                                    <TextField fullWidth
                                        autoFocus
                                        noPadding
                                        disabled={state.buzy}
                                        localState={state}
                                        setLocalState={setState}
                                        name="Password"
                                        label={t('selfcare:label.password-submitted-at-block')}
                                        id="Password"
                                        maxLength={30}
                                        margin="normal"
                                    />
                                </div>
                            </div>}
                            {state.CanActivate && <div style={{ paddingTop: '20px', paddingLeft: '10px', paddingRight: '10px' }}>
                                <div style={{ maxWidth: '320px' }}>
                                    <TextField fullWidth
                                        noPadding
                                        autoFocus
                                        disabled={state.buzy}
                                        localState={state}
                                        setLocalState={setState}
                                        label={t('selfcare:label.final-6-digits-sim')}
                                        id="SimNo"
                                        name="SimNo"
                                        type="tel"
                                        maxLength={30}
                                        labelWidth="340px"
                                        helperText={state.ErrorField === 'SimNo' && state.ErrorText}
                                    />
                                </div>
                                <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                    <div style={{ paddingTop: '20px' }}>
                                        <LargeButton type="submit" disabled={state.buzy} style={{ marginRight: '10px' }}>{t('selfcare:button.activate')}</LargeButton>
                                        <LargeButton id='cancel' variant="text" color="secondary" disabled={state.buzy} onClick={() => { props.handleClose(); }}>{t('selfcare:button.cancel')}</LargeButton>
                                    </div>
                                </Grid>
                            </div>}
                            {!state.CanActivate &&
                                <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                    <div>
                                        {t('selfcare:error.no-order-sim-found')}<br />
                                        <LargeButton id='back' disabled={state.buzy} onClick={() => { props.handleClose(); }}><Icon name="back" size={32} color="#FFFFFF" />{t('selfcare:button.back')}</LargeButton>
                                    </div>
                                </Grid>
                            }
                            {state.buzy &&
                                <Grid container direction="column" justifyContent="space-around" alignItems="center">
                                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
}

