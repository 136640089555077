import React, { useRef, useState, useEffect } from "react";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import produce from 'immer';
import auth from "../auth";
import Version from "../version";
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import PageContainer from '../controls/PageContainer';
import { Grid } from "@mui/material";
import { ActionBox } from '../controls/ActionBox';
import ServerState from '../controls/ServerState';
import { Box, BoxTitle, BoxContent } from '../controls/Box';
import { SmallButton } from '../controls/components/Buttons';
import SparkleDialog from '../controls/components/SparkleDialog';


export default function HiddenNumber() {
    const getParameterByName = (name) => {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };
    const { vm, state } = useConnect("HiddenNumber", {}, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: { SubscriptionNo: getParameterByName('subscriptionno') },
        exceptionHandler: _ => auth.signOut()
    });
    const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, showBuyDataButton: false, dialogOpen: false, ViaplayBuzy: false, CancelViaplayDialogOpen: false, ActivateViaplayDialogOpen: false });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    const gstate = useGlobalState(globalState);

    const { t } = useTranslation();
    const theme = useTheme();
    const dialogRef = useRef();
    const handleRefresh = () => {
        vm.$dispatch({ Refresh: true });
    }

    useEffect(() => {
        const callbackDialog = sessionStorage.CallbackDialogRet;
        if (callbackDialog) {
            sessionStorage.removeItem('CallbackDialogRet');
            dialogRef.current.showDialog(callbackDialog);
        }
    }, [])

    return (
        <>
            <ServerState serverState={state} localState={localState} setLocalState={setLocalState} />
            <SparkleDialog ref={dialogRef} handleRefresh={handleRefresh} subscriptionNo={state.SubscriptionNo} />
            <PageContainer back title={gstate.IsNorway.get() ? 'Skjul ditt nummer' : 'Visa inte ditt nummer'} noSelect id="HiddenNumber">
                <Grid container direction="column" justifyContent="space-between" alignItems="center">
                    <div style={{ fontFamily: theme.typography.fontFamily2, padding: '35px 17px 20px 17px' }}>
                        {gstate.IsNorway.get() ? 'Her er de forskjellige måtene du kan skjule ditt nummer fra opplysningen, fra andre når du ringer ut eller ved sikkerhetstjenesten hemmelig nummer.' : 'Välj på vilket sätt du vill sluta visa ditt nummer'}
                    </div>
                    {state.Services && state.Services.length > 0 ? state.Services.map((service) => {
                        return (
                            <div key={service.ServiceNo} style={{ width: '100%' }}>
                                <ActionBox fullWidth disabled={(state.IsPaused && service.ServiceNo !== '176') || state.IsCancelled}
                                    title={service.Name}
                                    subTitle="&nbsp;"
                                    icon={service.Image}
                                    buttonText={gstate.IsNorway.get() ? "Administrer" : "Hantera"}
                                    action={() => { sessionStorage.ServiceNo = service.ServiceNo; sessionStorage.GroupId = service.GroupId; dialogRef.current.showDialog('changeService'); }}
                                />
                            </div>
                        );
                    }) : null}

                </Grid>
            </PageContainer>
        </>
    );
}