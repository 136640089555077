import React from 'react';
import PropTypes from 'prop-types';

const MasonryShoppingLayout = props => {
    const columnWrapper = {};
    const result = [];
    const weights = [];

    // create columns
    for (let i = 0; i < props.columns; i++) {
        columnWrapper[`column${i}`] = [];
        weights[i] = 0;
    }
    // divide children into columns
    for (let i = 0; i < props.children.length; i++) {
        const columnIndex = weights.indexOf(Math.min(...weights));

        //console.log('Index: ' + i.toString());
        //console.log("Vikter: ")
        //console.log(...weights);
        //console.log("Vald kolumn: " + columnIndex.toString());

        weights[columnIndex] += Number(props.children[i].props.weight);
        columnWrapper[`column${columnIndex}`].push(
            <div key={i}>
                {props.children[i]}
            </div>
        );
    }

    // wrap children in each column with a div
    for (let i = 0; i < props.columns; i++) {
        result.push(
            <div key={i}
                style={{
                    marginLeft: `${i > 0 ? props.gap : 0}px`,
                    flex: 1,
                }}>
                {columnWrapper[`column${i}`]}
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', marginTop: props.gap + 'px', width: '100%' }}>
            {result}
        </div>
    );
}

MasonryShoppingLayout.propTypes = {
    columns: PropTypes.number.isRequired,
    gap: PropTypes.number.isRequired,
    children: PropTypes.arrayOf(PropTypes.element),
};
MasonryShoppingLayout.defaultProps = {
    columns: 2,
    gap: 36,
};

export default MasonryShoppingLayout;