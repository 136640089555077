import React, { useState } from "react"
import { flushSync } from "react-dom"
import { Popover as HeadlessPopover } from "@headlessui/react"
import Icon from "../../../components/Selfcare/Common/Icon"
import { useTheme } from "@mui/material"
import styles from "./styles.module.css"
import { useFloating, shift, flip, size } from "@floating-ui/react-dom"
import globalState from "../../../state/GlobalState"
import { useState as useGlobalState } from "@hookstate/core"

const Popover = ({ heading, children }) => {
  const isNorway = useGlobalState(globalState).isNorway

  const theme = useTheme()

  const [maxHeight, setMaxHeight] = useState(null)
  const borderRadius = 30 // Compensate for the border radius of the dialogs
  const { refs, floatingStyles } = useFloating({
    middleware: [
      size({
        apply({ availableHeight }) {
          flushSync(() => setMaxHeight(availableHeight - borderRadius))
        }
      }),
      shift(),
      flip()
    ]
  })
  return (
    <HeadlessPopover>
      <HeadlessPopover.Button
        ref={refs.setReference}
        className={styles.button}
        aria-label={isNorway ? "Hjelp" : "Hjälp"}
      >
        <Icon name="information" size={24} color={theme.palette.primary.main} />
      </HeadlessPopover.Button>
      <HeadlessPopover.Panel
        ref={refs.setFloating}
        className={styles.panel}
        style={{ ...floatingStyles, maxHeight }}
      >
        <div className={styles.headingContainer}>
          <p>{heading}</p>
          <HeadlessPopover.Button aria-label={isNorway ? "Lukk" : "Stäng"}>
            <Icon name="close" size={24} color={theme.palette.primary.main} />
          </HeadlessPopover.Button>
        </div>
        <hr role="presentation" />
        <div className={styles.contentContainer}>{children}</div>
      </HeadlessPopover.Panel>
    </HeadlessPopover>
  )
}

export default Popover
