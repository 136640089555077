import React from "react"
import { useTheme } from "@mui/material/styles"
import { Grid } from "@mui/material"
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import Icon from "../../components/Selfcare/Common/Icon"

const DataMeter = ({ Pot }) => {
  const theme = useTheme()

  const { IsFreeData, Label1, Label2, Value1, Value2, Values } = Pot

  const textContent = (
    <>
      {Pot && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {Label1 && (
            <div
              style={{
                fontSize: "12px",
                height: "15px",
                fontFamily: theme.typography.fontFamily2,
                fontWeight: 300,
                fontStyle: "normal"
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: Label1 }} />
            </div>
          )}
          {!IsFreeData ? (
            <>
              {Value1 && (
                <div
                  style={{
                    fontSize: "25px",
                    height: "30px",
                    fontWeight: 500
                  }}
                >
                  {Value1}
                </div>
              )}
              {Label2 && (
                <div
                  style={{
                    fontSize: "12px",
                    height: "15px",
                    fontFamily: theme.typography.fontFamily2,
                    fontWeight: 300,
                    fontStyle: "normal"
                  }}
                >
                  {Label2}
                </div>
              )}
              {Value2 && (
                <div
                  style={{
                    fontSize: "16px",
                    height: "20px",
                    fontWeight: 500
                  }}
                >
                  {Value2}
                </div>
              )}
            </>
          ) : (
            <div style={{ marginTop: "-8px" }}>
              <Icon
                name="infinity"
                size={48}
                color={theme.palette.text.primary}
              />
            </div>
          )}
        </Grid>
      )}
    </>
  )

  return (
    <div style={{ width: "150px", height: "150px" }}>
      {[2, 1, 0].reduce((children, index) => {
        const { Percentage = 0, Color = "#eee" } = Values?.[index] ?? {}
        return (
          <CircularProgressbarWithChildren
            key={index}
            value={Percentage}
            strokeWidth={8}
            styles={buildStyles({
              pathColor: Color,
              trailColor: index === 0 ? "#eee" : "transparent",
              pathTransitionDuration: 0
            })}
          >
            {children}
          </CircularProgressbarWithChildren>
        )
      }, textContent)}
    </div>
  )
}

export default DataMeter
