import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import useWindowDimensions from "../hooks/useWindowDimensions";
import auth from "../auth";
import Version from "../version";
import { useConnect } from 'dotnetify';
import ServerState from '../controls/ServerState';
import { useTheme } from '@mui/material/styles';
import InsuranceTestResult from '../controls/InsuranceTestResult';

import ChiliNoLogo from '../assets/img/svg/ChiliNoLogoSmall.svg';

export default function InsCallback() {
    const wDim = useWindowDimensions();
    const getParameterByName = (name) => {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };
    const location = useLocation()
    const history = useHistory()

    const gstate = useGlobalState(globalState);
    const theme = useTheme();

    const { vm, state, setState } = useConnect("InsCallback", { Buzy: false }, {
        headers: {
            ClientVersion: Version.getVersion(),
        },
        vmArg: {
            Uri: window.location.href,
            transactionId: getParameterByName('quoteId'),
            validationId: getParameterByName('validationId'),
        },
        exceptionHandler: _ => auth.signOut()
    });
    //useEffect(() => {
    //    const queryParams = new URLSearchParams(location.search);
    //    if (queryParams.has('validationId')) {
    //        queryParams.delete('validationId');
    //    }
    //    if (queryParams.has('quoteId')) {
    //        queryParams.delete('quoteId');
    //    }
    //    history.replace({
    //        search: queryParams.toString(),
    //    });
    //}, [])
    return (
        <>
            <ServerState serverState={state} />
            <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', marginTop: '26px' }}>
                <div style={{ width: wDim.desktop ? '65px' : '54px' }}><img src={ChiliNoLogo} height={wDim.desktop ? "41" : "32"} alt="" /></div>
            </div>
            {state.ServerReady &&
                <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <InsuranceTestResult state={state} setState={setState} vm={vm} />
                </div>
            }
            {state.ServerError &&
                <>
                    <div style={{ fontSize: '20px', color: 'red', textAlign: 'center', marginTop: '20px' }}>
                        {state.IsNorway ? 'Noe gikk galt.' : 'Något gick fel.'}
                    </div>
                    <div style={{ fontSize: '16px', color: 'red', textAlign: 'center' }}>
                        {state.IsNorway ? 'Resultater fra testen mangler.' : 'Resultat från testet saknas.'}
                    </div>
                </>
            }
        </>
    );
}