import React, { useEffect } from "react";
import auth from "../auth";
import Version from "../version";
import { useConnect } from 'dotnetify';
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

export default function Loading(props) {
    const gstate = useGlobalState(globalState);
    const { t } = useTranslation();
    const { vm, state, setState } = useConnect("Loading", {
        LoadingSubscription: true
    }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
        },
        exceptionHandler: _ => auth.signOut()
    });
    const theme = useTheme();
    const ReactGA = window.ReactGA;

    useEffect(() => {
        if (state.ServerToken) {
            window.sessionStorage.ServerToken = state.ServerToken;
        }
    }, [state.ServerToken]);

    useEffect(() => {
        const hasLocalStorage = function () {
            try {
                localStorage.setItem("__test", "data");
                localStorage.removeItem("__test");
            } catch (e) {
                return false;
            }
            return true;
        }

        if (state.EncryptedPassword && hasLocalStorage() && localStorage.chRememberMe) {
            localStorage.chPassword = state.EncryptedPassword;
        }
    }, [state.EncryptedPassword]);

    useEffect(() => {
        if (state.Greeting) {
            gstate.Greeting.set(state.Greeting);
            gstate.PackageType.set(state.PackageType);
            gstate.IsNorway.set(state.IsNorway);
            gstate.IsSweden.set(state.IsSweden);
            gstate.HasBirthday.set(state.HasBirthday);
            gstate.Fullname.set(state.Fullname);
            gstate.Msisdn.set(state.Msisdn);
            gstate.CustomerSource.set(state.CustomerSource);
            gstate.HideMenu.set(state.HideMenu);
        }
    }, [state.Greeting]);

    useEffect(() => {
        if (state.GoToOverview) {
            ReactGA.set({ page: '/home' });
            ReactGA.pageview('/home');
            window.location.assign('/home');
        }
    }, [state.GoToOverview]);

    useEffect(() => {
    if (state.SetNewPassword) {
        window.location.assign('/set-new-password');
    }
    }, [state.SetNewPassword]);

    return (
        <div id="preloader">
            <div id="loader"></div>
        </div>
    );
}

