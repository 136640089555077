import React from "react"
import Button from "@mui/material/Button"

export const LargeButton = ({ ...other }) => (
  <Button
    variant="contained"
    color="primary"
    sx={{
      borderRadius: "24px",
      padding: "14px 28px 12px 28px",
      lineHeight: "14px",
      fontSize: "14px",
      fontWeight: 400
    }}
    {...other}
  />
)

export const SmallButton = ({ ...other }) => (
  <Button
    variant="contained"
    color="primary"
    size="small"
    sx={{
      borderRadius: "12px",
      padding: "5px 12px 4px 12px",
      lineHeight: "13px",
      fontSize: "13px",
      fontWeight: 400
    }}
    {...other}
  />
)
