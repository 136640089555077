import React from 'react';
import PropTypes from 'prop-types';
import LoginPage from '../views/LoginPage';
import Loading from '../views/Loading';
import auth from "../auth";

export class AppStart extends React.Component {

  constructor(props) {
    super(props);

    this.state = { authenticated: auth.hasAccessToken() };
  }

  render() {
    const handleAuthenticated = _ => this.setState({ authenticated: true });

      return !this.state.authenticated ?
          <LoginPage onAuthenticated={handleAuthenticated} /> : <Loading/>;
  }
}

AppStart.propTypes = {
  children: PropTypes.element
};


