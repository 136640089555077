import Version from "./version";

class Auth {
    url = "/token";
    signIn(username, password, persist, rememberMe) {
        var error = false;
        var clientVersion = Version.getVersion();
        return fetch(this.url, {
            method: 'post',
            mode: 'no-cors',
            body: "username=" + username + "&password=" + encodeURIComponent(password) + "&client_version=" + clientVersion + "&rememberMe=" + rememberMe + "&grant_type=password&client_id=chiliminside",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
        })
        .then(response => {
            if (!response.ok) {
                error = true;
            }
            return response.json();
        })
        .then(token => {
            if (error) {
                throw new Error(token.error_description);
            } else {
                window.appInsights.setAuthenticatedUserContext(username);
                window.sessionStorage.setItem("access_token", token.access_token);
                window.sessionStorage.setItem("access_identity", username);
            }
        });
    }
    signOutManually() {
        window.sessionStorage.setItem("loggedOut", "TRUE");
        this.signOut();
    }
    signOut() {
        window.sessionStorage.removeItem("access_token");
        window.sessionStorage.removeItem("access_identity");
        window.location.replace("/");
    }

    getAccessToken() {
        return window.sessionStorage.getItem("access_token");
    }
    getUsername() {
        return window.sessionStorage.getItem("access_identity");
    }
    hasAccessToken() {
        return this.getAccessToken() !== null;
    }
}

export default new Auth();