export default class NavigationHelper {
    static pages = ['/home','/invoice-list','/subscriptions','/shopping','/support']
    
    static getPageName(index, packageType) {
        if (packageType === 'prepaid' && index === 1) {
            return "Forbruk";
        }
        return 'selfcare:menu.top.' + index;
    }

    static getPageUrl(index) {
        return this.pages[index];
    }
    static getPageIndex(path) {
        var ret = -1;
        this.pages.forEach((page, index) => {
            if (page.valueOf() === path.valueOf()) {
                ret = index;
            }
        });
        return ret;
    }
}