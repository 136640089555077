import React from "react"
import Collapse from "@mui/material/Collapse"
import { Grid } from "@mui/material"
import PotControl from "./PotControl"
import PotLegend from "./components/PotLegend"
import SpinningCogWheels from "./components/SpinningCogWheels"

const PotDisplay = ({
  pots,
  dailyDataStatus,
  onClick,
  hideChevron,
  showLegend,
  legendShadow
}) => {
  const hasLegend = pots && pots.length > 0 && pots[0].HasDetails

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ cursor: !!onClick && hasLegend ? "pointer" : "auto" }}
      >
        {!pots && (
          <React.Fragment>
            <SpinningCogWheels style={{ height: "199px" }} />
          </React.Fragment>
        )}
        <Grid
          role="button"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            if (!!onClick && hasLegend) onClick()
          }}
        >
          {pots &&
            pots.map(pot => {
              var multiple = false
              if (pots.length > 1) {
                multiple = true
              }
              return (
                <PotControl
                  key={pot.Index}
                  Pot={pot}
                  multiple={multiple}
                  hideChevron={hideChevron}
                />
              )
            })}
        </Grid>
        <Collapse
          in={showLegend}
          sx={
            legendShadow
              ? {
                  width: "100%",
                  maxWidth: "450px",
                  boxShadow: "0px 5px 10px #76767629",
                  borderRadius: "10px",
                  overflow: "hidden"
                }
              : {}
          }
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              width: "100%",
              padding: "1rem 0",
              maxWidth: "450px",
              background: "#FFFFFF 0% 0% no-repeat padding-box"
            }}
          >
            {hasLegend &&
              pots[0].Legends.map(legend => {
                return <PotLegend key={legend.Index} PotLegend={legend} />
              })}
          </Grid>
        </Collapse>
        {pots && pots.length > 0 && (
          <div
            style={{
              fontSize: "10px",
              lineHeight: "14px",
              fontWeight: 300,
              fontStyle: "normal",
              marginTop: "10px",
              textAlign: "center"
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: pots[0].Label3 }} />
          </div>
        )}
        {dailyDataStatus && (
          <div style={{ fontStyle: "normal", textAlign: "center" }}>
            {dailyDataStatus}
          </div>
        )}
      </Grid>
    </>
  )
}

export default PotDisplay
