import React, { useState, useRef, useEffect } from "react"
import produce from "immer"
import auth from "../auth"
import Version from "../version"
import * as rdd from "react-device-detect"
import useWindowDimensions from "../hooks/useWindowDimensions"
import { useTranslation } from "react-i18next"
import { useConnect } from "dotnetify"
import globalState from "../state/GlobalState"
import { useTheme } from "@mui/material/styles"
import { useState as globalUseState } from "@hookstate/core"
import ServerState from "../controls/ServerState"
import ToggleButton from "react-toggle-button"
import { Grid, Divider } from "@mui/material"
import { Link } from "react-tiger-transition"
import LeftChevronIcon from "../assets/img/svg/left-chevron.svg"
import SparkleDialog from "../controls/components/SparkleDialog"
import PrepaidContainer from "../controls/PrepaidContainer"
import PotContainer from "../controls/PotContainer"
import Icon from "../components/Selfcare/Common/Icon"

const Action = ({ icon, text, action, detail, altDetailBackground }) => {
  const theme = useTheme()
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        onClick={!!action ? action : undefined}
        sx={{
          padding: "0.5rem",
          cursor: action && "pointer"
        }}
      >
        <Grid container item alignItems="center" xs={9}>
          <Icon name={icon} size={24} color={theme.palette.primary.main} />
          <p
            style={{
              margin: 0,
              marginLeft: "0.5rem",
              fontSize: "0.875rem",
              width: "calc(100% - 24px - 0.5rem)"
            }}
          >
            {text}
          </p>
        </Grid>
        <Grid
          container
          item
          justifyContent="flex-end"
          alignItems="center"
          xs={3}
        >
          {detail && (
            <p
              style={{
                margin: 0,
                padding: "0.25rem 0.5rem 0.25rem 0.5rem",
                backgroundColor: !altDetailBackground ? "white" : "#E7E7E7",
                borderRadius: "6px",
                fontSize: detail.length > 8 ? "0.625rem" : "0.875rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "calc(100% - 18px - 0.5rem)"
              }}
            >
              {detail}
            </p>
          )}
          <span
            style={{
              transform: "rotate(-90deg)",
              height: "18px",
              marginLeft: "0.5rem"
            }}
          >
            <Icon
              name="down-arrow"
              size={18}
              color={
                !altDetailBackground ? theme.palette.primary.main : "8E8E8E"
              }
            />
          </span>
        </Grid>
      </Grid>
    </>
  )
}

const Section = ({ actions, altBackground }) =>
  !actions?.length ? (
    <></>
  ) : (
    <Grid
      container
      flexDirection="column"
      sx={{
        backgroundColor: !altBackground ? "boxBackground.main" : "#F5F5F5",
        borderRadius: "10px",
        marginBottom: "0.5rem"
      }}
    >
      {actions.map((a, i) => (
        <React.Fragment key={a.text}>
          {i !== 0 && (
            <Divider
              sx={{
                backgroundColor: !altBackground ? "#FFC4C4" : "#D3D3D3",
                marginLeft: "calc(0.5rem + 24px + 0.5rem)" // Divider offset = padding + icon size + margin
              }}
            />
          )}
          <Action
            icon={a.icon}
            text={a.text}
            action={a.action}
            detail={a.detail}
            altDetailBackground={a.altDetailBackground}
          />
        </React.Fragment>
      ))}
    </Grid>
  )

const Subscription = () => {
  const { t } = useTranslation()
  const dialogRef = useRef()
  const getParameterByName = name => {
    var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search)
    return match && decodeURIComponent(match[1].replace(/\+/g, " "))
  }
  const { state, vm } = useConnect(
    "Subscription",
    {},
    {
      headers: {
        Authorization: "Bearer " + auth.getAccessToken(),
        ClientVersion: Version.getVersion(),
        ServerToken: window.sessionStorage.ServerToken
      },
      vmArg: { SubscriptionNo: getParameterByName("subscriptionno") },
      exceptionHandler: _ => auth.signOut()
    }
  )
  const [localState, internalSetLocalState] = useState({
    disabled: false,
    busy: false,
    showBuyDataButton: false,
    dialogOpen: false
  })
  const setLocalState = o => {
    internalSetLocalState(
      produce(draft => {
        draft[Object.keys(o)[0]] = Object.values(o)[0]
      })
    )
  }
  const [leftMargin, setLeftMargin] = useState("0px")

  const setVoiceOverFocus = element => {
    var focusInterval = 10 // ms, time between function calls
    var focusTotalRepetitions = 10 // number of repetitions

    element.setAttribute("tabindex", "0")
    element.blur()

    var focusRepetitions = 0
    var interval = window.setInterval(function () {
      element.focus()
      focusRepetitions++
      if (focusRepetitions >= focusTotalRepetitions) {
        window.clearInterval(interval)
      }
    }, focusInterval)
  }

  useEffect(() => {
    function handleResize() {
      var el = document.getElementById("pageContainer-subscription")
      if (el) {
        var offset = el.offsetLeft
        var margin = "-" + offset + "px"
        setLeftMargin(margin)
      } else {
        setLeftMargin("0px")
      }
    }
    setTimeout(function () {
      handleResize()
      var el = document.getElementById("back")
      if (el) setVoiceOverFocus(el)
    }, 150)

    window.addEventListener("resize", handleResize)
    const callbackDialog = sessionStorage.CallbackDialogRet
    if (callbackDialog) {
      sessionStorage.removeItem("CallbackDialogRet")
      dialogRef.current.showDialog(callbackDialog)
    }
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const handleOpenPostpaidTopupDialog = () => {
    dialogRef.current.showDialog("postpaidTopup")
  }
  const handleOpenPostpaidEUTopupDialog = () => {
    dialogRef.current.showDialog("postpaidEUTopup")
  }
  const handleOpenDataReloadDialog = () => {
    dialogRef.current.showDialog("postpaidDataReload")
  }
  const handleOpenPostpaidSuperboostDialog = () => {
    dialogRef.current.showDialog("postpaidSuperboost")
  }
  const handleOpenPrepaidTopupDialog = () => {
    dialogRef.current.showDialog("prepaidTopup")
  }
  const handleOpenChargePrepaidDialog = () => {
    dialogRef.current.showDialog("chargePrepaid")
  }
  const handleChangeDataLevel = () => {
    dialogRef.current.showDialog("changeSubscriptionDataLevel")
  }
  const handleDailyData = () => {
    dialogRef.current.showDialog("dailyData")
  }
  const handleSubscriptionPause = pause => {
    if (pause) {
      dialogRef.current.showDialog("subscriptionpause")
    } else {
      dialogRef.current.showDialog("subscriptionunpause")
    }
  }
  const handleRefresh = () => {
    vm.$dispatch({ Refresh: true })
  }

  const gstate = globalUseState(globalState)
  const theme = useTheme()
  const wDim = useWindowDimensions()

  const showDialog = dialog => dialogRef.current.showDialog(dialog)
  const {
    IsPaused,
    IsCancelled,
    IsAdmin,
    IsAdministrating,
    CanChangeOwner,
    BillogramStep1,
    DirectDebitEnabled
  } = state
  const {
    CanActivateSimcard,
    CanChangeDataLevel,
    SimCardOrderOpen,
    SimCount,
    SubscriptionNo,
    CanPause,
    CanUnPause,
    CanRecruit,
    ShowHiddenNumber,
    HiddenNumberActive,
    HiddenNumberImage,
    HiddenNumberDescription,
    ShowFUP,
    PaymentMethod,
    ActiveServices,
    OtherServices
  } = state.subscription ?? {}

  const actionsSe = [
    ...(!state.IsAdministrating
      ? [
          !BillogramStep1 && {
            icon: "invoice",
            text: "Betalmetod",
            action:
              !IsPaused &&
              !IsCancelled &&
              (() => showDialog("changePaymentMethod"))
          },
          !BillogramStep1 &&
            PaymentMethod === "credit-card" && {
              icon: "credit-card",
              text: "Betalkort",
              action:
                !IsPaused &&
                !IsCancelled &&
                (() => showDialog("changePaymentCard"))
            },
          !BillogramStep1 &&
            DirectDebitEnabled &&
            PaymentMethod === "invoice" &&
            IsAdmin && {
              icon: "content-level",
              text: "Betala via Autogiro",
              action:
                !IsPaused && !IsCancelled && (() => showDialog("autogiro"))
            },
          {
            icon: "friends",
            text: "Chili kompisar",
            action:
              !IsPaused && !IsCancelled && (() => showDialog("viewFriends"))
          }
        ]
      : []),
    ShowFUP && {
      icon: "settings",
      text: "Daglig datagräns/förbrukning",
      action: !IsCancelled && (() => showDialog("FUP"))
    }
  ]
  const actions = [
    CanActivateSimcard && {
      icon: "simcard",
      text: t("selfcare:item.activatesim"),
      action: !IsPaused && !IsCancelled && (() => showDialog("activateSimcard"))
    },
    {
      icon: "pin",
      text: t("selfcare:item.pinpuk"),
      action: !IsPaused && !IsCancelled && (() => showDialog("viewPinPuk"))
    },
    {
      icon: "simcard",
      text: t("selfcare:item.ordernewsim"),
      action:
        !IsCancelled &&
        (SimCount > 1
          ? () =>
              dialogRef.current.navigatePage(
                "/subscriptions/subscription/selectsimtype/?subscriptionno=" +
                  SubscriptionNo,
                "glide-left"
              )
          : () => {
              sessionStorage.simType = "main"
              showDialog("orderSimcard")
            }),
      detail: SimCardOrderOpen && "Bestilt"
    },
    CanChangeDataLevel && {
      icon: "money",
      text: "Endre datagrense",
      action:
        !IsPaused &&
        !IsCancelled &&
        (() => showDialog("changeSubscriptionDataLevel"))
    },
    {
      icon: "block-sim",
      text: t("selfcare:item.blockunlocksim"),
      action: !IsPaused && !IsCancelled && (() => showDialog("blocking"))
    },
    IsAdmin &&
      !IsAdministrating && {
        icon: "profile",
        text: gstate.IsNorway.get() ? "Endre adresse" : "Ändra adress",
        action: !IsPaused && !IsCancelled && (() => showDialog("changeAddress"))
      },
    IsAdmin &&
      CanChangeOwner && {
        icon: "change-owner",
        text: gstate.IsNorway.get()
          ? "Endre eier / overføre abonnement"
          : "Ägarbyte",
        action: !IsCancelled && (() => showDialog("changeOwner"))
      },
    CanPause && {
      icon: "pause-button",
      text: gstate.IsNorway.get() ? "Sett på pause" : "Pausa abonnemang",
      action: !IsCancelled && (() => showDialog("subscriptionpause"))
    },
    CanUnPause && {
      icon: "play-button",
      text: gstate.IsNorway.get()
        ? "Gjenåpne abonnement"
        : "Återöppna abonnemang",
      action: !IsCancelled && (() => showDialog("subscriptionunpause"))
    },
    CanRecruit && {
      icon: "venner",
      text: (
        <>
          {"Chili Kompis "}
          <span style={{ fontWeight: 300 }}>{"- Verv venner"}</span>
        </>
      ),
      action: !IsPaused && !IsCancelled && (() => showDialog("viewFriends"))
    },
    ...(gstate.IsSweden.get() ? actionsSe : [])
  ].filter(a => a)

  const activeServices = [
    gstate.IsNorway.get() &&
      ShowHiddenNumber &&
      HiddenNumberActive && {
        icon: HiddenNumberImage,
        text: HiddenNumberDescription,
        action:
          !IsPaused &&
          !IsCancelled &&
          (() =>
            dialogRef.current.navigatePage(
              "/subscriptions/subscription/hiddennumber/?subscriptionno=" +
                SubscriptionNo,
              "glide-left"
            ))
      },
    ...(!ActiveServices?.length
      ? []
      : ActiveServices.map(s => ({
          icon: s.Image,
          text: s.Name,
          action:
            !(IsPaused && s.ServiceNo === "176") &&
            !IsCancelled &&
            (s.ServiceNo === "158"
              ? () => {
                  showDialog("insurance")
                }
              : () => {
                  sessionStorage.ServiceNo = s.ServiceNo
                  sessionStorage.GroupId = s.GroupId
                  showDialog("changeService")
                }),
          detail: s.Status
        })))
  ].filter(s => s)

  const otherServices = [
    gstate.IsNorway.get() &&
      ShowHiddenNumber &&
      !HiddenNumberActive && {
        icon: HiddenNumberImage,
        text: HiddenNumberDescription,
        action:
          !IsPaused &&
          !IsCancelled &&
          (() =>
            dialogRef.current.navigatePage(
              "/subscriptions/subscription/hiddennumber/?subscriptionno=" +
                SubscriptionNo,
              "glide-left"
            ))
      },
    ...(!OtherServices?.length
      ? []
      : OtherServices.map(s => ({
          icon: s.Image,
          text: s.Name,
          action:
            !IsPaused &&
            !IsCancelled &&
            (s.ServiceNo === "158"
              ? () => {
                  showDialog("insurance")
                }
              : () => {
                  sessionStorage.ServiceNo = s.ServiceNo
                  sessionStorage.GroupId = s.GroupId
                  showDialog("changeService")
                }),
          detail: s.Status,
          altDetailBackground: true
        })))
  ].filter(s => s)

  return (
    <>
      <ServerState
        serverState={state}
        localState={localState}
        setLocalState={setLocalState}
      />
      <SparkleDialog
        ref={dialogRef}
        handleRefresh={handleRefresh}
        subscriptionNo={state.SubscriptionNo}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          overflowX: "hidden",
          overflowY: rdd.isFirefox
            ? "auto"
            : wDim.desktop
              ? "overlay"
              : "scroll",
          height: wDim.height,
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: rdd.isFirefox ? "thin" : null
        }}
      >
        <div
          id="pageContainer-subscription"
          className="noselect"
          style={{
            padding: wDim.phone ? "25px 7px" : "35px 17px",
            maxWidth: wDim.desktop ? "1024px" : "unset",
            width: "100%"
          }}
        >
          <Link
            tabIndex="0"
            role="button"
            aria-label={
              (gstate.IsSweden.get() ? "Tillbaka till " : "Tilbake til ") +
              gstate.topModuleName.get()
            }
            id="back"
            replace
            to={gstate.topModuleUrl.get() ? gstate.topModuleUrl.get() : "/home"}
            transition="glide-right"
            style={{
              outline: "none",
              marginLeft: leftMargin,
              textDecoration: "none",
              fontSize: "14px",
              fontFamily: theme.typography.fontFamily2,
              fontWeight: 300
            }}
          >
            <img
              src={LeftChevronIcon}
              height="32"
              alt=""
              style={{ marginTop: "-5px", marginRight: "-6px" }}
            />
            <div
              style={{
                verticalAlign: "top",
                marginTop: "2px",
                color: theme.palette.primary.main,
                display: "inline-block"
              }}
            >
              {gstate.topModuleName.get()}
            </div>
          </Link>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{
              paddingBottom: wDim.phone || wDim.tablet ? "100px" : "0px"
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {state.IsAdministrating && (
                <>
                  <ToggleButton
                    colors={{
                      active: {
                        base: theme.palette.primary.main
                      }
                    }}
                    containerStyle={{
                      display: "inline-block",
                      width: "150px",
                      height: "40px"
                    }}
                    trackStyle={{
                      width: "160px",
                      borderRadius: 2,
                      height: "40px"
                    }}
                    thumbStyle={{
                      borderRadius: 2,
                      width: "38px",
                      height: "38px"
                    }}
                    thumbAnimateRange={[1, 120]}
                    activeLabelStyle={{
                      width: "100px",
                      fontSize: "14px",
                      left: "20px",
                      fontFamily: theme.typography.fontFamily
                    }}
                    inactiveLabelStyle={{
                      width: "100px",
                      fontSize: "14px",
                      right: "20px",
                      fontFamily: theme.typography.fontFamily
                    }}
                    inactiveLabel={
                      gstate.IsSweden.get() ? "Användare" : "Bruker"
                    }
                    activeLabel={
                      gstate.IsSweden.get() ? "Administratör" : "Administrator"
                    }
                    value={state.subscription?.IsAdmin}
                    onToggle={value => {
                      vm.$dispatch({ ChangeAdmin: !value })
                    }}
                  />
                  <div style={{ marginTop: "20px", width: "100%" }}></div>
                  {(state.subscription?.IsPostpaid ||
                    state.subscription?.IsData) && (
                    <PotContainer
                      pots={state.Pots}
                      extState={state}
                      localState={localState}
                      setLocalState={setLocalState}
                      dailyDataStatus={state.DailyDataStatus}
                      disabled={state.IsPaused}
                      handleBuyData={handleOpenPostpaidTopupDialog}
                      handleBuyDataReload={handleOpenDataReloadDialog}
                      handleBuySuperboost={handleOpenPostpaidSuperboostDialog}
                      handleBuyEUData={handleOpenPostpaidEUTopupDialog}
                      handleDailyData={handleDailyData}
                      handleChangeDataLevel={handleChangeDataLevel}
                      handleSubscriptionPause={handleSubscriptionPause}
                      onClick={() => vm.$dispatch({ ToggleDetails: 1 })}
                    />
                  )}
                  {state.subscription?.IsPrepaid && (
                    <PrepaidContainer
                      state={state}
                      handleBuyData={handleOpenPrepaidTopupDialog}
                      handleChargePrepaid={handleOpenChargePrepaidDialog}
                    />
                  )}
                </>
              )}
            </Grid>
            {state.subscription && (
              <Grid
                container
                alignItems="center"
                sx={{
                  border: "2px solid #FFCACA", // Should this color be in palette?
                  borderRadius: "10px",
                  padding: "1rem",
                  paddingRight: "0.5rem",
                  maxWidth: "450px",
                  cursor: "pointer"
                }}
                onClick={() => showDialog("changeUser")}
              >
                <Grid
                  container
                  direction="column"
                  sx={{ width: "calc(100% - 18px)" }}
                >
                  <p style={{ margin: 0, fontSize: "0.875rem" }}>
                    {state.subscription.UserName}
                  </p>
                  {/* Put p margin styling somewhere else, maybe globally. */}
                  <Grid container direction="row">
                    <p
                      style={{
                        margin: 0,
                        fontSize: "0.625rem"
                      }}
                    >
                      {state.subscription.Msisdn}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        marginLeft: "0.5rem",
                        padding: "0 0.25rem 0 0.25rem",
                        borderRadius: "2px",
                        fontSize: "0.625rem",
                        backgroundColor: "#E5E5E5" // This color is also not in  the palette.
                      }}
                    >
                      {IsAdmin ? "Administrator" : "Bruker"}
                    </p>
                  </Grid>
                </Grid>
                <span
                  style={{
                    transform: "rotate(-90deg)",
                    width: "18px"
                  }}
                >
                  <Icon
                    name="down-arrow"
                    size={18}
                    color={theme.palette.primary.main}
                  />
                </span>
              </Grid>
            )}
            {state.subscription ? (
              <Grid container spacing={1}>
                <Grid container item direction="column" xs={12} md={6}>
                  <p
                    style={{
                      color: theme.palette.primary.main,
                      margin: "0.5rem"
                    }}
                  >
                    {gstate.IsNorway.get() ? "Handlinger" : "Åtgärder"}
                  </p>
                  <Section
                    actions={
                      state.subscription?.CanChangePackage
                        ? [
                            {
                              icon: "money",
                              text: gstate.IsSweden.get()
                                ? t(
                                    "selfcare:item.change-subscription-type-swe"
                                  )
                                : state.IsPrepaid
                                  ? "Endre til abonnement"
                                  : "Endre abonnement",
                              action:
                                !IsPaused &&
                                !IsCancelled &&
                                (() => showDialog("changePackage")),
                              detail: state.subscription?.OpenOrder
                                ? t("selfcare:item.orderbeingprocessed", {
                                    0: state.subscription?.PackageDescription
                                  })
                                : state.subscription?.PackageDescription
                            }
                          ]
                        : []
                    }
                  />
                  <Section actions={actions} />
                </Grid>
                <Grid container item direction="column" xs={12} md={6}>
                  {!!activeServices.length && (
                    <p
                      style={{
                        color: theme.palette.primary.main,
                        margin: "0.5rem"
                      }}
                    >
                      {gstate.IsNorway.get()
                        ? "Aktive tjenester"
                        : "Aktiva tjänster"}
                    </p>
                  )}
                  <Section actions={activeServices} />
                  {!!otherServices.length && (
                    <p
                      style={{
                        margin: "0.5rem"
                      }}
                    >
                      {gstate.IsNorway.get()
                        ? "Ikke aktive tjenester"
                        : "Inte aktiva tjänster"}
                    </p>
                  )}
                  <Section actions={otherServices} altBackground />
                </Grid>
              </Grid>
            ) : (
              <div id="preloader">
                <div id="loader"></div>
              </div>
            )}
          </Grid>
        </div>
      </div>
    </>
  )
}

export default Subscription
