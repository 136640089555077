import React from 'react';
import auth from "../auth";
import Version from "../version";
import ServerState from "../controls/ServerState";
import { useConnect } from "dotnetify";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ReactLoading from 'react-loading';
import Icon from "../components/Selfcare/Common/Icon";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LargeButton } from '../controls/components/Buttons';
import { TextField } from '../controls/TextField';
export default function PrepaidTopup(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { vm, state, setState } = useConnect("PrepaidTopup",
        {
            show: false,
            redirect: null,
            toUrl: null,
            Packages: null,
            ChangePriceplanDialogOpen: false,
            OrderSent: false,
            OrderError: '',
            OrderOpen: '',
            DisableButtons: false,
            buzy: false,
            canbuy: false,
            Loading: true,
            password: '',
            EnterPasswordDialogOpen: false,
        },
        {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg:
            {
                SubscriptionNo: props.SubscriptionNo,
            },
            exceptionHandler: _ => auth.signOut()
        });

    const handleChange = event => {
        setState({ value: event.target.value, canbuy: true });
    };
    return (
        <>
            <ServerState serverState={state} setState={setState} handleClose={props.handleClose} />
            <div style={{ height: '100vh' }}>
                <Dialog
                    open={state.EnterPasswordDialogOpen}
                    onClose={() => { setState({ EnterPasswordDialogOpen: false, buzy: false }); }}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <div style={{ display: 'flex' }}>
                            <Icon name="edit" size={36} color={theme.palette.primary.main} />
                            <span style={{ marginLeft: '20px', fontWeight: '700' }}>{t('selfcare:title.code-set-by-legal-owner')}</span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {state.PasswordText}<br />
                        <TextField fullWidth
                            autoFocus
                            localState={state}
                            name="password"
                            setLocalState={setState}
                            id="password"
                            label={t('selfcare:label.code')}
                            type="text"
                            margin="normal"
                        />
                        {state.PasswordErrorText && <div style={{ width: '100%', padding: '5px', backgroundColor: theme.palette.primary.main, color: 'white', fontSize: '12px' }}>{state.PasswordErrorText}</div>}
                    </DialogContent>
                    <DialogActions>
                        <LargeButton onClick={() => { vm.$dispatch({ SetPassword: state.password }); }}>
                            {t('selfcare:button.proceed-uc')}
                        </LargeButton>
                        <LargeButton color="secondary" variant="text" onClick={() => { setState({ EnterPasswordDialogOpen: false, buzy: false }); props.handleClose(); }}>
                            {t('selfcare:button.cancel-uc')}
                        </LargeButton>
                    </DialogActions>
                </Dialog>
                {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
                <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            <Icon name="money" size={48} color="#FFFFFF" />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                {t('selfcare:item.datapacket-chili-prepaid')}
                            </div>
                            <div style={{ fontSize: '14px', fontFamily: theme.typography.fontFamily2 }}>
                                <span dangerouslySetInnerHTML={{ __html: t('selfcare:info.datapacket-description') }} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <form onSubmit={(e) => { e.preventDefault(); setState({ Processing: true }); vm.$dispatch({ PerformChange: { SelectedProductNo: state.value } }); }}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            <div style={{ paddingTop: '20px' }}>
                                {t('selfcare:item.choose-datapacket')}
                            </div>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label={t('selfcare:label.datapackets')}
                                    name="data"
                                    value={state.value}
                                    onChange={handleChange}
                                >
                                    {state.TopUps ? state.TopUps.map((option) => {
                                        return (
                                            <FormControlLabel key={option.ProductNo} value={option.ProductNo} control={<Radio color="primary" />} label={option.Description} style={{ fontWeight: 700 }} />
                                        );
                                    }) : null}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            {t('selfcare:item.datapacket-duration')}
                        </Grid>
                        <Grid item style={{ paddingTop: '20px' }}>
                            <Grid container direction="column" justifyContent="center">
                                <Grid container justifyContent="center" direction="row">
                                    <LargeButton type="submit" disabled={!state.canbuy || state.OrderOpen || state.Processing} style={{ marginRight: '10px' }}>{t('selfcare:button.confirm-purchase')}</LargeButton>
                                    <LargeButton color="secondary" variant="text" id='cancel' onClick={() => { props.handleClose(); }}>{t('selfcare:button.cancel')}</LargeButton>
                                </Grid>
                                {state.Processing && <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />}
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
}