import React, { useEffect, useState, useRef } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import auth from "../auth";
import YouTube from 'react-youtube';
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import dotnetify, { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid, Link } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { TextField } from '../controls/TextField';
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Tooltip from '@mui/material/Tooltip';
import Icon from "../components/Selfcare/Common/Icon";
import InsuranceTestResult from '../controls/InsuranceTestResult';
import InsuranceImg from '../assets/img/InsuranceImage.jpg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import AppleLogo from '../assets/img/AppleLogo.png';
import AndroidLogo from '../assets/img/AndroidLogo.png';

//dotnetify.debug = true;
const stepperStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        "& .Mui-disabled .MuiStepIcon-root": {
            width: '32px',
            height: '32px',
            padding: "4px 2px 0px 2px",
            borderRadius: "50%",
            border: "2px solid " + theme.palette.text.primary,
            fill: 'white',
            "& text": {
                fill: theme.palette.text.primary,
                fontSize: '16px',
                fontWeight: '700',
            },
        },
        "& .MuiStepIcon-active": {
            width: '32px',
            height: '32px',
            padding: "4px 2px 0px 2px",
            borderRadius: "50%",
            border: "2px solid " + theme.palette.primary.main,
            fill: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
            "& text": {
                fill: 'white',
                fontSize: '16px',
                fontWeight: '700',
            },
        },
        "& .MuiStepIcon-completed": {
            width: '40px',
            height: '40px',
            fill: theme.palette.primary.main,
        },
        "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
            fontSize: '11px',
            fontWeight: 200,
        },
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));
const BlackTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 11,
        fontFamily: 'Arial',
    },
    arrow: {
        color: theme.palette.common.black,
    }
}))(Tooltip);
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.boxGreyBackground.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.black,
            },
        },
        '&:hover': {
            backgroundColor: theme.palette.boxGreyBackground.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.black,
            },
        },
    },
}))(MenuItem);

export default function Insurance(props) {
    const wDim = useWindowDimensions();
    const { t } = useTranslation();
    const gstate = useGlobalState(globalState);
    const isSweden = gstate.IsSweden.get();
    const isNorway = gstate.IsNorway.get();
    const theme = useTheme();
    const classes = stepperStyles;
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [step, setStep] = useState(1);
    const [videoOpen, setVideOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const { vm, state, setState } = useConnect("Insurance", { Step: 0, disabled: false, busy: false, IMEI: '', imeiOK: false, termsAccepted: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });

    useEffect(() => { //OnMount
        setStep(0);
    }, [])
    useEffect(() => {
        switch (step) {
            case 1:
            case 99:
                props.setTitle(isNorway ? 'Forsikring' : 'Försäkring');
                props.setBackFunction(null);
                break;
            case 2:
                props.setTitle(isNorway ? 'Forsikring' : 'Försäkring');
                props.setBackFunction(() => { setStep(1); });
                break;

            case 3:
                props.setTitle(isNorway ? 'Oppsummering' : 'Summering');
                props.setBackFunction(() => { setStep(2); });
                break;
            case 4: //Start test
                props.setTitle(isNorway ? 'Sjekk mobil' : 'Test av mobil');
                props.setBackFunction(() => { setStep(3); });
                break;
            case 5: //Test results
                props.setTitle('');
                props.setBackFunction(null);
                break;
            case 10: //Use insurance
                props.setTitle(isNorway ? 'Forsikring' : 'Försäkring');
                props.setBackFunction(() => { setStep(99); });
                break;
            default:
                props.setTitle("");
                props.setBackFunction(null);
                break;
        }
    }, [step]);
    useEffect(() => {
        if (state.StateName === 'Step') {
            setStep(state.StateValue);
        }
    }, [state.StateName]);
    useEffect(() => {
        if (state.IMEI !== '' && state.IMEI !== null) {
            if (state.IMEI.length === 15)
                setState({ imeiOK: true });
            else
                setState({ imeiOK: false });
        }
    }, [state.IMEI]);

    const opts = {
        height: wDim.phone ? wDim.width * 0.61 : '390',
        width: wDim.phone ? wDim.width : '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };
    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleChangeTerms = (event) => {
        setState({ termsAccepted: event.target.checked })
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleVideoClose = () => {
        setVideOpen(false);
    }
    const videoEl = useRef(null);
    const handlePaste = event => {
        let dTemp = event.clipboardData.getData('text');
        let d = dTemp.replace(/\s/g, '');
        if (dTemp !== d) {
            event.preventDefault();
            setTimeout(
                function () {
                    setState({ "IMEI": d });
                },
                50
            );
        }
    }
    const [IMEITipOpen, setÍMEITipOpen] = useState(false);
    const [EmailTipOpen, setEmailTipOpen] = useState(false);
    const copySuccess = (type) => {
        if (type === 'IMEI') {
            setÍMEITipOpen(true);
            setTimeout(
                function () {
                    setÍMEITipOpen(false);
                },
                2000
            );
        }
        if (type === 'Email') {
            setEmailTipOpen(true);
            setTimeout(
                function () {
                    setEmailTipOpen(false);
                },
                2000
            );
        }
    };

    const attemptPlay = () => {
        setTimeout(
            function () {
                videoEl &&
                    videoEl.current &&
                    videoEl.current.play().catch(error => {
                        console.error("Error attempting to play", error);
                    });
            },
            500
        );
    };
    const getSteps = () => {
        return ['', '', '', '', ''];
    }
    const steps = getSteps();
    return <>
        <ServerState serverState={state} setState={setState} handleClose={props.handleClose} handleRefresh={props.handleRefresh} />
        <Dialog
            fullScreen={fullScreen}
            open={videoOpen}
            onClose={handleVideoClose}
            aria-labelledby="responsive-dialog-title"
            style={{ margin: wDim.phone ? '20px 5px 5px 5px' : '50px' }}
        >
            <Toolbar variant="dense">
                <IconButton
                    color="inherit"
                    onClick={handleVideoClose}
                    aria-label={isNorway ? "Lukk" : "Stäng"}
                    edge="end"
                    size="large">
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <div>
                <video
                    style={{ maxWidth: "100%", width: "400px", margin: "0 auto" }}
                    playsInline
                    controls
                    alt="All the devices"
                    src={videoUrl}
                    ref={videoEl}
                />
            </div>
        </Dialog>
        {step === 0 &&
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                    <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'150px'} width={'150px'} />
                </Grid>
            </Grid>
        }
        {step > 1 && step < 5 &&
            <div className={classes.root}>
                <Stepper activeStep={step - 2} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>

        }
        {step === 1 && /*Start page*/
            <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                <img src={InsuranceImg} style={{ margin: '0px', width: '100%' }} alt="" />
                <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', padding: '0px 17px' }}>
                    <div style={{ fontSize: '16px', marginTop: '23px', textAlign: 'center', fontWeight: 300 }}>
                        {isNorway ? 'Skadeforskring fra Chilimobil' : 'Skadeförsäkring från Chilimobil'}
                    </div>
                    <div style={{ fontSize: '22px', textAlign: 'center', fontWeight: 500, marginTop: '20px' }}>
                        {state.InsurancePrice}
                    </div>
                    <div style={{ marginTop: '15px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', textAlign: 'center' }}>
                        <div style={{ fontSize: '30px', textAlign: 'center', fontWeight: 700 }}>
                            {isNorway ? '0,- første måned' : '0 kr första månaden'}
                        </div>
                        <LargeButton disabled={state.buzy} style={{ marginTop: '19px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ StartOrder: true }); }}>{isNorway ? 'Bestill forsikring' : 'Beställ försäkring'}</LargeButton>
                    </div>
                    <div style={{ fontSize: '10px', textAlign: 'center', marginTop: '16px' }}>
                        <div>{isNorway ? 'Forskring i samarbeid med Berkley Nordic' : 'Försäkring i samarbete med Berkley Nordic'}</div>
                    </div>
                    <div style={{ marginTop: '31px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px' }}>
                        <div style={{ fontSize: '12px', paddingLeft: '17px', marginTop: '19px' }}>
                            <div style={{ marginBottom: '15px' }}>
                                <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                <span>{isNorway ? 'Erstatning ved uhell' : 'Ersättning vid skada'}</span>
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                                <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                <span>{isNorway ? 'Lav egenandel, kun 500 kr' : 'Låg självrisk, bara 500 kr'}</span>
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                                <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                <span>{isNorway ? 'Enkel og rask skadebehandling' : 'Enkel och snabb hantering vid skada'}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '31px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px' }}>
                        <div style={{ fontSize: '14px', fontWeight: 500, textAlign: 'center' }}>
                            {isNorway ? 'Forskringen dekker all type skade når uhellet er ute' : 'Försäkringen täcker alla typer av skador när olyckan är framme'}
                        </div>
                        <div style={{ fontSize: '12px', paddingLeft: '17px', marginTop: '19px' }}>
                            <div style={{ marginBottom: '15px' }}>
                                <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                <span>{isNorway ? 'Fallskade' : 'Stöt- och fallskada'}</span>
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                                <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                <span>{isNorway ? 'Vannskade' : 'Fuktskada'}</span>
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                                <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                <span>{isNorway ? 'Knust skjerm, bakdel og/eller kamera' : 'Sprucken skärm, bakdel och/eller kamera'}</span>
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                                <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                <span>{isNorway ? 'Kortslutning' : 'Elektriska och mekaniska fel'}</span>
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                                <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                <span>{isNorway ? 'Brannskade' : 'Brandskada'}</span>
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                                <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                <span>{isNorway ? 'Maskinskade etter at garantien har gått ut' : 'Maskinskada efter garantitiden'}</span>
                            </div>
                            {isSweden && <div style={{ marginBottom: '15px' }}>
                                <FiberManualRecordIcon style={{ fontSize: 'small', color: theme.palette.primary.main, marginLeft: '-20px', marginRight: '7px' }} />
                                <span>{isNorway ? 'Med mer.' : 'Gäller i hela världen	'}</span>
                            </div>}
                        </div>
                    </div>
                    {isNorway && <>
                        <div style={{ fontSize: '14px', marginTop: '34px', textAlign: 'center', fontWeight: 500 }}>
                            {isNorway ? 'Hvordan vi sjekker at mobilen er i god stand' : 'Hur vi testar att mobilen är i gott skick'}
                        </div>
                        <div style={{ marginTop: '13px' }}>
                            <YouTube videoId={state.InsuranceTestVideoUrl} opts={opts} onReady={_onReady} />
                        </div>
                    </>}
                    <LargeButton disabled={state.buzy} style={{ marginTop: '19px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ StartOrder: true }); }}>{isNorway ? 'Bestill forsikring for ' + state.InsurancePrice : 'Beställ försäkring för ' + state.InsurancePrice}</LargeButton>
                </div>
            </div>
        }
        {step === 2 && /*Get IMEI*/
            <div style={{ padding: '0px 20px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', padding: '0px 17px' }}>
                <div style={{ fontSize: '17px', marginTop: '13px', textAlign: 'center', fontWeight: 700 }}>
                    {isNorway ? 'Skriv inn IMEI-nummeret til mobilen' : 'Skriv in mobilens IMEI-nummer'}
                </div>
                <div style={{ fontSize: '14px', marginTop: '18px', textAlign: 'left', fontWeight: 400, maxWidth: '350px' }}>
                    {isNorway ? 'Dette er mobilen sitt eget indentifikasjonsnummer. Vi trenger det for å vite hvilken mobil vi forsikrer.' : 'IMEI-numret är mobilens ID-nummer. Vi behöver det för att veta vilken mobiltelefon det är som ska försäkras.'}
                </div>
                <LargeButton variant="outlined" color="secondary" style={{ marginTop: '20px' }} onClick={handleClick}>{isNorway ? 'Hvordan finner jeg IMEI-nummer?' : 'Hur hittar jag IMEI-nummer?'}</LargeButton>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <StyledMenuItem onClick={() => { setVideoUrl(state.InsuranceFindIMEIiPhoneUrl); setVideOpen(true); setAnchorEl(null); attemptPlay(); }}>
                        <ListItemIcon>
                            <img src={AppleLogo} style={{ width: '14px' }} alt="" />
                        </ListItemIcon>
                        <ListItemText primary="Apple" />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => { setVideoUrl(state.InsuranceFindIMEIAndroidUrl); setVideOpen(true); setAnchorEl(null); attemptPlay(); }}>
                        <ListItemIcon>
                            <img src={AndroidLogo} style={{ width: '18px' }} alt="" />
                        </ListItemIcon>
                        <ListItemText primary="Android" />
                    </StyledMenuItem>
                </StyledMenu>
                <div style={{ marginTop: '40px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', textAlign: 'left' }}>
                    <div style={{ fontSize: '18px', fontWeight: 700 }}>
                        {state.Name}
                    </div>
                    <div style={{ fontSize: '14px' }}>
                        {state.MSISDN}
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <TextField
                            fullWidth
                            disabled={state.buzy}
                            label={'IMEI'}
                            id="IMEI"
                            name="IMEI"
                            maxLength={15}
                            localState={state}
                            setLocalState={setState}
                            placeholder="012345678900000"
                            noPadding
                            inputPropsStyle={{ backgroundColor: '#FFF' }}
                            onpaste={handlePaste}
                        />
                    </div>
                </div>
                <LargeButton disabled={(!state.imeiOK) || state.buzy} style={{ marginTop: '19px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ RegisterIMEI: state.IMEI }); }}>{isNorway ? 'Neste' : 'Fortsätt'}</LargeButton>
            </div>
        }
        {step === 3 && /*Accept Terms and order*/
            <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                <div style={{ marginTop: '10px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', minWidth: '280px', textAlign: 'center' }}>
                    <div style={{ fontSize: '18px', textAlign: 'center', fontWeight: 700 }}>
                        {state.Name}
                    </div>
                    <Grid container justifyContent="space-between" style={{ marginTop: '15px' }}>
                        <Grid item>
                            {isNorway ? 'Merke:' : 'Märke:'}
                        </Grid>
                        <Grid item>
                            {state.Brand}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            Modell:
                        </Grid>
                        <Grid item>
                            {state.MarketName}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            IMEI:
                        </Grid>
                        <Grid item>
                            {state.IMEI}
                        </Grid>
                    </Grid>
                </div>
                <div style={{ marginTop: '29px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', minWidth: '280px', textAlign: 'center' }}>
                    <div style={{ fontSize: '20px', textAlign: 'center', fontWeight: 700 }}>
                        {isNorway ? 'Forsikring' : 'Försäkring'}
                    </div>
                    <Grid container justifyContent="space-between" style={{ marginTop: '15px' }}>
                        <Grid item>
                            {isNorway ? 'Gyldig til:' : 'Giltighetstid:'}
                        </Grid>
                        <Grid item>
                            {isNorway ? 'Oppsigelse' : '1 mån uppsägning'}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            {isNorway ? 'Første måned' : 'Första månaden'}
                        </Grid>
                        <Grid item>
                            {isNorway ? '0,-/mnd' : '0 kr/mån'}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            {state.Brand} {state.MarketName}
                        </Grid>
                        <Grid item>
                            {state.InsurancePrice}
                        </Grid>
                    </Grid>
                </div>
                <div style={{ padding: '15px 17px', width: '100%', maxWidth: '350px', minWidth: '280px', textAlign: 'center' }}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            {isNorway ? 'Egenandel ved skade' : 'Självrisk vid skada'}
                        </Grid>
                        <Grid item>
                            {isNorway ? '500,-' : '500 kr'}
                        </Grid>
                    </Grid>
                    <FormControlLabel
                        id="termsAccepted"
                        control={
                            <Checkbox
                                disableRipple
                                checkedIcon={<CheckBoxIcon color="primary" />}
                                icon={<CheckBoxOutlineBlank color="primary" />}
                                disabled={state.Disable}
                                checked={state.termsAccepted}
                                onChange={handleChangeTerms}
                                color="primary"
                                name="termsAccepted"
                            />
                        }
                        label=
                        {
                            <div style={{ fontSize: '12px', marginBottom: '-40px', textAlign: 'left' }} className="noselect">
                                {isNorway ? <div>Ja, jeg har lest og akseptert <Link color="primary" target="_blank" rel="noopener" href={state.InsuranceTermsUrl}>vilkårene for skadeforsikring fra Chilimobil</Link>. Beløpet blir lagt til på din månedlige mobilfaktura.</div> : <div>Ja, jag har tagit del av och accepterar <Link color="primary" target="_blank" rel="noopener" href={state.OurTermsUrl}>villkoren hos Chilimobil</Link> samt  <Link color="primary" target="_blank" rel="noopener" href={state.InsuranceTermsUrl}>villkoren hos Berkley Nordic</Link>. Betalning sker via din faktura från Chilimobil.</div>}
                            </div>
                        }
                    />
                </div>
                <LargeButton disabled={state.buzy || !state.termsAccepted} style={{ marginTop: '29px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ ConfirmOrder: true }); }}>{isNorway ? 'Godkjenn' : 'Godkänn'}</LargeButton>
                <div style={{ fontSize: '10px', textAlign: 'center', marginTop: '23px' }}>
                    <div>{isNorway ? 'Forskring i samarbeid med Berkley Nordic' : 'Försäkring i samarbete med Berkley Nordic'}</div>
                </div>
            </div>
        }
        {step === 4 && /*Start test*/
            <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                <div style={{ fontSize: '17px', marginTop: '18px', textAlign: 'left', fontWeight: 700, padding: '0px 34px' }}>
                    {isNorway ? 'Nå trenger vi raskt å sjekke at din mobil er i god stand.' : 'Nu skall vi testa så att din mobil är i gott skick.'}
                </div>
                <div style={{ marginTop: '10px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', minWidth: '280px', textAlign: 'center' }}>
                    <div style={{ fontSize: '18px', textAlign: 'center', fontWeight: 700 }}>
                        {state.Name}
                    </div>
                    <Grid container justifyContent="space-between" style={{ marginTop: '15px' }}>
                        <Grid item>
                            {isNorway ? 'Merke:' : 'Märke:'}
                        </Grid>
                        <Grid item>
                            {state.Brand}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            Modell:
                        </Grid>
                        <Grid item>
                            {state.MarketName}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            IMEI:
                        </Grid>
                        <Grid item>
                            {state.IMEI}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            Mobilnummer:
                        </Grid>
                        <Grid item>
                            {state.MSISDN}
                        </Grid>
                    </Grid>
                </div>
                {wDim.phone && !state.IsAdministrating ?
                    <>
                        <LargeButton style={{ marginTop: '30px' }} href={state.InsuranceStartTestUrl} target="_blank" rel="noopener" onClick={() => props.handleClose()}>{isNorway ? 'Gjør sjekk av mobilen' : 'Gör TEST av din mobil'}</LargeButton>
                        <div style={{ fontSize: '16px', textAlign: 'left', marginTop: '28px', maxWidth: '350px', padding: '0px 17px' }}>
                            {isNorway ? 'Det er også sendt en SMS til ditt nummer. Du kan bruke den SMS-en til å fullføre testen av din mobil.' : 'Vi skickar dig också ett sms till ditt nummer. Du kan använda det för att fullgöra testet av din mobil.'}
                        </div>
                    </>
                    :
                    <>
                        <div style={{ fontSize: '17px', marginTop: '25px', fontWeight: 700, textAlign: 'left', maxWidth: '500px', padding: '0px 34px' }}>
                            {isNorway ? 'Fullfør sjekken på mobilen som skal forsikres. Det er sendt en SMS til nummeret over.' : 'Vi skickar ett sms till numret ovan, vilket du skall använda för att fullgöra testet av din mobil.'}
                        </div>
                    </>
                }
                <LargeButton color="secondary" variant="outlined" style={{ marginTop: '30px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ CancelOrder: true }); }}>{isNorway ? 'Avbestill forsikring' : 'Avbeställ försäkring'}</LargeButton>
                <div style={{ fontSize: '10px', textAlign: 'center', marginTop: '23px' }}>
                    <div>{isNorway ? 'Forskring i samarbeid med Berkley Nordic' : 'Försäkring i samarbete med Berkley Nordic'}</div>
                </div>
            </div>
        }
        {
            step === 5 && /*Test result*/
            <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <InsuranceTestResult state={state} setState={setState} vm={vm} />
                </div>
            </div>
        }
        {step === 10 && /*Use insurance*/
            <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                <div style={{ fontSize: '17px', marginTop: '18px', textAlign: 'left', fontWeight: 700, padding: '0px 34px' }}>
                    {isNorway ? 'Er uhellet ute? Bruk din forsikring' : 'Är olyckan framme? Använd din försäkring'}
                </div>
                <div style={{ marginTop: '10px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', minWidth: '280px', textAlign: 'left' }}>
                    <div style={{ fontSize: '17px', textAlign: 'left', fontWeight: 700 }}>
                        {state.Name}
                    </div>
                    <div style={{ fontSize: '15px' }}>
                        {state.MarketName}
                    </div>
                    <div style={{ fontSize: '13px' }}>
                        {state.MSISDN}
                    </div>
                </div>
                <div style={{ fontSize: '14px', marginTop: '50px' }}>
                    {isNorway ? 'På neste side må du skrive inn Forsikringsnummeret ditt og e-posten din. Kopier dem før du går videre.' : 'På nästa sida skall du skriva in ditt försäkringsnummer och din mailadress. Kopiera dem gärna innan du går vidare.'}
                </div>
                <div style={{ marginTop: '15px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', minWidth: '280px', textAlign: 'center' }}>
                    <div style={{ fontSize: '22px', fontWeight: 700 }}>{isNorway ? 'Forsikringsnummer' : 'Försäkringsnummer'}</div>
                    <BlackTooltip
                        PopperProps={{
                            disablePortal: true,
                        }}

                        open={IMEITipOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={isNorway ? "Forsikringsnummer kopiert" : "Försäkringsnummer kopierat"}
                        placement="top"
                        arrow
                    >
                        <div onClick={() => { navigator.clipboard.writeText(state.InsuranceNo); copySuccess('IMEI'); }} style={{ cursor: 'pointer', borderRadius: '10px', textAlign: 'center', fontSize: '15px' }}>
                            <Grid container direction="row" alignItems="center" justifyContent="center">
                                <div>{state.InsuranceNo}</div><div style={{ marginLeft: '10px', marginRight: '-30px' }}><Icon name="copy-content" color={theme.palette.primary.main} size={24} /></div>
                            </Grid>
                        </div>
                    </BlackTooltip>
                </div>
                <div style={{ marginTop: '15px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', minWidth: '280px', textAlign: 'center' }}>
                    <div style={{ fontSize: '22px', fontWeight: 700 }}>{isNorway ? 'E-post:' : 'MAIL:'}</div>
                    <BlackTooltip
                        PopperProps={{
                            disablePortal: true,
                        }}

                        open={EmailTipOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={isNorway ? "E-post kopiert" : "MAIL kopierad"}
                        placement="top"
                        arrow
                    >
                        <div onClick={() => { navigator.clipboard.writeText(state.Email); copySuccess('Email'); }} style={{ cursor: 'pointer', borderRadius: '10px', textAlign: 'center', fontSize: '15px' }}>
                            <Grid container direction="row" alignItems="center" justifyContent="center">

                                <div>{state.Email}</div><div style={{ marginLeft: '10px', marginRight: '-30px' }}><Icon name="copy-content" color={theme.palette.primary.main} size={24} /></div>
                            </Grid>
                        </div>
                    </BlackTooltip>
                </div>

                <LargeButton disabled={state.buzy} style={{ marginTop: '19px' }} href={state.InsuranceReportClaimUrl} target="_blank" rel="noopener" onClick={() => { props.handleClose(); }}>{isNorway ? 'Bruk forsikring' : 'Använd försäkringen'}</LargeButton>

            </div>
        }
        {
            step === 99 && /*Insurance active*/
            <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                <img src={InsuranceImg} style={{ margin: '0px', width: '100%' }} alt="" />
                <div style={{ padding: '0px 20px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start', alignItems: 'center', padding: '0px 17px' }}>
                    <div style={{ fontSize: '16px', marginTop: '18px', textAlign: 'center', fontWeight: 300 }}>
                        {isNorway ? 'Dine forskringer' : 'Dina försäkringar'}
                    </div>
                    <div style={{ marginTop: '10px', padding: '15px 17px', backgroundColor: theme.palette.boxBackground.main, borderRadius: '10px', width: '100%', maxWidth: '350px', minWidth: '280px', textAlign: 'center' }}>
                        <div style={{ fontSize: '17px', textAlign: 'left', fontWeight: 700 }}>
                            {state.Name}
                        </div>
                        <div style={{ fontSize: '15px', textAlign: 'left' }}>
                            {state.Brand} {state.MarketName}
                        </div>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                {state.MSISDN}
                            </Grid>
                            <Grid item>
                                {state.InsurancePrice}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <SmallButton disabled={state.buzy} style={{ marginTop: '19px' }} onClick={() => { setStep(10); }}>{isNorway ? 'Bruk forsikring' : 'Använd försäkringen'}</SmallButton>
                            </Grid>
                            <Grid item>
                                <SmallButton variant="outlined" color="secondary" disabled={state.buzy} style={{ marginTop: '19px' }} onClick={() => { setState({ buzy: true }); vm.$dispatch({ CancelService: true }); }}>{isNorway ? 'Avbestill' : 'Avbeställ'}</SmallButton>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ fontSize: '10px', textAlign: 'center', marginTop: '13px' }}>
                        <div>{isNorway ? 'Forskring i samarbeid med Berkley Nordic' : 'Försäkring i samarbete med Berkley Nordic'}</div>
                    </div>
                </div>
            </div>
        }
    </>;
}