import React from "react"
import useWindowDimensions from "../hooks/useWindowDimensions"
import Grid from "@mui/material/Grid"
import { useTheme } from "@mui/material/styles"
import ReactLoading from "react-loading"
import SmallDataMeter from "./components/SmallDataMeter"
import { SmallButton } from "./components/Buttons"
import { Link } from "react-tiger-transition"

export const SubscriptionBox = ({
  subscription,
  isAdmin,
  isNorway,
  main,
  inverse,
  fullWidth
}) => {
  const {
    SubscriptionNo,
    UserName,
    Msisdn,
    PackageName,
    PackageDescription,
    PackagePrice,
    Pot,
    SaldoLabel,
    SaldoText,
    LockTo,
    LockToPassed,
    Color
  } = subscription ?? {}
  const wDim = useWindowDimensions()
  const theme = useTheme()
  const width = fullWidth ? "100%" : wDim.desktop ? "47%" : "100%"
  const navigateTo = subscriptionNo => {
    var e = document.getElementById("administrate-" + subscriptionNo)
    if (e) e.click()
  }

  const centered = {
    display: "grid",
    placeItems: "center"
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        role="button"
        item
        style={{
          position: "relative",
          minHeight: "140px",
          fontWeight: "300",
          width,
          marginBottom: "1rem",
          borderRadius: "10px",
          backgroundColor: main ? theme.palette.primary.main : Color,
          color: inverse
            ? theme.palette.common.white
            : theme.palette.text.primary,
          boxShadow: inverse ? "0px 2px 12px #00000029" : "none",
          cursor: isAdmin ? "pointer" : "auto"
        }}
        onClick={
          isAdmin
            ? () => {
                navigateTo(SubscriptionNo)
              }
            : null
        }
      >
        <p
          style={{
            position: "absolute",
            top: "0.5rem",
            margin: 0,
            width: "100%",
            textAlign: "center",
            fontWeight: "400"
          }}
        >
          {UserName}
        </p>

        <Grid
          container
          justifyContent="flex-end"
          direction="column"
          style={{ width: "calc(100%/3)" }}
        >
          <div style={{ height: "calc(100%/3)", ...centered }}>
            {isNorway ? (
              <div style={centered}>
                <p
                  style={{
                    fontWeight: 400,
                    margin: "0 0 .125rem 0",
                    lineHeight: "100%"
                  }}
                >
                  {Msisdn}
                </p>
                <p
                  style={{
                    fontFamily: theme.typography.fontFamily2,
                    margin: 0,
                    lineHeight: "100%"
                  }}
                >
                  {PackageDescription}
                </p>
              </div>
            ) : (
              <p style={{ margin: 0, fontWeight: 400 }}>{Msisdn}</p>
            )}
          </div>
          <div style={{ height: "calc(100%/3)", ...centered }}>
            <SmallButton
              id={"administrate-" + SubscriptionNo}
              component={Link}
              to={
                "/subscriptions/subscription?subscriptionno=" + SubscriptionNo
              }
              transition="glide-left"
              style={{
                color: isAdmin
                  ? inverse
                    ? theme.palette.primary.main
                    : theme.palette.common.white
                  : "#cbb",
                WebkitTextFillColor: isAdmin
                  ? inverse
                    ? theme.palette.primary.main
                    : theme.palette.common.white
                  : "#cbb",
                backgroundColor: isAdmin
                  ? inverse
                    ? theme.palette.common.white
                    : theme.palette.primary.main
                  : "#eee"
              }}
              disabled={!isAdmin}
            >
              {isNorway ? "Administrer" : "Hantera"}
            </SmallButton>
          </div>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ width: "calc(100%/3)" }}
        >
          <SmallDataMeter inverse={inverse} Pot={Pot} />
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent={isNorway ? "space-around" : "flex-end"}
          style={{ width: "calc(100%/3)" }}
        >
          {isNorway && (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ height: "calc(100%/3)" }}
            >
              <div style={centered}>
                <p
                  style={{
                    fontWeight: 400,
                    margin: "0 0 .125rem 0",
                    lineHeight: "100%"
                  }}
                >
                  {SaldoLabel}
                </p>
                {SaldoText ? (
                  <p
                    style={{
                      fontFamily: theme.typography.fontFamily2,
                      margin: 0,
                      lineHeight: "100%"
                    }}
                  >
                    {SaldoText}
                  </p>
                ) : (
                  <ReactLoading
                    type="bubbles"
                    color={
                      inverse
                        ? theme.palette.common.white
                        : theme.palette.primary.main
                    }
                    height={20}
                    width={32}
                  />
                )}
              </div>
            </Grid>
          )}
          {!isNorway && (
            <>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ height: "calc(100%/3)" }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    margin: "0 0 .125rem 0",
                    lineHeight: "100%"
                  }}
                >
                  {PackageName}
                </p>
                <p
                  style={{
                    fontFamily: theme.typography.fontFamily2,
                    margin: 0,
                    lineHeight: "100%"
                  }}
                >
                  {PackagePrice}
                </p>
              </Grid>
              <div
                style={{
                  margin: 0,
                  height: "calc(100%/3)",
                  fontSize: "10px",
                  textAlign: "center",
                  ...centered
                }}
              >
                <p>
                  Bindningstid:{" "}
                  <span style={{ whiteSpace: "nowrap" }}>
                    {LockToPassed || !LockTo ? "Ingen" : LockTo.split("T")[0]}
                  </span>
                </p>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}
