import React, { useState, useEffect } from "react";
import produce from 'immer';
import auth from "../auth";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import useWindowDimensions from "../hooks/useWindowDimensions";
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Icon from "../components/Selfcare/Common/Icon";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Grid, Divider } from '@mui/material';
import { LargeButton, SmallButton } from '../controls/components/Buttons';

export default function ChangePaymentMethod(props) {
    const wDim = useWindowDimensions();
    const { t } = useTranslation();
    const { vm, state, setState } = useConnect("ChangePaymentMethod", {}, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });
    useEffect(() => {
        if (state.pInfo) {
            gstate.paymentInfo.set(state.pInfo);
        }
        if (state.GoNets)
            window.location.assign(state.GoNets);
    }, [state.GoNets]);
    const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, termsAccepted: false, msisdn: '', amount: '' });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    const columns = [
        { id: 'msisdn', label: 'Mobilnummer', minWidth: 100 },
        { id: 'description', label: 'Abonnemang', minWidth: 170 },
        { id: 'paymentMethod', label: 'Betalningsform', minWidth: 60 },
    ];
    const mobColumns = [
        { id: 'mobDescription', label: 'Abonnemang', minWidth: 170 },
        { id: 'paymentMethod', label: 'Betalningsform', minWidth: 60 },
    ];
    const paymentMethod = state.PaymentMethod;
    return (
        <>
            <ServerState serverState={state} localState={localState} setLocalState={setLocalState} handleClose={props.handleClose} handleRefresh={props.handleRefresh} />
            {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
            <div style={{ height: '100vh' }}>
                <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            <Icon name="invoice" size={48} color="#FFF" />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px', marginBottom: '10px' }}>
                                {state.SingleSubscription ?
                                    'Byt betalningsform på mitt abonnemang'
                                    :
                                    'Byt betalningsform på mina abonnemang'
                                }
                            </div>
                            <div style={{ fontSize: '14px' }}>
                                {state.SingleSubscription ?
                                    'Här ser du abonnemanget som du har hos oss idag. Detta abonnemang kan du antingen betala med faktura (epost-faktura, autogiro) eller med betalkort.'
                                    :
                                    'Här ser du samtliga abonnemang som du har hos oss idag. Dessa abonnemang kan du antingen betala med faktura (epost-faktura, autogiro) eller med betalkort.'
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10} style={{ padding: '20px' }} >
                            <div style={{ marginBottom: '20px' }}>
                                {state.SingleSubscription ?
                                    <b>Ditt abonnemang</b>
                                    :
                                    <b>Dina abonnemang</b>
                                }
                            </div>
                            {wDim.desktop ?
                                <Paper >
                                    <TableContainer>
                                        <Table stickyHeader aria-label="sticky tabell" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {state.Subscriptions && state.Subscriptions.map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.key}>
                                                            {columns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                                :
                                <Paper>
                                    <TableContainer>
                                        <Table stickyHeader aria-label="sticky tabell" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    {mobColumns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {state.Subscriptions && state.Subscriptions.map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.key}>
                                                            {mobColumns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number' ? column.format(value) : <span dangerouslySetInnerHTML={{ __html: value }} />}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            }
                            <div style={{ marginTop: '20px' }}>
                                <b>{state.SingleSubscription ?
                                    'Jag väljer att mitt abonnemang i fortsättningen skall betalas med:'
                                    :
                                    'Jag väljer att samtliga abonnemang i fortsättningen skall betalas med:'
                                }</b>
                            </div>
                            <FormControl error={state.ErrorField === 'SelectedPaymentMethod'}>
                                <FormGroup>
                                    {(paymentMethod === 'creditcard' || paymentMethod === 'mixed') &&
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={state.SelectedPaymentMethod === 'invoice'}
                                                        onChange={() => { setState({ SelectedPaymentMethod: 'invoice' }); vm.$dispatch({ ErrorField: '' }); }}
                                                    value='ïnvoice'
                                                    color="primary"
                                                        disabled={state.DisableButtons}
                                                    />
                                                }
                                                label={<span style={{ fontSize: '14px' }}>{state.CreditCheck ? "Faktura (vid val av faktura sker en kreditkontroll)" : "Faktura"}</span>}
                                                labelPlacement="end"
                                            />
                                        </div>}
                                    {(paymentMethod === 'invoice' || paymentMethod === 'mixed') &&
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={state.SelectedPaymentMethod === 'creditcard'}
                                                        onChange={() => { setState({ SelectedPaymentMethod: 'creditcard' }); vm.$dispatch({ ErrorField: '' }); }}
                                                    value='creditcard'
                                                    color="primary"
                                                        disabled={state.DisableButtons}
                                                    />
                                                }
                                                label={<span style={{ fontSize: '14px' }}>Betalkort</span>}
                                                labelPlacement="end"
                                            />
                                        </div>}
                                </FormGroup>
                                <FormHelperText>{state.ErrorField === 'SelectedPaymentMethod' && state.ErrorText}</FormHelperText>
                            </FormControl>
                            {state.SelectedPaymentMethod === 'invoice' && state.CreditCheck &&
                                <div style={{ marginTop: '10px' }}>
                                    <b>I samband med byte av betalmedod till faktura utför vi en kreditkontroll</b>
                                </div>}
                            {state.SelectedPaymentMethod === 'creditcard' &&
                                <div style={{ marginTop: '10px' }}>
                                    <b>Du kommer att omdirigeras till Nets, där du får ange ditt kortnummer.<br />Kortet kommer att debiteras med {state.MonthlyAmount}.</b>
                                </div>}
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <LargeButton onClick={() => { vm.$dispatch({ PerformChange: state.SelectedPaymentMethod }); setLocalState({ buzy: true }); }} disabled={state.DisableButtons || state.buzy} style={{ marginRight: '10px' }}>
                                    Byt betalmetod
                                </LargeButton>
                                <LargeButton id='cancel' variant="text" color="secondary" disabled={state.buzy} onClick={() => { props.handleClose(); }}>
                                    {t('selfcare:button.cancel')}
                                </LargeButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}