//import 'bootstrap/dist/css/bootstrap.css';
import './styles/app.css';
import './styles/tabs.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import theme from './theme';

const MySwal = withReactContent(Swal);

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const updateServiceWorker = (reg) => {
    const registrationWaiting = reg.waiting;
    if (registrationWaiting) {
        registrationWaiting.addEventListener('statechange', e => {
            if (e.target.state === 'activated') {
                window.location.reload();
            }
        });
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
    }
};
ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </BrowserRouter>,
    rootElement);

let config = {
    source: 'Selfcare',
    onSuccess: function (registration) {
        console.log('index.js - Service Worker initialized', registration);
        if (sessionStorage.getItem("UpgradedVersion") !== "Yes")
            window.location.reload();
    },
    onUpdate: function (registration) {
        console.log('index.js  - Service Wroker new version available', registration);
        var title, message, buttonText;
        switch (process.env.REACT_APP_DEFAULTLOCALE.toLowerCase()) {
            case "nb-no":
                title = "Ny versjon";
                message = "Ny versjon installert.";
                buttonText = "Ok";
                break;
            case "sv-se":
                title = "Ny version";
                message = "Ny version installerad.";
                buttonText = "Ok";
                break;
            default:
                title = "Ny versjon";
                message = "Ny versjon installert.";
                buttonText = "Ok";
                break;
        }
        setTimeout(
            function () {
                MySwal.fire({
                    title: title,
                    html: message,
                    icon: 'info',
                    confirmButtonText: buttonText,
                    confirmButtonColor: theme.palette.primary.main,
                }).then((result) => {
                    updateServiceWorker(registration);
                });
            },
            10
        );
    }
};


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(config);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    window.document.addEventListener('touchmove', e => {
        if (e.scale !== 1) {
            e.preventDefault();
        }
    }, { passive: false });
}
