import React, { useState, useEffect, useRef } from "react";
import auth from "../auth";
import Version from "../version";
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import PageContainer from '../controls/PageContainer';
import ServerState from '../controls/ServerState';
import { Grid, Divider, Modal, Typography, LinearProgress } from "@mui/material";
import { Box } from "../controls/Box";
import Tooltip from '@mui/material/Tooltip';
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import SparkleDialog from '../controls/components/SparkleDialog';
import * as rdd from 'react-device-detect';

const BlackTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 11,
        fontFamily: 'Arial',
    },
    arrow: {
        color: theme.palette.common.black,
    }
}))(Tooltip);


export default function Invoice() {
    const { t } = useTranslation();
    const getParameterByName = (name) => {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };
    const { vm, state } = useConnect("Invoice", { PdfBuzy: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            InvoiceNo: getParameterByName('invoiceNo'),
        },
        exceptionHandler: _ => auth.signOut()
    });
    const dialogRef = useRef();
    const contentRef = useRef();
    useEffect(() => {
        if (state.InvoiceDetail !== undefined && state.InvoiceDetail !== null) {
            setTimeout(
                function () {
                    contentRef.current.reDraw();
                },
                10
            );
        }
    }, [state.InvoiceDetail])
    const theme = useTheme();
    const [AccountTipOpen, setAccountTipOpen] = useState(false);
    const [OcrTipOpen, setOcrTipOpen] = useState(false);
    const copySuccess = (type) => {
        if (type === 'ocr') {
            setOcrTipOpen(true);
        } else {
            setAccountTipOpen(true);
        }
        setTimeout(
            function () {
                if (type === 'ocr') {
                    setOcrTipOpen(false);
                } else {
                    setAccountTipOpen(false);
                }
            },
            2000
        );
    };
    //const wDim = useWindowDimensions();
    const helpText = state.IsSweden ? 'Tryck på OCR- och bankgironummer för att kopiera' : 'Trykk på KID- og kontonummeret for å kopiere det';
    let hasDueAmount = false;
    if (state.InvoiceDetail?.DueAmount) {
        hasDueAmount = true;
    }
    return (
        <>
            <ServerState serverState={state} />
            <SparkleDialog ref={dialogRef} />
            <Modal
                aria-labelledby={t('selfcare:label.loadpdf')}
                aria-describedby={t('selfcare:label.downloadpdf')}
                open={state.PdfBuzy}
            >
                <div style={{ width: '300px', top: '50%', left: '50%', transform: `translate(-50%, -50%)`, position: 'absolute', backgroundColor: theme.palette.background.paper, boxShadow: theme.shadows[5], padding: theme.spacing(2), outline: 'none' }}>
                    <Typography id="modal-title">
                        {state.PdfBuzyText}
                    </Typography>
                    <LinearProgress color="primary" variant="query" />
                </div>
            </Modal>
            <PageContainer ref={contentRef} back title={state.InvoiceDetail?.Month + ' - ' + state.InvoiceDetail?.Year} id={'invoice-' + getParameterByName('invoiceNo')}>
                <div style={{ marginTop: '19px', width: '100%' }}>
                    {(state.IsNorway || (state.IsSweden && state.InvoiceDetail?.OcrNo)) && <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '12px', width: '100%', textAlign: 'center' }}><div style={{ fontSize: '11px', color: '#969696', marginLeft: '-260px', transform: 'matrix(0.87, -0.5, 0.5, 0.87, 0, 0)' }}>Tips!</div>{helpText}</div>}
                    <Box background fullWidth>
                        <div style={{ fontSize: '12px', width: '100%' }}>
                            {state.IsNorway && state.InvoiceDetail?.InDebtCollection &&
                                <Grid container direction="column" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
                                    <div style={{ fontSize: '24px', marginBottom: '17px' }}>
                                        Inkassosak
                                    </div>
                                    <div>
                                        OBS! Denne fakturaen ble sendt ut: {state.InvoiceDetail?.InvoiceDate}. Den har dessverre stått ubetalt så lenge at den er blitt oversendt til inkasso. Bruk betal-knappen under for å bli sendt direkte til inkassosaken.
                                    </div>
                                    <LargeButton color="primary" onClick={() => { dialogRef.current.openUrl(state.InvoiceDetail?.DebtCollectionUrl, true); }} style={{ marginBottom: '17px', marginTop: '17px' }}>Betal</LargeButton>
                                    <Divider style={{ width: '100%', marginBottom: '1px', marginTop: '1px' }} />
                                    <div style={{ fontSize: '24px', marginTop: '17px', marginBottom: '17px' }}>
                                        Original faktura
                                    </div>
                                    <div style={{ marginBottom: '17px' }}>
                                        NB! Inkassaken over må betales. Original faktura er ikke lenger gyldig betalingsmåte, fordi denne har vært ubetalt over flere forfall.
                                    </div>
                                </Grid>
                            }
                            {state.IsNorway && state.InvoiceDetail?.DaysOverDue && <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '17px', width: '100%' }}>
                                <div style={{ fontWeight: 300 }}>Dager siden forfall</div>
                                <div style={{ color: '#FF0000', fontSize: '14px' }}>{state.InvoiceDetail?.DaysOverDue}</div>
                            </Grid>}
                            {state.IsNorway && <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '7px', width: '100%' }}>
                                <div style={{ fontWeight: 300 }}>Forfallsdato</div>
                                <div>{state.InvoiceDetail?.DueDate}</div>
                            </Grid>}
                            {state.IsSweden &&
                                <>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '7px', width: '100%' }}>
                                        <div style={{ fontWeight: 300 }}>Fakturanummer</div>
                                        <div>{state.InvoiceDetail?.InvoiceNo}</div>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '7px', width: '100%' }}>
                                        <div style={{ fontWeight: 300 }}>Fakturadatum</div>
                                        <div>{state.InvoiceDetail?.InvoiceDate}</div>
                                    </Grid>
                                </>
                            }
                            {(state.IsNorway || (state.IsSweden && state.InvoiceDetail?.OcrNo)) &&
                                <>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '7px', width: '100%' }}>
                                        <div style={{ fontWeight: 300 }}>{state.IsSweden ? 'Bankgironummer' : 'Kontonummer'}</div>
                                        <BlackTooltip
                                            PopperProps={{
                                                disablePortal: true,
                                            }}

                                            open={AccountTipOpen}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={state.IsSweden ? "Bankgironummer kopierat" : "Kontonummer kopiert"}
                                            placement="top"
                                            arrow
                                        >
                                            <div onClick={() => { navigator.clipboard.writeText(state.InvoiceDetail?.AccountNo); copySuccess('account'); }} style={{ cursor: 'pointer', borderRadius: '10px', padding: '0px 7px', backgroundColor: '#E5E5E57B', marginRight: '-8px', textAlign: 'right' }}>
                                                {state.InvoiceDetail?.AccountNo}
                                            </div>
                                        </BlackTooltip>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '17px', width: '100%' }}>
                                        <div style={{ fontWeight: 300 }}>{state.IsSweden ? "OCR-nummer" : "KID-nummer"}</div>
                                        <BlackTooltip
                                            PopperProps={{
                                                disablePortal: true,
                                            }}

                                            open={OcrTipOpen}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={state.IsSweden ? "OCR-nummer kopierat" : "KID-nummer kopiert"}
                                            placement="top"
                                            arrow
                                        >
                                            <div onClick={() => { navigator.clipboard.writeText(state.InvoiceDetail?.OcrNo); copySuccess('ocr'); }} style={{ cursor: 'pointer', borderRadius: '10px', padding: '0px 7px', backgroundColor: '#E5E5E57B', marginRight: '-8px', textAlign: 'right' }}>
                                                {state.InvoiceDetail?.OcrNo}
                                            </div>
                                        </BlackTooltip>
                                    </Grid>
                                </>
                            }
                            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '7px', width: '100%' }}>
                                <div style={{ fontWeight: 300 }}>{state.IsNorway ? 'Beløp' : 'Totalbelopp'}</div>
                                <div style={{ fontSize: hasDueAmount ? '12px' : '18px' }}>{state.InvoiceDetail?.Amount}</div>
                            </Grid>
                            {state.InvoiceDetail?.DueAmount &&
                                <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '7px', width: '100%' }}>
                                    <div style={{ fontWeight: 300 }}>{state.IsNorway ? 'Gjenstår' : 'Kvar att betala'}</div>
                                    <div style={{ fontSize: '18px' }}>{state.InvoiceDetail?.DueAmount}</div>
                                </Grid>
                            }
                            <Grid container direction="row" justifyContent="space-between" alignItems="baseline" style={{ marginBottom: '7px', width: '100%' }}>
                                <SmallButton onClick={() => {
                                    var uri = window.location.protocol + '//' + window.location.host;
                                    vm.$dispatch({ GetInvoicePdf: { InvoiceNo: state.InvoiceNo, ReturnType: 'url' } });
                                    //dialogRef.current.openUrl((!rdd.isAndroid ? 'https://docs.google.com/viewer?url=' + uri : '') + `/api/document/pdf/${state.InvoiceDetail.InvoiceNo}.pdf`, true);
                                    dialogRef.current.openUrl((rdd.isAndroid ? 'https://mozilla.github.io/pdf.js/web/viewer.html?file=' + uri : '') + `/api/document/pdf/${state.InvoiceDetail.InvoiceNo}.pdf`, true);
                                }}>{state.IsNorway ? 'Åpne faktura' : 'Öppna faktura'}</SmallButton>
                                {state.InvoiceDetail?.PaymentURL && <SmallButton href={state.InvoiceDetail?.PaymentURL} rel="noopener noreferrer" target="_blank">Betala</SmallButton>}
                                <div style={{ fontSize: '14px' }}><span style={{ color: state.InvoiceDetail?.PaymentStatusColor }}>{state.InvoiceDetail?.PaymentStatus}</span>{state.InvoiceDetail?.PaymentSubStatus && <><span> - </span><span style={{ color: state.InvoiceDetail?.PaymentSubStatusColor }}>{state.InvoiceDetail?.PaymentSubStatus}</span></>}</div>
                            </Grid>
                            {state.IsNorway &&
                                <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
                                    <SmallButton onClick={() => {
                                        var uri = window.location.protocol + '//' + window.location.host;
                                        vm.$dispatch({ GetSpecificationPdf: { InvoiceNo: state.InvoiceNo, ReturnType: 'url' } });
                                        //dialogRef.current.openUrl((!rdd.isAndroid ? 'https://docs.google.com/viewer?url=' + uri : '') + `/api/document/pdf/${state.InvoiceDetail.InvoiceNo}_spes.pdf`, true);
                                        dialogRef.current.openUrl((rdd.isAndroid ? 'https://mozilla.github.io/pdf.js/web/viewer.html?file=' + uri : '') + `/api/document/pdf/${state.InvoiceDetail.InvoiceNo}_spes.pdf`, true);
                                    }} disabled={!state.InvoiceDetail?.HasSpecification}>{state.IsNorway ? 'Åpne spesifikasjon' : 'Öppna specifikation'}</SmallButton>
                                </Grid>
                            }
                        </div>
                    </Box>
                    {state.IsNorway && <div style={{ fontSize: '12px', fontFamily: theme.typography.fontFamily2, fontWeight: 300, textAlign: 'center', width: '100%', marginTop: '-10px' }}>
                        {state.IsNorway ? 'Detaljert spesifikasjon under «Åpne spesifikasjon» slettes automatisk 2 måneder etter fakturadato.' : 'Detaljerad specifikation under «Öppna specifikation» raderas automatiskt 2 månader efter fakturadatum.'}
                    </div>}
                </div>
            </PageContainer>
        </>
    );
}