import React, { useRef, useState, useEffect } from "react"
import globalState from "../state/GlobalState"
import { useState as useGlobalState } from "@hookstate/core"
import produce from "immer"
import auth from "../auth"
import Version from "../version"
import { useConnect } from "dotnetify"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material/styles"
import PageContainer from "../controls/PageContainer"
import { Grid } from "@mui/material"

import ServerState from "../controls/ServerState"
import { Box, BoxTitle, BoxContent } from "../controls/Box"
import { SmallButton } from "../controls/components/Buttons"
import SparkleDialog from "../controls/components/SparkleDialog"

export default function SelectSimType() {
  const getParameterByName = name => {
    var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search)
    return match && decodeURIComponent(match[1].replace(/\+/g, " "))
  }
  const { vm, state } = useConnect(
    "SelectSimType",
    {},
    {
      headers: {
        Authorization: "Bearer " + auth.getAccessToken(),
        ClientVersion: Version.getVersion(),
        ServerToken: window.sessionStorage.ServerToken
      },
      vmArg: { SubscriptionNo: getParameterByName("subscriptionno") },
      exceptionHandler: _ => auth.signOut()
    }
  )
  const [localState, internalSetLocalState] = useState({
    disabled: false,
    busy: false,
    showBuyDataButton: false,
    dialogOpen: false,
    ViaplayBuzy: false,
    CancelViaplayDialogOpen: false,
    ActivateViaplayDialogOpen: false
  })
  const setLocalState = o => {
    internalSetLocalState(
      produce(draft => {
        draft[Object.keys(o)[0]] = Object.values(o)[0]
      })
    )
  }
  const gstate = useGlobalState(globalState)

  const { t } = useTranslation()
  const theme = useTheme()
  const dialogRef = useRef()
  const handleRefresh = () => {
    vm.$dispatch({ Refresh: true })
  }

  useEffect(() => {
    const callbackDialog = sessionStorage.CallbackDialogRet
    if (callbackDialog) {
      sessionStorage.removeItem("CallbackDialogRet")
      dialogRef.current.showDialog(callbackDialog)
    }
  }, [])

  return (
    <>
      <ServerState
        serverState={state}
        localState={localState}
        setLocalState={setLocalState}
      />
      <SparkleDialog
        ref={dialogRef}
        handleRefresh={handleRefresh}
        subscriptionNo={state.SubscriptionNo}
      />
      <PageContainer
        back
        title={gstate.IsNorway.get() ? "Bestill nytt SIM" : "Beställ nytt SIM"}
        noSelect
        id="SelectSimTyp"
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <div
            style={{
              fontFamily: theme.typography.fontFamily2,
              padding: "35px 17px 20px 17px"
            }}
          >
            {gstate.IsNorway.get()
              ? "Velg hvilket SIM-kort du trenger. Erstatning SIM-kort til mobilen din, eller et nytt SIM-kort til en ekstra enhet?"
              : "Vilket SIM-kort du behöver du? Behöver du ett nytt SIM-kort till mobilen eller en annan enhet?"}
          </div>
          <Box background fullWidth>
            <BoxTitle>
              {gstate.IsNorway.get() ? "Hoved-SIM" : "Huvud-SIM"}
            </BoxTitle>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={8}>
                <BoxContent>
                  {gstate.IsNorway.get()
                    ? "Erstatning SIM-kort. Mistet mobilen din eller trenger du nytt SIM-kort?"
                    : "Ersättnings SIM-kort. Har du förlorat mobilen eller behöver du nytt SIM-kort?"}
                </BoxContent>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "center" }}>
                <div style={{ fontSize: "13px", fontWeight: 400 }}>
                  Pris: {state.SimPrice}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontFamily: theme.typography.fontFamily2
                  }}
                >
                  {gstate.IsNorway.get() ? "Engangssum" : "Engångspris"}
                </div>
              </Grid>
            </Grid>
            <SmallButton
              onClick={() => {
                sessionStorage.returnUrl = state.ReturnUrl
                sessionStorage.simType = "main"
                dialogRef.current.showDialog("orderSimcard")
              }}
            >
              {state.IsNorway ? "Les mer / bestill" : "Läs mer / beställ"}
            </SmallButton>
          </Box>
          {state.HasDataSim && (
            <Box background fullWidth>
              <BoxTitle>
                {gstate.IsNorway.get() ? "Data-SIM" : "Extra-SIM"}
              </BoxTitle>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={8}>
                  <BoxContent>
                    {gstate.IsNorway.get()
                      ? "Erstatning Data-SIM. Mistet enheten din eller trenger du nytt SIM-kort?"
                      : "Ersättnings Extra-SIM. Har du förlorat din enhet eller behöver du nytt SIM-kort?"}
                  </BoxContent>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "center" }}>
                  <div style={{ fontSize: "13px", fontWeight: 400 }}>
                    Pris: {state.SimPrice}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: theme.typography.fontFamily2
                    }}
                  >
                    {gstate.IsNorway.get() ? "Engangssum" : "Engångspris"}
                  </div>
                </Grid>
              </Grid>
              <SmallButton
                onClick={() => {
                  sessionStorage.returnUrl = state.ReturnUrl
                  sessionStorage.simType = "data"
                  dialogRef.current.showDialog("orderSimcard")
                }}
              >
                {state.IsNorway ? "Les mer / bestill" : "Läs mer / beställ"}
              </SmallButton>
            </Box>
          )}
          {state.HasTwinSim && (
            <Box background fullWidth>
              <BoxTitle>Tvilling-SIM</BoxTitle>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={8}>
                  <BoxContent>
                    Erstatning Tvilling-SIM. Mistet enheten din eller trenger du
                    nytt SIM-kort?
                  </BoxContent>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "center" }}>
                  <div style={{ fontSize: "13px", fontWeight: 400 }}>
                    Pris: {state.SimPrice}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: theme.typography.fontFamily2
                    }}
                  >
                    {gstate.IsNorway.get() ? "Engangssum" : "Engångspris"}
                  </div>
                </Grid>
              </Grid>
              <SmallButton
                onClick={() => {
                  sessionStorage.returnUrl = state.ReturnUrl
                  sessionStorage.simType = "twin"
                  dialogRef.current.showDialog("orderSimcard")
                }}
              >
                {state.IsNorway ? "Les mer / bestill" : "Läs mer / beställ"}
              </SmallButton>
            </Box>
          )}
          {state.CanOrderData && (
            <Box disabled={state.IsPaused} background fullWidth>
              <BoxTitle>{state.DataName}</BoxTitle>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={8}>
                  <BoxContent>{state.DataDescription}</BoxContent>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "center" }}>
                  <div style={{ fontSize: "13px", fontWeight: 400 }}>
                    {t("selfcare:item.initialfee")}: {state.DataOneTimePrice}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: theme.typography.fontFamily2
                    }}
                  >
                    {t("selfcare:item.permonth")}: {state.DataMonthlyPrice}
                  </div>
                </Grid>
              </Grid>
              <SmallButton
                disabled={state.IsPaused}
                onClick={() => {
                  sessionStorage.returnUrl = state.ReturnUrl
                  sessionStorage.ServiceNo = "529"
                  dialogRef.current.showDialog("changeService")
                }}
              >
                {state.IsNorway ? "Les mer / bestill" : "Läs mer / beställ"}
              </SmallButton>
            </Box>
          )}
          {state.CanOrderTwin && (
            <Box disabled={state.IsPaused} background fullWidth>
              <BoxTitle>{state.TwinName}</BoxTitle>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={8}>
                  <BoxContent>
                    Ekstra SIM-kort. Del data, tale, SMS og MMS med en annan
                    enhet.
                  </BoxContent>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "center" }}>
                  <div style={{ fontSize: "13px", fontWeight: 400 }}>
                    {t("selfcare:item.initialfee")}: {state.TwinOneTimePrice}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: theme.typography.fontFamily2
                    }}
                  >
                    {t("selfcare:item.permonth")}: {state.TwinMonthlyPrice}
                  </div>
                </Grid>
              </Grid>
              <SmallButton
                disabled={state.IsPaused}
                onClick={() => {
                  sessionStorage.returnUrl = state.ReturnUrl
                  sessionStorage.ServiceNo = "479"
                  dialogRef.current.showDialog("changeService")
                }}
              >
                {state.IsNorway ? "Les mer / bestill" : "Läs mer / beställ"}
              </SmallButton>
            </Box>
          )}
        </Grid>
      </PageContainer>
    </>
  )
}
