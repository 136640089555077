import React, { useState, useEffect } from "react";
import produce from 'immer';
import auth from "../auth";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from "../components/Selfcare/Common/Icon";
import { Grid } from '@mui/material';
import { LargeButton } from '../controls/components/Buttons';

export default function SubscriptionPause(props) {
    const { t } = useTranslation();
    const { vm, state, setState } = useConnect("SubscriptionPause", { ConfirmDialogOpen: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });
    const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, termsAccepted: false, msisdn: '', amount: '' });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    const gstate = useGlobalState(globalState);
    const isNorway = gstate.IsNorway.get();
    const isSweden = !isNorway;
    const theme = useTheme();

    return (
        <>
            <ServerState serverState={state} setState={setState} handleClose={props.handleClose} handleRefresh={props.handleRefresh} />
            <Dialog
                open={state.ConfirmDialogOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        setState({ ConfirmDialogOpen: false, buzy: false });
                    }
                }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <div style={{ display: 'flex' }}>
                        <Icon name="pause-button" size={36} color={theme.palette.primary.main} />
                        <span style={{ marginLeft: '20px', fontWeight: '700' }}>{isNorway ? 'Fryse Hyttebredbånd-abonnementet' : 'Pausa abonnemang'}</span>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {isNorway ? 'Jeg ønsker å fryse mitt Hyttebredbånd. Abonnementet fryses ved førstkommende månedsskifte.' : 'Jag önskar pausa mitt abonnemang. Abonnemanget pausas vid månadsskiftet.'}
                </DialogContent>
                <DialogActions>
                    <LargeButton onClick={() => { vm.$dispatch({ PauseSubscription: true }); setState({ ConfirmDialogOpen: false }) }}>
                        {isNorway ? 'Frys' : 'Pausa mitt abonnemang'}
                    </LargeButton>
                    <LargeButton color="secondary" variant="text" onClick={() => { setState({ ConfirmDialogOpen: false, buzy: false }); }}>
                        {t('selfcare:button.canceluc')}
                    </LargeButton>
                </DialogActions>
            </Dialog>
            {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
            <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10}>
                        <Icon name="pause-button" size={48} color="#FFFFFF" />
                        <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                            {isNorway ? 'Frys Hyttebredbånd' : 'Pausa abonnemang'}
                        </div>
                        <div>
                            {isNorway ? <span>Her kan du fryse ditt Hyttebredbånd. Det er gratis, og abonnementet kan være fryst i opptil 6 måneder. Hvis abonnementet ikke åpnes innen 6 måneder vil det automatisk avsluttes, da må du bestille på nytt hvis du ønsker tjenesten igjen etter denne perioden.<br /><br />Frys aktiveres fra og med neste måned. Abonnementet vil vare ut den påbegynte måneden, først. Eventuelle tilleggstjenester vil tas bort fra abonnementet fra frystidspunktet. Merk at hvis du har brukt abonnementet utover de inkluderte 25 GB, vil du få en egen faktura for den bruken.</span> : <span>Här kan du pausa abonnemang som du har hos Chilimobil. Om du har fler abonnemang som du vill pausa, så tar du ett abonnemang i taget. Det abonnemang som du pausar kan vara pausat så länge du själv önskar. Det är helt gratis att ha abonnemanget pausat de första 6 månaderna. Därefter är kostnaden 49 kr/mån.<br/>Att återöppna ett abonnemang (aktivera ditt simkort igen) tar ca 15 minuter.</span>}
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Grid container direction="column" justifyContent="space-around" alignItems="center" style={{ paddingTop: '17px' }}>
                <Grid item xs={12} sm={10} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                    <Grid container direction="column" justifyContent="space-around" alignItems="center">
                        {isSweden && <div style={{ paddingTop: '20px' }}>
                            Pausning sker vi nästkommande månadsskifte om du aktiverar ”pausa” mellan den 1-14:e i månaden. Om ”pausa” aktiveras från den 15:e i månaden så pausas ditt abonnemang månadsskiftet därpå.
                        </div>}
                        {state.PauseDisabled && <div style={{ color: 'red', marginTop: '20px' }}>
                            {isNorway ? 'Du har en eller flere ubetalte fakturaer, merk at dette må betales før du kan pause ditt abonnement.' : 'Eftersom du har obetalda fakturor hos oss kan du inte pausa ditt abonnemang. Ta gärna kontakt med kundservice@chilimobil.se om du behöver hjälp.'}
                        </div>}
                        <div style={{ paddingTop: '20px' }}>
                            <LargeButton color="secondary" variant="text" style={{ marginRight: '14px' }} onClick={() => { props.handleClose(); }}>
                                {t('selfcare:button.cancel')}
                            </LargeButton>
                            <LargeButton onClick={() => { setState({ ConfirmDialogOpen: true }); }} disabled={localState.buzy || state.PauseDisabled}>
                                {isNorway ? 'Frys ' : 'Pausa '}{state.MSISDN}
                            </LargeButton>
                        </div>
                    </Grid>
                </Grid>
                {state.buzy &&
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px' }} >
                        <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                    </div>
                }
            </Grid>
        </>
    );
}