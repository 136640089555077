import React, { useEffect, useCallback } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import globalState from "../state/GlobalState";
import { useState as useGlobalState } from "@hookstate/core";
import { useTranslation } from "react-i18next";
import * as rdd from "react-device-detect";
import isWebview from "is-ua-webview";
import NavigationHelper from "../utils/NavigationHelper";
import BellIcon from "../assets/img/svg/Bell.svg";
import BellNotificationIcon from "../assets/img/svg/BellNotification.svg";
import ProfileIcon from "../assets/img/svg/profile.svg";
import ChiliNoLogo from "../assets/img/svg/ChiliNoLogoSmall.svg";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";
import { Link } from "react-tiger-transition";
import theme from "../theme";

const nh = NavigationHelper;
const TopContainer = (props) => {
    const wDim = useWindowDimensions();
    const gstate = useGlobalState(globalState);
    const { t } = useTranslation();

    const setCurrentIndex = useCallback(() => {
        var ci = nh.getPageIndex(window.location.pathname);
        gstate.currentIndex.set(ci);
    }, []);

    const setTopLevelInfo = function () {
        var ci = gstate.currentIndex.get();
        var pageName = nh.getPageName(ci, gstate.PackageType.get());
        var cn = t(pageName);
        var cp = nh.getPageUrl(ci);
        if (cn === "menu.top.0") {
            cn = gstate.IsSweden.get() ? "Hem" : "Hjem";
        }
        gstate.topModuleName.set(cn);
        gstate.topModuleUrl.set(cp);
    };

    useEffect(() => {
        // code to run on component mount
        const scrollToTop = function () {
            setTimeout(function () {
                window.scrollTo(0, 1);
            }, 10);
        };
        setCurrentIndex();
        scrollToTop();
        setTopLevelInfo();
    }, [setCurrentIndex]);

    const backgroundHeight = "12rem"
    const backgroundStyle = {
        width: "100vw",
        height: backgroundHeight,
        background: theme.palette.primary.main,
        marginBottom: "-" + backgroundHeight
    };


    return (
        <div
            style={{
                width: "100%",
                overflowX: "hidden",
                overflowY: rdd.isFirefox ? "auto" : wDim.desktop ? "overlay" : "scroll",
                height: wDim.height,
                WebkitOverflowScrolling: "touch",
                scrollbarWidth: rdd.isFirefox ? "thin" : null,
                color: props.background ? "white" : undefined,
                ...props.style
            }}
        >
        {props.background && <div style={backgroundStyle} />}
            <div
                style={{ maxWidth: "1024px", width: "100%", margin: "auto", paddingTop: "1rem" }}
                className={props.noSelect ? "noselect" : ""}
            >   
                {rdd.isIOS && isWebview && (
                    <div style={{ width: "100%", height: "10px" }} />
                )}
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    style={{ paddingBottom: wDim.phone || wDim.tablet ? "100px" : "0px" }}
                >
                    <div
                        style={{
                            maxWidth: wDim.desktop ? "1024px" : "unset",
                            width: "100%",
                            padding: wDim.phone ? "5px 7px" : "5px 17px"
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <div style={{ width: "50px" }}>
                                <div style={{ marginLeft: "10px" }}>
                                    {props.messageStatus && (
                                        <Link
                                            aria-label={
                                                gstate.IsSweden.get() ? "Meddelanden" : "Meldinger"
                                            }
                                            to="/messages"
                                            transition="glide-left"
                                        >
                                            <IconButton size="large">
                                                <img
                                                    src={
                                                        props.messageStatus === "new"
                                                            ? BellNotificationIcon
                                                            : BellIcon
                                                    }
                                                    height="32"
                                                    alt=""
                                                />
                                            </IconButton>
                                        </Link>
                                    )}
                                </div>
                            </div>
                            {props.logo && (
                                <div style={{ width: wDim.desktop ? "65px" : "54px" }}>
                                    <img
                                        src={ChiliNoLogo}
                                        height={wDim.desktop ? "41" : "32"}
                                        alt=""
                                    />
                                </div>
                            )}
                            {props.title && (
                                <div style={{ fontSize: "20px", fontWeight: "700" }}>
                                    {props.title}
                                </div>
                            )}
                            {props.hideprofile || wDim.large ? (
                                <div style={{ width: "50px" }} />
                            ) : (
                                <div style={{ width: "50px" }}>
                                    <Link
                                        aria-label="Profil"
                                        style={{ marginRight: "10px" }}
                                        to="/profile"
                                        transition="glide-left"
                                    >
                                        <IconButton size="large">
                                            <img src={ProfileIcon} height="32" alt="" />
                                        </IconButton>
                                    </Link>
                                </div>
                            )}
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-around"
                            alignItems="center"
                            style={{ paddingTop: "25px" }}
                        >
                            {props.children}
                        </Grid>
                    </div>
                </Grid>
            </div>
        </div>
    );
};

export default TopContainer;
