import React, { useRef, useState } from "react";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import produce from 'immer';
import auth from "../auth";
import Version from "../version";
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import PageContainer from '../controls/PageContainer';
import { useTheme } from '@mui/material/styles';
import { Grid } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Icon from "../components/Selfcare/Common/Icon";
import ServerState from '../controls/ServerState';
import SparkleDialog from '../controls/components/SparkleDialog';
import { Box, BoxTitle, BoxContent } from '../controls/Box';
import { LargeButton, SmallButton } from '../controls/components/Buttons';

export default function Messages() {
    const { vm, state } = useConnect("Messages", {}, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        exceptionHandler: _ => auth.signOut()
    });
    const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, showBuyDataButton: false, dialogOpen: false, ViaplayBuzy: false, CancelViaplayDialogOpen: false, ActivateViaplayDialogOpen: false });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    const { t } = useTranslation();
    const theme = useTheme();
    const dialogRef = useRef();
    const handleRefresh = () => {
        vm.$dispatch({ Refresh: true });
    }
    const gstate = useGlobalState(globalState);
    return (
        <React.Fragment>
            <ServerState serverState={state} localState={localState} setLocalState={setLocalState} />
            <SparkleDialog ref={dialogRef} handleRefresh={handleRefresh} />
            <PageContainer back logo noSelect id="Messages">
                <div style={{ height: '20px' }} />
                {state.MessageList?.map((message, index) => {
                    return (
                        <Box border relative key={index} fullWidth>
                            <BoxContent>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <div style={{ width: message.HasImage ? '60%' : '100%' }}>
                                        {message.HasTitle && <div style={{ fontFamily: theme.typography.fontFamily, fontSize: '14px', fontWeight: 400, }}>
                                            {message.Title}
                                        </div>}
                                        <div>
                                            <div dangerouslySetInnerHTML={{ __html: message.Message }} />
                                        </div>
                                    </div>
                                    {message.HasImage && <div style={{ width: message.HasImage ? '40%' : '100%', textAlign: 'center', marginBottom: '-30px' }}>
                                        <img src={message.ImageType + message.Image} style={{ width: '30vw', maxWidth: '100px', marginRight: '-30px' }} alt='' />
                                    </div>}
                                </Grid>
                            </BoxContent>
                            {message.HasButton && <SmallButton onClick={() => { dialogRef.current.openUrl(message.Url, true); }}>{message.ButtonText}</SmallButton>}
                            {message.HasActionButton && <SmallButton onClick={() => { dialogRef.current.showDialog(message.ActionVariant, message.ActionParam); }}>{message.ButtonText}</SmallButton>}
                            <IconButton
                                aria-label={gstate.IsSweden.get() ? 'Radera meddelande' : 'Slett melding'}
                                style={{ position: 'absolute', top: '-5px', right: '-5px' }}
                                onClick={() => { vm.$dispatch({ DeleteMessage: message.MessageId }) }}
                                size="large">
                                <Icon name="delete" size={30} color={theme.palette.primary.main} />
                            </IconButton>
                        </Box>
                    );
                })}

            </PageContainer>
        </React.Fragment>
    );
}