import React from "react";
import { useTheme } from '@mui/material/styles';
import ReactLoading from 'react-loading';
import { Grid } from '@mui/material';
import { LargeButton } from './components/Buttons';

const PrepaidContainer = (props) => {
    const theme = useTheme();
    const state = props.state;
    return (
        <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6}>
                <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: '150px', marginTop: '30px' }}>
                    <div style={{ fontSize: '16px', marginBottom: '27px' }}>Saldo</div>
                    {state.PrepaidSaldoHTML ?
                        <>
                            <div dangerouslySetInnerHTML={{ __html: state.PrepaidSaldoHTML }} />
                            <LargeButton style={{ margin: '17px 0px 13px' }} onClick={() => { props.handleChargePrepaid(); }}>Fyll på kontantkort</LargeButton>
                        </>
                        :
                        <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={30} width={50} />
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: '150px', marginTop: '30px' }}>
                    <div style={{ fontSize: '16px', marginBottom: '27px' }}>Gjenstående data</div>
                    {state.PrepaidDataHTML ?
                        <>
                            <div dangerouslySetInnerHTML={{ __html: state.PrepaidDataHTML }} />
                            <LargeButton style={{ margin: '17px 0px 13px' }} onClick={() => { props.handleBuyData(); }}>Kjøp datapakke</LargeButton>
                        </>
                        :
                        <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={30} width={50} />
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PrepaidContainer;