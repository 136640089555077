import React from "react"
import { useTheme } from "@mui/material/styles"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { Grid } from "@mui/material"

const PotLegend = props => {
  const theme = useTheme()
  const wDim = useWindowDimensions()

  const divider =
    (wDim.width >= 338 && props?.PotLegend?.Index === 2) ||
    (wDim.width >= 488 && props?.PotLegend?.Index === 3)
      ? true
      : false
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ width: "150px", alignSelf: "flex-start" }}
      >
        {divider && (
          <div
            style={{
              height: "70px",
              width: "2px",
              backgroundColor: theme.palette.primary.main,
              marginTop: "-10px"
            }}
          />
        )}
        <div style={{ width: "148px" }}>
          {props && props.PotLegend && (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid container direction="row">
                <div
                  style={{
                    height: "10px",
                    width: "30px",
                    borderRadius: "5px",
                    margin: "0px 10px",
                    backgroundColor: props.PotLegend.Color
                  }}
                ></div>
                <div
                  style={{
                    fontSize: "12px",
                    lineHeight: "12px",
                    fontFamily: theme.typography.fontFamily2,
                    fontWeight: 300,
                    fontStyle: "normal",
                    marginRight: "-10px"
                  }}
                >
                  {props.PotLegend.Title}
                </div>
              </Grid>
              {props.PotLegend.Label1 && (
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "14px",
                    fontFamily: theme.typography.fontFamily2,
                    fontWeight: 300,
                    fontStyle: "normal"
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: props.PotLegend.Label1 }}
                  />
                </div>
              )}
              {props.PotLegend.Value1 && (
                <div
                  style={{
                    fontSize: "25px",
                    lineHeight: "25px",
                    fontWeight: 500,
                    marginTop: "1rem"
                  }}
                >
                  {props.PotLegend.Value1}
                </div>
              )}
              {props.PotLegend.Label2 && (
                <div
                  style={{
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontFamily: theme.typography.fontFamily2,
                    fontWeight: 300,
                    fontStyle: "normal"
                  }}
                >
                  {props.PotLegend.Label2}
                </div>
              )}
              {props.PotLegend.Value2 && (
                <div
                  style={{
                    fontSize: "13px",
                    lineHeight: "17px",
                    fontWeight: 500
                  }}
                >
                  {props.PotLegend.Value2}
                </div>
              )}
              {props.PotLegend.Label3 && (
                <div
                  style={{
                    fontSize: "10px",
                    lineHeight: "14px",
                    fontFamily: theme.typography.fontFamily2,
                    fontWeight: 300,
                    fontStyle: "normal"
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: props.PotLegend.Label3 }}
                  />
                </div>
              )}
            </Grid>
          )}
        </div>
      </Grid>
    </>
  )
}

export default PotLegend
