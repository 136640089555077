import React, { useEffect, useState } from "react"
import auth from "../auth"
import globalState from "../state/GlobalState"
import { useState as useGlobalState } from "@hookstate/core"
import Version from "../version"
import ServerState from "../controls/ServerState"
import ReactLoading from "react-loading"
import { useConnect } from "dotnetify"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material/styles"
import Icon from "../components/Selfcare/Common/Icon"
import Checkbox from "@mui/material/Checkbox"
import { FormControlLabel, RadioGroup, Radio, Link } from "@mui/material"
import { Grid } from "@mui/material"
import { TextField } from "../controls/TextField"
import { LargeButton, SmallButton } from "../controls/components/Buttons"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import { Alert, AlertTitle } from "@mui/material"
import { Collapse } from "@mui/material"
import Dialog from "../controls/components/Dialog/Dialog"

export default function ChangePackage(props) {
  const { t } = useTranslation()
  const gstate = useGlobalState(globalState)
  const fromOrder = sessionStorage.FromOrder
  const { vm, state, setState } = useConnect(
    "ChangePackage",
    {
      disabled: false,
      busy: false,
      EndOfMonth: false,
      Loading: true,
      termsAccepted: false,
      ssn: ""
    },
    {
      headers: {
        Authorization: "Bearer " + auth.getAccessToken(),
        ClientVersion: Version.getVersion(),
        ServerToken: window.sessionStorage.ServerToken
      },
      vmArg: {
        HostUrl: window.location.host,
        Path: window.location.pathname,
        SubscriptionNo: props.SubscriptionNo,
        Param: props.param,
        FromOrder: fromOrder
      },
      exceptionHandler: _ => auth.signOut()
    }
  )
  useEffect(() => {
    props.setTitle(
      gstate.IsSweden.get()
        ? "Ändra abonnemangsform"
        : gstate.PackageType.get() === "prepaid"
          ? "Oppgrader til abonnement"
          : "Endre abonnement"
    )
    props.setBackFunction(props.handleClose, "Avbryt")
  }, [])
  useEffect(() => {
    if (
      state.SelectPackageNo &&
      state.SelectPackageNo !== null &&
      state.SelectPackageNo !== ""
    ) {
      vm.$dispatch({ SelectPackage: state.SelectPackageNo })
    }
  }, [state.SelectPackageNo])
  useEffect(() => {
    if (
      state.SelectedPackage !== null &&
      state.SelectedPackage !== undefined &&
      state.SelectedPackage.Downgrade
    ) {
      setState({ EndOfMonth: true })
    }
    if (
      state.SelectedPackage !== null &&
      state.SelectedPackage !== undefined &&
      state.SelectedPackage.Upgrade
    ) {
      setState({ EndOfMonth: false })
    }
  }, [state.SelectedPackage])
  const theme = useTheme()
  const handleChange = event => {
    vm.$dispatch({ SelectPackage: event.target.value })
  }
  const handleRBChange = event => {
    setState({ EndOfMonth: event.target.value === "true" })
  }
  const handleInputChange = (name, obj) => event => {
    if (event.target.type === "checkbox") {
      // Handle checkboxes
      if (!obj)
        setState({
          [name]: event.target.checked,
          ErrorField: "",
          ErrorText: ""
        })
      else setState({ [obj.objId]: { ...obj, [name]: event.target.checked } }) //
    } else {
      //Handle other controls
      if (!obj)
        setState({ [name]: event.target.value, ErrorField: "", ErrorText: "" })
      else setState({ [obj.objId]: { ...obj, [name]: event.target.value } }) //
    }
    //Clear any validation errors
    setState({ ErrorField: "", ErrorText: "", OrderError: "" })
  }

  const [dialogOpen, setDialogOpen] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  useEffect(() => {
    if (dialogOpen) {
      const timer = setTimeout(() => {
        setButtonDisabled(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [dialogOpen])

  const performChange = () => {
    vm.$dispatch({
      PerformChange: {
        SelectedPackage: state.SelectedPackage,
        EndOfMonth: state.EndOfMonth,
        ssn: state.ssn,
        termsAccepted: state.termsAccepted
      }
    })
    setState({ buzy: true })
  }

  const resetDialog = () => {
    setDialogOpen(false)
    setButtonDisabled(true)
  }

  return (
    <>
      <ServerState
        serverState={state}
        setState={setState}
        handleClose={props.handleClose}
        handleRefresh={props.handleRefresh}
      />
      <Dialog
        open={dialogOpen}
        title="Endringer ved bytte av abonnement"
        description={state.SelectedPackage?.ServiceChanges}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            gap: "1rem"
          }}
        >
          <LargeButton
            disabled={buttonDisabled}
            onClick={() => {
              resetDialog()
              performChange()
            }}
          >
            Endre abonnement
          </LargeButton>
          <LargeButton
            onClick={resetDialog}
            color="secondary"
            variant="outlined"
          >
            Avbryt
          </LargeButton>
        </div>
      </Dialog>
      {state.Loading && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <ReactLoading
              type={"bubbles"}
              color={theme.palette.primary.main}
              height={"150px"}
              width={"150px"}
            />
          </Grid>
        </Grid>
      )}
      {!state.CanChangePriceplan && (
        <div
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "5px",
            textAlign: "center",
            borderBottom: "1px grey solid"
          }}
        >
          {t("selfcare:info.howtochangesubscriptiontype")}
        </div>
      )}
      {state.IsAdministrating && (
        <div
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "5px",
            textAlign: "center"
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t("selfcare:info.now-administrating-number", {
                0: state.AdministratedMsisdn
              })
            }}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "20px"
        }}
        className="noselect"
      >
        {state.OrderOpen && !state.CancelText && (
          <div
            style={{
              backgroundColor: theme.palette.boxBackground.main,
              padding: "17px",
              borderRadius: "10px",
              marginBottom: "10px"
            }}
          >
            {t("selfcare:info.orderprocessed")}
          </div>
        )}
        {state.CancelText && (
          <div style={{ marginBottom: "20px", textAlign: "center" }}>
            <Alert
              icon={
                <Icon
                  name="infofilled"
                  size={24}
                  color={theme.palette.primary.main}
                />
              }
              style={{
                color: theme.palette.text.main,
                backgroundColor: theme.palette.boxBackground.main,
                borderRadius: "10px"
              }}
            >
              <AlertTitle>{t("selfcare:info.orderprocessed")}</AlertTitle>
              <span dangerouslySetInnerHTML={{ __html: state.CancelText }} />
              <br />
              <a
                href="#"
                onClick={event => {
                  setState({ ShowCancellation: true })
                  event.preventDefault()
                }}
                style={{ color: theme.palette.primary.main }}
              >
                {state.IsSweden ? "Ångra byte" : "Angre endring"}
              </a>
            </Alert>
            <Collapse in={state.ShowCancellation}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: "-6px" }}
              >
                <Icon
                  name="up-triangle"
                  size={32}
                  color={theme.palette.boxBackground.main}
                />
                <div
                  style={{
                    borderRadius: "10px",
                    backgroundColor: theme.palette.boxBackground.main,
                    textAlign: "center",
                    color: theme.palette.primary.main,
                    padding: "20px 40px",
                    marginTop: "-6px"
                  }}
                >
                  <div
                    style={{
                      color: theme.palette.text.primary,
                      padding: "0px 10px 10px 10px"
                    }}
                  >
                    {" "}
                    {state.IsSweden
                      ? "Önskar du avbryta beställningen?"
                      : "Vil du kansellere bestillingen?"}
                  </div>
                  <SmallButton
                    disabled={state.buzy}
                    onClick={() => {
                      setState({ buzy: true })
                      vm.$dispatch({ PerformCancel: true })
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    Ja
                  </SmallButton>
                  <SmallButton
                    onClick={() => {
                      setState({ ShowCancellation: false })
                    }}
                  >
                    Nei
                  </SmallButton>
                </div>
              </Grid>
            </Collapse>
          </div>
        )}
        {state.Packages
          ? state.Packages.map((pack, index) => {
              return (
                <div
                  key={index}
                  disabled={state.Disabled}
                  style={{
                    cursor: state.Disabled || state.buzy ? "auto" : "pointer",
                    borderRadius: "10px",
                    padding: "14px 18px 18px 18px",
                    marginBottom: "20px",
                    width: "100%",
                    maxWidth: "400px",
                    border: pack.Selected
                      ? `2px solid ${theme.palette.primary.main}`
                      : pack.CurrentPackage
                        ? "1px solid #8E8E8E"
                        : "1px solid #D8D8D8",
                    background: pack.Selected
                      ? `${theme.palette.background.selected} 0% 0% no-repeat padding-box`
                      : "#FFFFFF 0% 0% no-repeat padding-box"
                  }}
                  onClick={() =>
                    state.Disabled
                      ? null
                      : vm.$dispatch({ SelectPackage: pack.PackageNo })
                  }
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      fontStyle: pack.CurrentPackage ? "italic" : "normal",
                      fontWeight: pack.Selected ? 700 : 400
                    }}
                  >
                    <FormControlLabel
                      value={pack.PackageNo}
                      control={
                        <Checkbox
                          disabled={state.Disabled || state.buzy}
                          color="primary"
                          checkedIcon={<CheckCircleIcon />}
                          icon={<RadioButtonUncheckedIcon />}
                          checked={pack.Selected}
                          onChange={handleChange}
                        />
                      }
                      label={
                        <Grid
                          item
                          style={{
                            fontSize: pack.HasDiscount ? "18px" : "16px",
                            fontWeight: pack.HasDiscount
                              ? 700
                              : pack.Selected
                                ? 700
                                : 400
                          }}
                        >
                          <div>
                            {pack.Name}{" "}
                            {pack.HasDiscount
                              ? " - " + pack.DiscountedMonthyPrice
                              : ""}
                          </div>
                          {pack.HasDiscount && (
                            <div style={{ fontSize: "16px" }}>
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 200,
                                  marginTop: "-4px"
                                }}
                              >
                                {pack.DiscountValidDescription}
                              </div>
                            </div>
                          )}
                        </Grid>
                      }
                      labelPlacement="end"
                      disabled={state.buzy || state.Disabled}
                    />

                    <Grid item style={{ textAlign: "left" }}>
                      <div
                        style={{
                          color:
                            state.Disabled || state.buzy
                              ? "#00000061"
                              : theme.palette.text.primary,
                          fontSize: "16px"
                        }}
                      >
                        {!pack.HasDiscount ? pack.MonthlyPrice : ""}
                      </div>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      color:
                        state.Disabled || state.buzy
                          ? "#00000061"
                          : theme.palette.text.primary,
                      marginLeft: "32px",
                      textAlign: "left",
                      fontSize: "12px",
                      fontFamily: theme.typography.fontFamily2
                    }}
                    dangerouslySetInnerHTML={{ __html: pack.DescriptionHTML }}
                  />
                  {pack.CurrentPackage && (
                    <div
                      style={{
                        color:
                          state.Disabled || state.buzy
                            ? "#00000061"
                            : theme.palette.text.primary,
                        marginTop: "10px",
                        marginLeft: "32px",
                        fontSize: "14px",
                        fontStyle: "italic",
                        fontWeight: 200
                      }}
                    >
                      {gstate.IsSweden.get()
                        ? "Nuvarande abonnemangsform"
                        : "Nåværende abonnement"}
                    </div>
                  )}
                  {pack.Ordered && (
                    <div
                      style={{
                        color:
                          state.Disabled || state.buzy
                            ? "#00000061"
                            : theme.palette.text.primary,
                        marginTop: "10px",
                        marginLeft: "32px",
                        fontSize: "14px",
                        fontWeight: 400
                      }}
                    >
                      {gstate.IsSweden.get() ? "Beställt" : "Bestilt"}
                    </div>
                  )}
                </div>
              )
            })
          : null}
        {state.SelectedPackage &&
          state.SelectedPackage.PackageNo !==
            state.CurrentPackage.PackageNo && (
            <>
              {!fromOrder && (
                <>
                  {state.SelectedPackage &&
                  (state.SelectedPackage.Upgrade ||
                    state.SelectedPackage.Downgrade) ? (
                    <div
                      style={{
                        textAlign: "left",
                        width: "100%",
                        maxWidth: "400px",
                        marginLeft: "32px"
                      }}
                    >
                      <RadioGroup
                        disabled={state.Disabled}
                        aria-label={t("selfcare:dialog.upgrade-when")}
                        name="when-to-upgrade"
                        value={state.EndOfMonth}
                        onChange={handleRBChange}
                      >
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              disabled={state.SelectedPackage.Downgrade}
                              color="primary"
                              checkedIcon={<CheckCircleIcon />}
                              icon={<RadioButtonUncheckedIcon />}
                            />
                          }
                          label={
                            <span
                              style={{
                                fontSize: "12px",
                                fontFamily: theme.palette.fontFamily2
                              }}
                            >
                              {gstate.IsNorway.get()
                                ? "Endre abonnementet nå"
                                : "Byt abonnemangsform nu"}
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio
                              color="primary"
                              checkedIcon={<CheckCircleIcon />}
                              icon={<RadioButtonUncheckedIcon />}
                            />
                          }
                          label={
                            <span
                              style={{
                                fontSize: "12px",
                                fontFamily: theme.palette.fontFamily2
                              }}
                            >
                              {gstate.IsNorway.get()
                                ? "Endre abonnement fra og med neste måned"
                                : "Byt abonnemangsform från och med nästa månad"}
                            </span>
                          }
                          labelPlacement="end"
                          style={{
                            fontSize: "12px",
                            fontFamily: theme.palette.fontFamily2
                          }}
                        />
                      </RadioGroup>
                    </div>
                  ) : state.SelectedPackage.PreToPost ? null : (
                    <div
                      style={{
                        fontFamily: theme.typography.fontFamily2,
                        fontSize: "14px",
                        textAlign: "center"
                      }}
                    >
                      {gstate.IsSweden.get()
                        ? "Abonnemanget ändras från och med nästa månad."
                        : "Abonnementet endres fra og med neste måned."}
                    </div>
                  )}
                  {state.SelectedPackage &&
                    state.SelectedPackage.CreditCheck && (
                      <div
                        style={{
                          width: "100%",
                          maxWidth: "400px",
                          marginLeft: "32px"
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                fontFamily: theme.typography.fontFamily2
                              }}
                            >
                              Vær oppmerksom at vi i forbindelse med dette vil
                              foreta en kredittsjekk for å godkjenne byttet.
                            </div>
                          </Grid>
                          <Grid item xs={12} style={{ marginLeft: "-17px" }}>
                            <TextField
                              fullWidth
                              autoFocus
                              disabled={state.Disabled || state.buzy}
                              id="ssn"
                              name="ssn"
                              maxLength={11}
                              label="Fødselsnummer (11 siffer)"
                              type="tel"
                              localState={state}
                              setLocalState={setState}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  {state.SelectedPackage && state.SelectedPackage.PreToPost && (
                    <div
                      style={{
                        textAlign: "left",
                        width: "100%",
                        maxWidth: "400px",
                        marginLeft: "32px"
                      }}
                    >
                      <FormControlLabel
                        style={{ display: "table", marginTop: "10px" }}
                        control={
                          <Checkbox
                            id="termsAccepted"
                            disabled={state.Disabled || state.buzy}
                            checked={state.termsAccepted}
                            onChange={handleInputChange("termsAccepted")}
                            color="primary"
                            style={{
                              display: "table-cell",
                              verticalAlign: "top"
                            }}
                          />
                        }
                        label={
                          <div
                            style={{
                              fontSize: "14px",
                              fontFamily: theme.typography.fontFamily2
                            }}
                          >
                            Jeg har forstått og akseptert Chilimobil sine&nbsp;
                            <Link
                              color="primary"
                              onClick={e => {
                                props.openUrl(
                                  "https://www.chilimobil.no/vilkar/",
                                  true
                                )
                                e.preventDefault()
                              }}
                              style={{
                                fontSize: "14px",
                                fontFamily: theme.typography.fontFamily2,
                                cursor: "pointer"
                              }}
                            >
                              vilkår
                            </Link>
                            &nbsp;og&nbsp;
                            <Link
                              color="primary"
                              onClick={e => {
                                props.openUrl(
                                  "https://www.chilimobil.no/personvern/",
                                  true
                                )
                                e.preventDefault()
                              }}
                              style={{
                                fontSize: "14px",
                                fontFamily: theme.typography.fontFamily2,
                                cursor: "pointer"
                              }}
                            >
                              personvernerklæring
                            </Link>
                            . Egne priser gjelder fra Norge til&nbsp;
                            <Link
                              color="primary"
                              onClick={e => {
                                props.openUrl(
                                  "https://www.chilimobil.no/sporsmal-hjelp/mobilabonnement-norge-utlandet/",
                                  true
                                )
                                e.preventDefault()
                              }}
                              style={{
                                fontSize: "14px",
                                fontFamily: theme.typography.fontFamily2,
                                cursor: "pointer"
                              }}
                            >
                              EU/EØS/utlandet
                            </Link>
                            .
                          </div>
                        }
                      />
                    </div>
                  )}
                  {state.ErrorField === "termsAccepted" && (
                    <div
                      style={{
                        color: theme.palette.error.main,
                        marginTop: "5px"
                      }}
                    >
                      {state.ErrorText}
                    </div>
                  )}
                  {state.SelectedPackage &&
                    state.SelectedPackage.RolloverWillBeLost &&
                    gstate.IsNorway.get() && (
                      <div
                        style={{
                          fontFamily: theme.typography.fontFamily2,
                          fontSize: "12px",
                          maxWidth: "400px",
                          textAlign: "center"
                        }}
                      >
                        Merk at du kan miste litt av din oppsparte rollover når
                        du nedgraderer. Eksempel: Et{" "}
                        {state.SelectedPackage.PackageSize} abonnement kan
                        maksimalt spare opp {state.SelectedPackage.PackageSize}{" "}
                        i ekstra rollover.
                      </div>
                    )}
                </>
              )}
            </>
          )}

        {state.CurrentPackage && state.SelectedPackage && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ marginTop: "24px" }}
          >
            <LargeButton
              onClick={() => {
                const hasServiceChanges =
                  state.SelectedPackage?.ServiceChanges?.length > 0
                setDialogOpen(hasServiceChanges)
                if (!hasServiceChanges) {
                  performChange()
                }
              }}
              disabled={
                state.CurrentPackage.PackageNo ===
                  state.SelectedPackage.PackageNo || state.buzy
              }
            >
              {gstate.IsNorway.get()
                ? state.SelectedPackage.PreToPost
                  ? "Velg"
                  : "Endre abonnement"
                : "Byt abonnemangsform"}
            </LargeButton>
          </Grid>
        )}
      </div>
    </>
  )
}
