import React, { useEffect } from "react";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import auth from "../auth";
import Version from "../version";
import { useConnect } from 'dotnetify';
import ServerState from '../controls/ServerState';
import { useTheme } from '@mui/material/styles';
import { Grid, Modal, Typography, LinearProgress } from "@mui/material";

export default function Callback() {
    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    const { vm, state } = useConnect("Callback", { Buzy: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            Uri: window.location.href,
        },
        exceptionHandler: _ => auth.signOut()
    });
    useEffect(() => {
        const getPaymentInfo = () => {
            const temp = gstate.paymentInfo.get();
            const PaymentInfo = Object.assign({}, temp);
            return PaymentInfo;
        }
        if (state.ServerReady)
            vm.$dispatch({ PaymentInfo: getPaymentInfo() });
    }, [state.ServerReady])

    return (
        <>
            <ServerState serverState={state} />
            <Modal
                open={state.Buzy}
            >
                <div style={{ width: '300px', top: '50%', left: '50%', transform: `translate(-50%, -50%)`, position: 'absolute', backgroundColor: theme.palette.background.paper, boxShadow: theme.shadows[5], padding: theme.spacing(2), outline: 'none' }}>
                    <Typography id="modal-title">
                        {state.BuzyText}
                    </Typography>
                    {state.BuzySubText}
                    <LinearProgress color="primary" variant="query" />
                </div>
            </Modal>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    
                </Grid>
        </>
    );
}