import React, { useState, useEffect } from "react";
import produce from 'immer';
import auth from "../auth";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Icon from "../components/Selfcare/Common/Icon";
import { Grid } from '@mui/material';
import { LargeButton } from '../controls/components/Buttons';
import { TextField } from '../controls/TextField';
import TextMaskAmount from '../components/Selfcare/TextMasks/TextMaskAmount';

export default function ChargePrepaid(props) {
    const { t } = useTranslation();
    const { vm, state } = useConnect("ChargePrepaid", {}, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        vmArg: {
            HostUrl: window.location.host,
            Path: window.location.pathname,
            SubscriptionNo: props.SubscriptionNo,
        },
        exceptionHandler: _ => auth.signOut()
    });
    useEffect(() => {
        if (state.msisdn)
            setLocalState({ msisdn: state.msisdn });
    }, [state.msisdn])
    useEffect(() => {
        if (state.PaymentInfo) {
            gstate.paymentInfo.set(state.PaymentInfo);
        }
        if (state.GoNets)
            window.location.assign(state.GoNets);
    }, [state.GoNets])
    const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, termsAccepted: false, msisdn: '', amount: '' });
    const setLocalState = (o) => {
        internalSetLocalState(
            produce(draft => {
                draft[Object.keys(o)[0]] = Object.values(o)[0];
            })
        )
    }
    const gstate = useGlobalState(globalState);
    const theme = useTheme();

    return (
        <>
            <ServerState serverState={state} localState={localState} setLocalState={setLocalState} />
            {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
            <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={6}>
                        <Icon name="money" size={48} color="#FFFFFF" />
                        <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                            Påfylling kontantkort
                        </div>
                    </Grid>
                </Grid>
            </div>
            <form onSubmit={(e) => { e.preventDefault(); vm.$dispatch({ ValidateCharge: { amount: localState.amount, termsAccepted: localState.termsAccepted } }); setLocalState({ buzy: true }); }}>
                <Grid container direction="column" justifyContent="space-around" alignItems="center" style={{ paddingTop: '17px' }}>
                    <TextField
                        localState={localState}
                        setLocalState={setLocalState}
                        disabled={true}
                        id="msisdn"
                        name="msisdn"
                        label="Nummer du vil fylle på"
                        margin="none"
                    />
                    <TextField
                        localState={localState}
                        setLocalState={setLocalState}
                        autoFocus
                        disabled={localState.Disabled || localState.buzy}
                        name="amount"
                        id="amount"
                        label="Hvor mye vil du fylle på?"
                        margin="none"
                        placeholder="Beløp over 69,-"
                        inputComponent={TextMaskAmount}

                    />
                    <Grid item xs={12} sm={6} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={localState.Disable || localState.buzy}
                                    checked={localState.termsAccepted}
                                    onChange={(e) => { setLocalState({ termsAccepted: e.target.checked }); }}
                                    color="primary"
                                />
                            }
                            label=
                            {
                                <span style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: "Jeg godtar <a href='https://www.chilimobil.no/vilkar/' target='_blank'>vilkår</a> for lading." }} />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ width: '100%', padding: '0px 17px 17px 17px' }}>
                        <Grid container direction="row" justifyContent="center">
                            <LargeButton color="secondary" variant="text" style={{ marginRight: '14px' }} onClick={() => { props.handleClose(); }}>Avbryt</LargeButton>
                            <LargeButton disabled={localState.buzy || localState.Disabled} type="submit" color="primary">
                                Videre til betaling
                            </LargeButton>
                        </Grid>
                    </Grid>
                    {localState.buzy && <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px' }} >
                        <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />
                    </div>}
                </Grid>
            </form>
        </>
    );
}