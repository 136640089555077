import React, { useEffect } from 'react';
import auth from "../auth";
import { useConnect } from "dotnetify";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import ServerState from "../controls/ServerState";
import { Grid } from '@mui/material';
import Icon from "../components/Selfcare/Common/Icon";
import { TextField } from '../controls/TextField';
import { LargeButton } from '../controls/components/Buttons';


// dotNetify


export default function ChangePassword(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { vm, state, setState } = useConnect("ChangePassword",
        {
            show: false,
            redirect: null,
            toUrl: null,
            snackbarOpen: false,
            snackbarMessage: '',
            username: '',
            OldPassword: '',
            Password: '',
            PasswordRepeat: '',
        },
        {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ServerToken: window.sessionStorage.ServerToken
            },
            exceptionHandler: _ => auth.signOut()
        });
    useEffect(() => {
        setTimeout(
            function () {
                setState({ show: true });
                var el = document.getElementById("usernamebox");
                el.style.display = "none";
                el = document.getElementById("OldPassword");
                el.type = "password";
                el = document.getElementById("Password");
                el.type = "password";
                el = document.getElementById("PasswordRepeat");
                el.type = "password";
            },
            10
        );
    }, [])
    useEffect(() => {
        if (state.UnAuthorized) {
            auth.signOut();
        }
    }, [state.UnAuthorized])

    useEffect(() => {
        if (state.EncryptedPassword) {
            if (hasLocalStorage() && localStorage.chRememberMe) {
                localStorage.chPassword = state.EncryptedPassword;
            }
        }
    }, [state.EncryptedPassword])

    const hasLocalStorage = () => {
        try {
            window.localStorage.setItem("__test", "data");
            window.localStorage.removeItem("__test");
        } catch (e) {
            return false;
        }
        return true;
    };

    return (
        <>
            <ServerState serverState={state} setState={setState} handleClose={props.handleClose} />
            <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={6}>
                        <Icon name="lock" size={48} color="#FFFFFF" />
                        <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                            {t('selfcare:item.changepassword')}
                        </div>
                        <div style={{ fontSize: '12px', paddingTop: '20px' }}>{t('selfcare:info.passwordrequirements')}
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div style={{ padding: '20px' }}>
                <form autoComplete="off" onSubmit={(e) => { e.preventDefault(); setState({ ErrorField: '' }); vm.$dispatch({ PerformChange: { oldPassword: state.OldPassword, password: state.Password, passwordRepeat: state.PasswordRepeat } }); }}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div id="usernamebox">
                                        <TextField fullWidth
                                            localState={state}
                                            name="Username"
                                            setLocalState={setState}
                                            id="Username"
                                            label='Mobilnummer'
                                            type="tel"
                                            noPadding
                                            disabled={state.buzy}
                                        />
                                    </div>
                                    <TextField
                                        fullWidth
                                        localState={state}
                                        setLocalState={setState}
                                        name='OldPassword'
                                        label={t('selfcare:label.oldpassword')}
                                        type="text"
                                        autoFocus
                                        autoComplete="new-password"
                                    />
                                    <TextField
                                        fullWidth
                                        localState={state}
                                        setLocalState={setState}
                                        name='Password'
                                        label={t('selfcare:label.newpassword')}
                                        type="text"
                                        autoComplete="new-password"
                                    />
                                    <TextField
                                        fullWidth
                                        localState={state}
                                        setLocalState={setState}
                                        name='PasswordRepeat'
                                        label={t('selfcare:label.confirmpassword')}
                                        type="text"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" direction="row">
                                <div style={{ paddingTop: '20px' }}>
                                    <LargeButton type="submit" style={{ marginRight: '10px' }}>{t('selfcare:button.changepassword')}</LargeButton>
                                    <LargeButton variant="text" color="secondary" id='cancel' onClick={() => { props.handleClose(); }}>{t('selfcare:button.cancel')}</LargeButton>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
}
