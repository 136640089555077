import React, { useState, useRef } from "react";
import produce from 'immer';
import auth from "../auth";
import Version from "../version";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import useWindowDimensions from "../hooks/useWindowDimensions";
import ServerState from '../controls/ServerState';
import { useTranslation } from 'react-i18next';
import NavigationHelper from '../utils/NavigationHelper';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import TopContainer from '../controls/TopContainer';
import { Box, BoxTitle, BoxContent } from '../controls/Box';
import { SmallButton, LargeButton } from '../controls/components/Buttons';
import SparkleDialog from '../controls/components/SparkleDialog';
import Icon from "../components/Selfcare/Common/Icon";
import StrexIcon from '../assets/img/svg/strex.svg';
import { ReactComponent as BillogramIcon } from '../assets/img/svg/billogram_logo_horizontal_Black.svg';

const nh = NavigationHelper;

export default function InvoiceList() {
    const { vm, state, setState } = useConnect("InvoiceList", { LoadingData: true, LoadingMore: false, dialogOpen: false, disabled: false, buzy: false, showBuyDataButton: false }, {
        headers: {
            Authorization: "Bearer " + auth.getAccessToken(),
            ClientVersion: Version.getVersion(),
            ServerToken: window.sessionStorage.ServerToken
        },
        exceptionHandler: _ => auth.signOut()
    });
    const gstate = useGlobalState(globalState);
    const { t } = useTranslation();
    //const [localState, internalSetLocalState] = useState({ disabled: false, busy: false, showBuyDataButton: false, dialogOpen: false });
    //const setLocalState = (o) => {
    //    internalSetLocalState(
    //        produce(draft => {
    //            draft[Object.keys(o)[0]] = Object.values(o)[0];
    //        })
    //    )
    //}
    const dialogRef = useRef();
    const theme = useTheme();
    const wDim = useWindowDimensions();
    const setTopLevelInfo = function () {
        var ci = gstate.currentIndex.get();
        var cn = t(nh.getPageName(ci));
        var cp = nh.getPageUrl(ci);
        gstate.topModuleName.set(cn);
        gstate.topModuleUrl.set(cp);
    }
    const handleCloseDialog = () => {
        setState({ dialogOpen: false });
    }
    const isPrepaid = gstate.PackageType.get() === 'prepaid';

    return (
        <React.Fragment>
            <ServerState serverState={state} localState={state} setLocalState={setState} />
            <SparkleDialog ref={dialogRef} open={state.dialogOpen} handleClose={handleCloseDialog} type={state.dialogType} />
            <TopContainer title={gstate.IsSweden.get() ? 'Fakturor' : isPrepaid ? 'Forbruk' : 'Faktura'} noSelect>
                {state.LoadingData ?
                    <>
                        {isPrepaid ?
                            <div id="preloader">
                                < div id="loader"></div>
                            </div>
                            :
                            <>
                                {gstate.IsNorway.get() ? "Henter fakturainformasjon..." : "Hämtar fakturainformation..."}
                                <Skeleton variant="rectangular" style={{ width: wDim.desktop ? '47%' : '100%', height: '124px', borderRadius: '10px', marginBottom: '17px' }} />
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Skeleton variant="rectangular" style={{ width: wDim.desktop ? '47%' : '100%', height: '93px', borderRadius: '10px', marginBottom: '17px' }} />
                                    <Skeleton variant="rectangular" style={{ width: wDim.desktop ? '47%' : '100%', height: '93px', borderRadius: '10px', marginBottom: '17px' }} />
                                </Grid>
                            </>
                        }
                    </>
                    :
                    <>
                        {gstate.IsNorway.get() && !isPrepaid &&
                            <Box border>
                                <BoxTitle>
                                    Foreløpig kostnader for neste faktura
                                </BoxTitle>
                                <BoxContent>

                                    {!state.Unbilled ?
                                        <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={30} width={50} />
                                        :
                                        <Grid container role="list">
                                            {state.Unbilled.map((row, index) => {
                                                return (
                                                    <Grid key={index} container direction="row" justifyContent="space-between" alignItems="center" role="listitem">
                                                        <Grid item>
                                                            {row.Description === ' ' ? <span>&nbsp;</span> : row.Description}
                                                        </Grid>
                                                        <Grid item>
                                                            {row.Price}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>}
                                </BoxContent>
                            </Box>}
                        {isPrepaid &&
                            <Box border>
                                <BoxTitle>
                                    Påfyllingshistorikk
                                </BoxTitle>
                                <BoxContent>
                                    {!state.Refills && !state.RefillText ?
                                        <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={30} width={50} />
                                        :
                                        <>
                                            <div>{state.RefillText}</div>
                                            <Grid container role="list">
                                                {state.Refills?.map((row, index) => {
                                                    return (
                                                        <Grid key={index} container direction="row" alignItems="center" role="listitem">
                                                            <Grid item xs={6}>
                                                                {row.Description === ' ' ? <span>&nbsp;</span> : row.Description}
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                {row.Date}
                                                            </Grid>
                                                            <Grid item xs={2} style={{ textAlign: 'right' }}>
                                                                {row.Amount}
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </>
                                    }
                                </BoxContent>
                            </Box>}
                        {gstate.IsNorway.get() && state.ShowStrex &&
                            <LargeButton variant="outlined" style={{ padding: '12px 4px 12px 12px', borderRadius: '10px', marginBottom: '20px' }} onClick={() => dialogRef.current.showDialog('strexTransactions')} disabled={state.DataLoading}>
                                <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <img src={StrexIcon} height="28" alt="" style={{ display: 'inline-block' }} />

                                    <div style={{ marginLeft: '10px', textAlign: 'left', color: theme.palette.text.primary, width: '200px' }}>
                                        <div style={{ fontSize: '14px' }}>{state.IsPrepaid ? 'Se kjøp av varer og tjenester' : 'Se kjøp via mobilregningen'}</div>
                                    </div>
                                    <div style={{ marginLeft: '40px', paddingTop: '5px' }}>
                                        <Icon name='right-arrow' size={16} color="#BABABA" style={{ display: 'inline-block' }} />
                                    </div>
                                </div>
                            </LargeButton>
                        }
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            {state.Invoices?.map((row, index) => {
                                return (
                                    row.ErrorText ?
                                        <Box key={index} background>
                                            <BoxTitle>
                                                {state.IsSweden ? 'Fakturor' : 'Faktura'}
                                            </BoxTitle>
                                            <BoxContent>
                                                {row.ErrorText}
                                            </BoxContent>
                                        </Box>
                                        :
                                        <Box key={index} background linkAction={() => { row.PaymentURL ? dialogRef.current.openUrl(row.PaymentURL) : dialogRef.current.navigatePage('/invoice-list/invoice/?invoiceNo=' + row.InvoiceNo, 'glide-left'); }}>
                                            <BoxTitle>
                                                <Grid container direction="row" justifyContent="space-between">
                                                    <div>{row.Month} - {row.Year}</div>
                                                    <div>{row.Amount}</div>
                                                </Grid>
                                                <Grid container direction="row" justifyContent="space-between" style={{ fontSize: '12px', height: '17px', paddingTop: '4px', fontWeight: 200 }}>
                                                    {row.DueAmount && <>
                                                        <div>{state.IsNorway ? 'Gjenstår' : 'Kvar att betala'}</div>
                                                        <div>{row.DueAmount}</div>
                                                    </>}
                                                </Grid>
                                            </BoxTitle>
                                            <Grid container direction="row" justifyContent="space-between" alignItems="baseline" style={{ paddingTop: '10px', fontSize: '14px' }}>
                                                {row.PaymentURL ?
                                                    <div style={{ display: "flex", flexFlow: "row wrap", }}>
                                                        <SmallButton href={row.PaymentURL} rel="noopener noreferrer" target="_blank" onClick={(e) => { e.stopPropagation(); }} style={{ fontSize: "12px", }}>Öppna och betala faktura</SmallButton>
                                                        <div style={{ marginLeft: "12px", display: "flex", alignItems: "center", }}><BillogramIcon height="14" width="auto" /></div>
                                                    </div>
                                                    :
                                                    <SmallButton>Se mer</SmallButton>}
                                                <div><span style={{ color: row.PaymentStatusColor }}>{row.PaymentStatus}</span>{row.PaymentSubStatus && <><span> - </span><span style={{ color: row.PaymentSubStatusColor }}>{row.PaymentSubStatus}</span></>}</div>
                                            </Grid>
                                        </Box>

                                )
                            })}
                            {state.HasMoreInvoices &&
                                <div style={{ textAlign: 'center', width: '100%' }}>
                                    <div style={{ position: 'relative' }}>
                                        <LargeButton disabled={state.LoadingMore} onClick={() => { setState({ LoadingMore: true }); vm.$dispatch({ GetMoreInvoice: true }); }}>{gstate.IsNorway.get() ? 'Vis fler' : 'Visa fler'}</LargeButton>
                                        <div style={{ position: 'absolute', top: 0, left: '48%' }}>
                                            {state.LoadingMore && <ReactLoading type={"spinningBubbles"} color={theme.palette.primary.main} height={'38px'} width={'38px'} />}
                                        </div>
                                    </div>
                                </div>
                            }
                        </Grid>
                    </>
                }
            </TopContainer>
        </React.Fragment >
    );
}