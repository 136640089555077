import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useTheme } from '@mui/material/styles';

export const ShoppingGroup = (props) => {
    const wDim = useWindowDimensions();
    const theme = useTheme();
    return (
        <div weight={props.weight ? props.weight : 1}>
            <div style={{ marginBottom: '10px', width: '100%', textAlign: 'center', minHeight: '20px' }}>{props.title}</div>
            {props.children}
        </div>
    );
};
ShoppingGroup.defaultProps = {
    weight: 2,
};

